export function initGoogleMap (val_lat, val_lng, element, icon) {
    var fenway = {lat: parseFloat(val_lat), lng: parseFloat(val_lng)};
    var map = new google.maps.Map(element, {
        center: fenway,
        zoom: 14,
    });
    var markerIcon = {
        anchor: new google.maps.Point(22, 16),
        url: '/images/marker.png',
    }
    var blueMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#6EBBF8" stroke="white" stroke-width="3"/></svg>';
    var yellowMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#f5f242" stroke="white" stroke-width="3"/></svg>';
    var redMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#ff1953" stroke="white" stroke-width="3"/></svg>';          

    var iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(blueMarker);
    if (icon == 2) {
        iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(yellowMarker);
    }
    if (icon == 3) {
        iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(redMarker);
    }
    var marker = new google.maps.Marker({
        position: fenway,
        map: map,
        icon: iconSvg,
    });
}

export function initGoogleMapMulti(type_group, element) {
    var fenway = { lat: parseFloat(type_group[Object.keys(type_group)[0]].ptc.geo_data.lat), lng: parseFloat(type_group[Object.keys(type_group)[0]].ptc.geo_data.lng) };

    var map = new google.maps.Map(element, {
        center: fenway,
        zoom: 14,
    });
    var markerIcon = {
        anchor: new google.maps.Point(22, 16),
        url: '/images/marker.png',
    }

    var blueMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#6EBBF8" stroke="white" stroke-width="3"/></svg>';
    var yellowMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#f5f242" stroke="white" stroke-width="3"/></svg>';
    var redMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#ff1953" stroke="white" stroke-width="3"/></svg>';          

    var boxText = document.createElement("div");
    boxText.className = 'map-box'
    var currentInfobox;
    var boxOptions = {
        content: boxText,
        disableAutoPan: true,
        alignBottom: true,
        maxWidth: 0,
        pixelOffset: new google.maps.Size(-145, -45),
        zIndex: null,
        boxStyle: {
            width: "200px"
        },
        closeBoxMargin: "0",
        closeBoxURL: "",
        infoBoxClearance: new google.maps.Size(1, 1),
        isHidden: false,
        pane: "floatPane",
        enableEventPropagation: false,
        contextmenu:true,
    };

    Object.keys(type_group).forEach((type) => {
        let markerP = { lat: parseFloat(type_group[type].ptc.geo_data.lat), lng: parseFloat(type_group[type].ptc.geo_data.lng) };
        var iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(blueMarker);
        if (type_group[type].ptc.geo_data.icon == 2) {
            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(yellowMarker);
        }
        if (type_group[type].ptc.geo_data.icon == 3) {
            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(redMarker);
        }
        
        let marker = new google.maps.Marker({
            position: markerP,
            map: map,
            icon: iconSvg,
        });

        let popuphtml = `
        <div class="map-popup-wrap">
            <div class="map-popup">
                <div class="infoBox-close"><i class="fa fa-times"></i></div>
                <div class="listing-content fl-wrap popup-slide-`+type_group[type].ptc.fid+`">
                <div class="listing-title fl-wrap">
                    <div style="padding: 0 5px; display: inline-block;">
                        <h4>`+type_group[type].ptc.name+`</h4>
                        <h4>`+type_group[type].ptc.type+`</h4>
                        <h4>Rez Status: `+type_group[type].ptc.rz.status+`</h4>
                        <h4>DP Status: `+type_group[type].ptc.dp.status+`</h4>
                        <h4>BP Status: `+type_group[type].ptc.bp.status+`</h4>
                    </div>
                </div>`;
                    if (Object.keys(type_group[type].ptc.utc).length > 0) {
                        Object.keys(type_group[type].ptc.utc).forEach(function (index) {
                            popuphtml +=  `<div class="listing-title fl-wrap">
                                <div style="padding: 0 5px; display: inline-block;">
                                <h4>`+type_group[type].ptc.utc[index].name+`</h4>
                                <h4>`+type_group[type].ptc.utc[index].type+`</h4>
                                <h4>`+ type_group[type].ptc.utc[index].storey + ` Storeys</h4>`;
                                if (typeof type_group[type].ptc.utc[index].details != 'undefined' && type_group[type].ptc.utc[index].details.length > 0) {
                                    type_group[type].ptc.utc[index].details.forEach((detail) => {
                                        popuphtml += `<div class="row">
                                            <div class="col-6"><h4>`+detail.type+`</h4></div>
                                            <div class="col-6"><a href="`+detail.ref_link+`"><h4>`+detail.status+`</h4></a></div>
                                        </div>`;
                                    });
                                }
                            popuphtml +=`</div></div>`
                            });
                        }
                popuphtml += `</div>
            </div>
        </div>`;


        var ib = new InfoBox();
        google.maps.event.addListener(marker, 'click', (function (marker) {
            return function () {
                ib.setOptions(boxOptions);
                boxText.innerHTML = popuphtml;
                currentInfobox = type_group[type].ptc.fid;
                google.maps.event.addListener(ib, 'domready', function () {
                    $('.infoBox-close').click(function (e) {
                        e.preventDefault();
                        ib.close();
                    });
                    if (!$('.popup-slide-'+type_group[type].ptc.fid).hasClass('slick-initialized')) {
                        $('.popup-slide-'+type_group[type].ptc.fid).slick({
                            infinite: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                            arrows: true,
                            draggable: false,
                        });
                    }
                });
                ib.close();
                ib.open(map, marker);
                google.maps.event.addListener(map, 'click', function () {
                    ib.close();
                    vm.closeMapBox();
                });
            }
        })(marker));
    });
}

export function classStatus(status) {
    const prefix = 'status-circle mr-2 ';
    switch (status) {
        case 'Now Selling':
            return prefix + 'now-selling-color';
        case 'Now Leasing':
            return prefix + 'now-leasing-color';
        case 'Selling Soon':
            return prefix + 'selling-soon-color';
        case 'In Planning':
            return prefix + 'in-planning-color';
        case 'Sold Out':
            return prefix + 'sold-out-color';
        case 'Leasing Soon':
            return prefix + 'leasing-soon-color';
        case 'Leased Out':
            return prefix + 'leased-out-color';
        case 'Not Submitted':
            return prefix + 'status-grey';
        case 'In Process':
            return prefix + 'status-yellow';
        case 'Approved':
            return prefix + 'status-purple';
        case 'Issued':
            return prefix + 'status-blue';
        case 'Completed':
            return prefix + 'status-green';
        case 'Denied':
            return prefix + 'status-red';
    }
}