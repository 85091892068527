export default class UserData {
    constructor (first_name, last_name, brokerage_name, team_name, user_email, phone_number, pic, link, user_id){
        this.first_name = first_name;
        this.last_name = last_name;
        this.brokerage_name = brokerage_name;
        this.team_name = team_name;
        this.user_email = user_email;
        this.phone_number = phone_number;
        this.pic = pic;
        this.link = link;
        this.user_id = user_id;
    }
}