<template>
    <div class="container">
        <div class="row pt-4" v-if="!['developer','developerbc'].includes(current_user.role.toLowerCase())">
            <div class="col-md-12">
                <table class="table">
                    <thead class="fp-header">
                        <th>PID</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Registration Type</th>
                        <th>Sales Price</th>
                        <th>Registered Owner</th>
                        <th>Date</th>
                    </thead>
                    <tbody class="fp-body">
                        <tr v-for="(value, key) in landInfo" :key="key">
                            <td>{{value.pid}}</td>
                            <td>{{value.address}}</td>
                            <td>{{value.status}}</td>
                            <td>
                                <template v-if="selectedDateMap[key].selectedDate">
                                    {{value.reg[selectedDateMap[key].selectedDate].registration_type}}
                                </template>
                            </td>
                            <td>
                                <template v-if="selectedDateMap[key].selectedDate">
                                    {{value.reg[selectedDateMap[key].selectedDate].sale_price}}
                                </template>
                            </td>
                            <td>
                                <template v-if="selectedDateMap[key].selectedDate">
                                    {{value.reg[selectedDateMap[key].selectedDate].purchaser}}
                                </template>
                            </td>
                            <td v-if="selectedDateMap[key]">
                                <b-form-select v-model="selectedDateMap[key].selectedDate" @change="selectedDate($event, key)">
                                    <b-form-select-option v-for="(reg, regKey) in value.reg" :key="regKey+key" :value="regKey">
                                        {{regKey}}
                                    </b-form-select-option>
                                </b-form-select>
                            </td>
                            <td v-else>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row pt-4" v-if="groupInfo.ptc.display_total && ['admin'].includes(current_user.role.toLowerCase())">
            <div class="col-md-12">
                <table class="table">
                    <thead class="fp-header">
                        <th></th>
                        <th>Units</th>
                        <th>Floor Area</th>
                        <th>Floor Area (gross)</th>
                        <th>FSR/FAR</th>
                        <th>Parking</th>
                        <th>Studio</th>
                        <th>1-bed</th>
                        <th>2-bed</th>
                        <th>3-bed</th>
                        <th>4-bed</th>
                    </thead>
                    <tbody class="fp-body">
                        <tr v-for="(value, key) in totalInfo" :key="key">
                            <td>{{key}}</td>
                            <td>{{value.total_units?value.total_units:'-'}}</td>
                            <td>{{value.total_floor_area | numFormat}}</td>
                            <td>{{value.gross | numFormat}}</td>
                            <td>{{value.total_fsr?value.total_fsr: '-'}}</td>
                            <td>{{value.total_parking?value.total_parking:'-'}}</td>
                            <td>{{value.total_studio?value.total_studio:'-'}}</td>
                            <td>{{value.total_1_bed?value.total_1_bed:'-'}}</td>
                            <td>{{value.total_2_bed?value.total_2_bed:'-'}}</td>
                            <td>{{value.total_3_bed?value.total_3_bed:'-'}}</td>
                            <td>{{value.total_4_bed?value.total_4_bed:'-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
            <div v-for="(app, key_i) in groupInfo.ptc.utc" :key="key_i" style="border: 1px solid grey; margin-bottom: 20px; padding: 10px; position: relative;" :class="{'row' : ['developer','developerbc'].includes(current_user.role.toLowerCase())}">
                <div v-for="(item, key_a) in app" :key="key_a"  :class="{'col-5' : ['developer','developerbc'].includes(current_user.role.toLowerCase())}">
                    <template v-if="key_a === 'zoning_info_only' && !['developer','developerbc'].includes(current_user.role.toLowerCase())">
                        <span class="city-plan" target="_blank" v-b-modal="'city-app-'+groupInfo.ptc.fid +'-'+key_i"></span>
                        <b-modal :id="'city-app-'+groupInfo.ptc.fid +'-'+key_i" hide-footer :title="'City Application Doc'">
                            <div class="col-md-4 d-block text-center" style="margin-bottom: 20px;" v-for="(file, key) in item.city_plan" :key="key">
                                <a target="_blank" :href="file.file" style="font-size: 17px;">{{file.name}}</a>
                            </div>
                        </b-modal>
                        <div class="row d-flex align-items-center pt-2">
                            <div class="col-md-12">
                                <table class="table text-left permit-table table-borderless">
                                    <thead class="table-borderless">
                                        <th>TYPE</th>
                                        <th>STATUS</th>
                                        <th>SUBMISSION DATE</th>
                                        <th>STATUS DATE</th>
                                        <th># DAYS</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Rezoning</td>
                                            <td><span class="status-circle mr-2" :class="statusSwitch(item.zoning_info.rz_status)"></span>{{item.zoning_info.rz_status}}</td>
                                            <td>{{item.zoning_info.rz_sub_date}}</td>
                                            <td>{{item.zoning_info.rz_app_date}}</td>
                                            <td>{{item.zoning_info.rz_sub_date ? item.zoning_info.rz_range : ''}}</td>
                                        </tr>
                                        <tr>
                                            <td>Development Permit</td>
                                            <td><span class="status-circle mr-2" :class="statusSwitch(item.zoning_info.dp_status)"></span>{{item.zoning_info.dp_status}}</td>
                                            <td>{{item.zoning_info.dp_sub_date}}</td>
                                            <td>{{item.zoning_info.dp_app_date}}</td>
                                            <td>{{item.zoning_info.dp_sub_date ? item.zoning_info.dp_range : ''}}</td>
                                        </tr>
                                        <tr>
                                            <td>Building Permit</td>
                                            <td><span class="status-circle mr-2" :class="statusSwitch(item.zoning_info.bp_status)"></span>{{item.zoning_info.bp_status}}</td>
                                            <td>{{item.zoning_info.bp_sub_date}}</td>
                                            <td>{{item.zoning_info.bp_app_date}}</td>
                                            <td>{{ item.zoning_info.bp_sub_date ? item.zoning_info.bp_range : ''}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="!['developer','developerbc'].includes(current_user.role.toLowerCase())">
                        <div class="row d-flex align-items-center pt-4 mb-4">
                            <div class="col-1"></div>
                            <div class="col-8">
                                <div class="type-c-box">
                                    <div>{{item.name}}</div>
                                    <div>{{item.type}}</div>
                                    <div>{{item.storey}} Storey</div>
                                </div>
                            </div>
                            <div class="col-2"><span class="btn btn-primary-reverse" v-b-modal="'consultant-'+groupInfo.ptc.fid +'-'+key_i">Consultant</span></div>
                            <div class="col-1"></div>
                        </div>
                        <div class="col-12">
                            <table class="table">
                                <thead class="fp-header">
                                    <th>Unit Type</th>
                                    <th>Bldg</th>
                                    <th>Units</th>
                                    <th>Floor Area</th>
                                    <th>Floor Area (gross)</th>
                                    <th>FSR/FAR</th>
                                    <th>Parking</th>
                                    <th>Status</th>
                                </thead>
                                <tbody class="fp-body">
                                    <tr v-for="(value, key) in item.details" :key="key">
                                        <td>{{value.type}}</td>
                                        <td>{{value.building}}</td>
                                        <td>{{value.units}}</td>
                                        <td>{{value.floor_area?value.floor_area+' sqft':''}}</td>
                                        <td>{{value.gross?value.gross+' sqft':''}}</td>
                                        <td>{{value.fsr_far}}</td>
                                        <td>{{value.parking}}</td>
                                        <td><a :href="value.ref_link">{{value.status}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <b-modal :id="'consultant-'+groupInfo.ptc.fid +'-'+key_i" hide-footer :title="'Consultant'">
                            <table class="table text-left permit-table table-borderless">
                                <thead class="table-borderless">
                                    <th>Consultant Type</th>
                                    <th>Consultant Title</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(consultant, key_c) in item.consultant" :key="key_c">
                                        <td>{{consultant.type}}</td>
                                        <td>{{consultant.name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-modal>
                    </template>
                    <template v-else>
                            <div class="border text-center" v-if="key_a != 'zoning_info_only'">
                                <div class="border-bottom py-2 bg-vb-primary text-light">{{ item.name }}</div>
                                <div class="border-bottom py-2">{{ item.type }}</div>
                                <div class="border-bottom py-2">{{ item.storey ? item.storey + '-storey' : '-' }}</div>
                                <div class="d-flex">
                                    <template v-for="(detail, index_key) in item.details">
                                        <div :key="index_key" class="col border px-0">
                                            <div class="py-1 font-weight-bold">{{ detail.type }}</div>
                                            <div class="py-1">{{ detail.units ? detail.units + ' Units' : '-'}}</div>
                                            <div class="py-1">{{ detail.fsr_far ? detail.fsr_far + ' FSR' : '-' }}</div>
                                            <div class="py-1">{{ detail.floor_area ? Number(detail.floor_area).toLocaleString() + ' Sq.Ft NFA' : '-' }}</div>
                                            <div class="py-1 mb-2">{{ detail.parking ? detail.parking + ' Parking Stalls' : '-' }}</div>
                                            <div class="py-1 font-weight-bold text-light" 
                                                    :style="{ 'background-color': setColor(detail.status) }" style="position: absolute;
                                            right: 0;
                                            left: 0;
                                            bottom: 0;"><a :href="['market strata' , 'market rental'].includes(detail.type.toLocaleLowerCase()) &&
                                                ['in planning', 'selling soon', 'leasing soon', 'now selling', 'now leasing', 'sold out', 'leased out'].includes(detail.status.toLowerCase())
                                             ? detail.ref_link : '#'" class="text-light">{{ detail.status }}</a></div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                    </template>
                </div>
            </div>
    </div>                   
</template>
<script>
import {BModal, VBModal} from 'bootstrap-vue'
import { useUserStore } from '../../store/UserStore'
import {mapStores, mapState} from 'pinia'

export default {
    components: {
        'b-modal': BModal,
    },
    directives: {
        'b-modal': VBModal,
    },
    data() {
        return {
            landInfo: {},
            selectedDateMap: {},
            totalInfo: {}
        }
    },
    computed:{ 
        ...mapState(useUserStore, ['current_user']),
    },
    mounted: function() {
        // initGoogleMap(this.location.lat, this.location.lng, this.$refs.googleMap, this.location.icon)
        this.landInfo = this.groupInfo.ptc.land_info
        this.totalInfo = this.groupInfo.ptc.total_data
        Object.keys(this.landInfo).forEach((key) => {
            this.selectedDateMap[key] = { selectedDate: Object.keys(this.landInfo[key].reg)[0] }
        })
    },
    props: ['groupInfo', 'location'],
    methods: {
        setColor: function(data){
            let color = "";
                if (data) {
                    switch (data.toLocaleLowerCase()) {
                    case "in planning":
                        color = "#244260";
                        break;

                    case "selling soon":
                        color = "#3c6289";
                        break;

                    case "leasing soon":
                        color = "#969191";
                        break;

                    case "now selling":
                        color = "#6c90b6";
                        break;

                    case "now leasing":
                        color = "#b8b0b0";
                        break;

                    case "sold out":
                        color = "#a1bcd8";
                        break;

                    case "leased out":
                        color = "#d4d2d2";
                        break;

                    default:
                        color = "#000";
                        break;
                    }
                }
            return color;
        },
        keyIsInt: function(key) {
            return !isNaN(parseInt(key))
        },
        selectedDate: function(value, propertyName) {
            this.selectedDateMap = Object.assign({}, this.selectedDateMap, {[propertyName]: {selectedDate: value}})
        },
        statusSwitch (status) {
            switch (status) {
                case 'Denied':
                    return 'status-red';
                    break;
                case 'Not Submitted':
                    return 'status-grey';
                    break;
                case 'In Process':
                    return 'status-yellow';
                    break;
                case 'Approved':
                    return 'status-purple';
                    break;
                case 'Issued':
                    return 'status-blue';
                    break;
                case 'Completed':
                    return 'status-green';
                    break;
                default:
                    return 'status-transparent';
                    break;
            }
        }
    },
}
</script>
<style scoped>
tr:last-child {
    border-bottom: 1px solid #dee2e6;
}
.city-plan {
    height: 25px;
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 0px;
    background: #79B8F2;
    display: block;
    margin-left: -10px;
    margin-right: -10px;
}
</style>