<template>
    <div class="container d-flex h-100 px-4" style="background-color: transparent;">
        <div class="_max-width mx-auto">
            <h1 class="mt-15 pt-4 mb-3 text-center text-light _contact-title">LEARN MORE!</h1> 
            <h5 class="text-center text-light mb-4 font-weight-light">admin@realpropertydata.ca</h5>
            <div v-if="statusMessage" class="alert alert-success text-left">{{ statusMessage }}</div>
            <form @submit.prevent="checkForm">
                <div class="row">
                    <div class="form-group col-md-6">
                        <RpdInputForm v-model="form.name" place-holder="Name" input-type="text" />
                    </div>
                    <div class="form-group col-md-6">
                        <RpdInputForm v-model="form.email" place-holder="Email" input-type="email" required />
                    </div>
                </div>
                <div class="row my-2">
                    <div class="form-group col-md-6">
                        <RpdInputForm v-model="form.subject" place-holder="Company Name" input-type="text" />
                    </div>
                    <div class="form-group col-md-6">
                        <RpdInputForm v-model="form.contact" place-holder="Contact Number" input-type="text" required />
                    </div>
                </div>
                <div class="form-group">
                    <RpdTextAreaForm id="message" place-holder="Message" v-model="form.message" />
                </div>
                <p class="text-center">
                    <b-button style="width:15rem;" type="submit" pill variant="outline-vb-white">Submit</b-button>
                </p>
            </form>
        </div>
        <div id="bg__color"></div>
    </div>
</template>

<script>
import { BACKEND_URL } from '../variables.js';
import RpdInputForm from '../shared/form/RpdInputForm.vue';
import RpdTextAreaForm from '../shared/form/RpdTextAreaForm.vue';

export default {
    components: {
        RpdInputForm,
        RpdTextAreaForm
    },
    data() {
        return {
            statusMessage: '',
            inputValue: '',
            form: {
                name: '',
                email: '',
                subject: '',
                contact: '',
                message: '',
            }
        }
    },
    mounted: function () {
    },
    methods: {
        checkForm: function (e) {
            $.post(BACKEND_URL + 'api/feedback', {
                message: this.form
            }, (data, status) => {
                if (data.status) {
                    this.statusMessage = 'Thanks for your feedback!';
                    setTimeout(function () {
                        this.$router.push({ path: '/' });
                        this.$router.go();
                    }, 2000);
                }
            });
            e.preventDefault();
        }
    },
}
</script>

<style>
._contact-title {
    font-weight: 600;
}

._max-width {
    width: 60rem;
    margin: auto;
}
.wrapper{
    height: calc(100% - 120px) !important;
}

@supports (-webkit-touch-callout: none) {
}
</style>