<template>
    <section>
        <div class="container">
            <div class="text-left">
                <b-form v-on:submit.prevent="mlsEntryUpload">
                    <b-form-group v-if="!prefillFile.fileExist" id="input-group-5">
                        <b-form-file
                            v-model="uploadForm.floorPlan"
                            :state="Boolean(uploadForm.floorPlan)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            ></b-form-file>
                    </b-form-group>
                    <div v-else>
                        <a :href="prefillFile.link">{{prefillFile.title}}</a>
                    </div>
                    <b-form-group id="input-group-list-plan-name" label="Plan Name:" label-for="upload-list-plan-name">
                        <b-form-input v-model="uploadForm.planName" id="upload-list-plan-name" type="text" placeholder="Plan Name"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-list-bed" label="Beds:" label-for="upload-list-bed">
                        <b-form-input v-model="uploadForm.bed" id="upload-list-bed" type="text" placeholder="Beds"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-list-bath" label="Bath:" label-for="upload-list-bath">
                        <b-form-input v-model="uploadForm.bath" id="upload-list-bath" type="text" placeholder="Bath"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-list-unit-size" label="Unit Size:" label-for="upload-list-unit-size">
                        <b-form-input v-model="uploadForm.unitSize" id="upload-list-unit-size" type="text" placeholder="Unit Size"></b-form-input>
                    </b-form-group>
                    <!-- <b-form-group id="input-group-fp" label="FP Specs:" label-for="upload-fp">
                        <b-form-select id="upload-fp" v-model="uploadForm.fp" :options="mlsFP"></b-form-select>
                    </b-form-group> -->
                    <b-form-group id="input-group-list-owner" label="Owner Name:" label-for="upload-list-owner">
                        <b-form-input v-model="uploadForm.owner" id="upload-list-owner" type="text" placeholder="Owner"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-list-purchaser" label="Purchaser Name:" label-for="upload-list-purchaser">
                        <b-form-input v-model="uploadForm.purchaser" id="upload-list-purchaser" type="text" placeholder="Purchaser Name"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-list-pid" label="PID:" label-for="upload-list-pid">
                        <b-form-input v-model="uploadForm.pid" id="upload-list-pid" type="text" placeholder="PID"></b-form-input>
                    </b-form-group>
                    <label class="typo__label">FP Specs:</label>
                    <multiselect v-model="uploadForm.fp" :options="mlsFP" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="" label="name" track-by="value">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen" v-for="val in values">{{val.name}} </span></template>
                    </multiselect>
                    <b-form-group id="input-group-orientation" label="View Orientation:" label-for="upload-orientation">
                        <b-form-select id="upload-orientation" v-model="uploadForm.orientation" :options="mlsOrientation"></b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-balcony" label="Balcony:" label-for="upload-balcony">
                        <b-form-select id="upload-balcony" v-model="uploadForm.balcony" :options="balconyOrientation"></b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-balcony-area" label="Balcony Area:" label-for="upload-balcony-area">
                        <b-form-input v-model="uploadForm.balconyArea" id="upload-balcony-area" type="text" placeholder="Balcony Area"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-parking" label="Parking:" label-for="upload-parking">
                        <b-form-select id="upload-parking" v-model="uploadForm.pr" :options="mlsParking"></b-form-select>
                    </b-form-group>

                    <b-form-group id="input-group-floor-level" label="Floor Level:" label-for="upload-floor-level">
                        <b-form-select id="upload-floor-level" v-model="uploadForm.fl" :options="mlsFloorLevel"></b-form-select>
                    </b-form-group>

                    <!-- <label class="typo__label">Parking:</label>
                    <multiselect v-model="uploadForm.pr" :options="mlsParking" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="" label="name" track-by="value">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>

                    <label class="typo__label">Floor Level:</label>
                    <multiselect v-model="uploadForm.fl" :options="mlsFloorLevel" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="" label="name" track-by="value">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect> -->

                    <div class="row">
                        <div class="col-3">
                            <b-form-group id="input-group-list-unit" label="Unit:" label-for="upload-list-unit">
                                <b-form-input v-model="uploadForm.unit" id="upload-list-unit" type="text" placeholder="Unit Number"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-9 pt-4">
                            <!-- {{project.short_address}} -->
                        </div>
                    </div>
                    <label for="upload-list-date">List Date</label>
                    <b-form-datepicker id="upload-list-date" v-model="uploadForm.listDate" reset-button class="mb-2"></b-form-datepicker>
                    <b-form-group id="input-group-list-price" label="List Price:" label-for="upload-list-price">
                        <b-form-input v-model="uploadForm.listPrice" id="upload-list-price" type="text" placeholder="List Price"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-price-backup" label="Price Backup:" label-for="upload-price-backup">
                        <b-form-select id="upload-price-backup" v-model="uploadForm.priceBackup" :options="priceBackupOption"></b-form-select>
                    </b-form-group>
                    <label for="upload-list-price-date">List Price Date</label>
                    <b-form-datepicker id="upload-list-price-date" v-model="uploadForm.listPriceDate" reset-button class="mb-2"></b-form-datepicker>
                    <b-form-group id="input-group-parking" label="Parking:" label-for="upload-parking">
                        <b-form-input v-model="uploadForm.parking" id="upload-parking" type="text" placeholder="Parking"></b-form-input>
                    </b-form-group>
                    <label for="upload-backup-file" style="font-size: 1.8rem;">Back-up File:</label>
                    <b-form-group id="upload-backup-file">
                        <b-form-file
                            v-model="uploadForm.backupfile"
                            :state="Boolean(uploadForm.backupfile)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            ></b-form-file>
                    </b-form-group>
                    <b-form-group id="input-group-locker" label="Locker:" label-for="locker">
                        <b-form-input v-model="uploadForm.locker" id="upload-locker" type="text" placeholder="Locker"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-gst" label="GST Included:" label-for="upload-gst">
                        <b-form-input v-model="uploadForm.gst" id="upload-gst" type="text" placeholder="GST Included"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-buyer-incentives" label="Buyer Incentives:" label-for="upload-buyer-incentives">
                        <b-form-input v-model="uploadForm.buyerIncentives" id="upload-buyer-incentives" type="text" placeholder="Buyer Incentives"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-realtor-incentives" label="Realtor Incentives:" label-for="upload-realtor-incentives">
                        <b-form-input v-model="uploadForm.realtorIncentives" id="upload-realtor-incentives" type="text" placeholder="Realtor Incentives"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-status" label="Status:" label-for="upload-status">
                        <b-form-select id="upload-status" v-model="uploadForm.status" :options="mlsStatus"></b-form-select>
                    </b-form-group>
                    <label for="upload-sold-date">Sold Date</label>
                    <b-form-datepicker id="upload-sold-date" v-model="uploadForm.soldDate" reset-button class="mb-2"></b-form-datepicker>
                    <b-form-group id="input-group-sold-price" label="Sold Price:" label-for="upload-sold-price">
                        <b-form-input v-model="uploadForm.soldPrice" id="upload-sold-price" type="text" placeholder="Sold Price"></b-form-input>
                    </b-form-group>

                    <b-form-group id="upload-group-assignment">
                        <b-form-checkbox-group v-model="uploadForm.assignment" id="update-assignment">
                        <b-form-checkbox value="1">Assignment</b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>

                    <b-button type="submit" variant="primary">Submit</b-button>
                </b-form>
            </div>
        </div>
    </section>
</template>
<script>
    import {BTabs, BTab, BModal, BModalDirective, VBModal, BForm, BFormGroup, 
    BFormInput, BFormSelect, BFormCheckbox, BFormCheckboxGroup,  BButton, BFormFile, BFormDatepicker} from 'bootstrap-vue';
    import {BACKEND_URL} from '../variables.js';
    import Multiselect from 'vue-multiselect'

    export default {
        components: { 
            'b-modal': BModal,
            'b-tabs': BTabs,
            'b-tab': BTab,
            'b-form': BForm,
            'b-form-group': BFormGroup,
            'b-form-input': BFormInput,
            'b-form-select': BFormSelect,
            'b-form-checkbox': BFormCheckbox,
            'b-form-checkbox-group': BFormCheckboxGroup,
            'b-button': BButton,
            'b-form-file': BFormFile,
            'b-form-datepicker': BFormDatepicker,
            Multiselect
        },
        directives: {
            'b-modal': VBModal,
        },
        data() {
            return {
                prefillFile: {fileExist: false},
                uploadForm: {
                    planName: '',
                    fp: [],
                    orientation: 11105,
                    unit: '',
                    bed: '',
                    bath: '',
                    unitSize: '',
                    listPrice: '',
                    pr: 11107,
                    locker: '',
                    gst: '',
                    buyerIncentives: '',
                    realtorIncentives: '',
                    // status: 8197,
                    soldDate: null,
                    listDate: null,
                    listPriceDate: null,
                    fl: 11106,
                    owner: '',
                    purchaser: '',
                    pid: '',
                    backupfile: null
                },
                formDisabled: {
                    planName: false,
                    fp: false,
                    orientation: false,
                    unit: false,
                    bed: false,
                    bath: false,
                    unitSize: false,
                    listPrice: false,
                    pr: false,
                    locker: false,
                    gst: false,
                    buyerIncentives: false,
                    realtorIncentives: false,
                    // status: false,
                    soldDate: false,
                    listDate: false,
                    listPriceDate: false,
                    fl: false,
                    owner: false,
                    purchaser: false,
                    pid: false,
                },
                mlsOrientation: [
                    {text: 'East', value: 11033},
                    {text: 'North', value: 11030},
                    {text: 'North East', value: 11035},
                    {text: 'North West', value: 11034},
                    {text: 'South', value: 11032},
                    {text: 'South East', value: 11037},
                    {text: 'South West', value: 11036},
                    {text: 'West', value: 11031},
                ],
                mlsFP: [],
                mlsParking: [],
                mlsFloorLevel: [],
                mlsStatus: [{text: '', value: 11482}, {text: 'Active', value: 8197}, {text: 'Sold', value: 8198}],
                balconyOrientation: [
                    {text: 'Yes', value: 'Yes'},
                    {text: 'No', value: 'No'}
                ],
                priceBackupOption: [
                    {text: '', value: ''},
                    {text: 'Verified', value: 'Verified'},
                    {text: 'Semi-Verified', value: 'Semi-Verified'},
                    {text: 'Educated Estimated', value: 'Educated Estimated'}
                ]
            }
        },
        mounted: function() {
            const vm = this;
            
            $.get({
                url: BACKEND_URL + 'api/get_fp_specs',
            }, (data, status) => {
                if (status === 'success') {
                    vm.mlsFP = data.fp_specs;
                    vm.mlsParking = data.parking;
                    vm.mlsFloorLevel = data.floor_level;
                }
            });

            $.get({
                url: BACKEND_URL + 'api/get_mls_by_cid_entry?cid=' + this.$route.query.cid + '&entry=' + this.$route.query.entry,
            }, (data, status) => {
                
                if (status === 'success') {
                    vm.uploadForm.planName = data.plan_name;
                    vm.uploadForm.fp = data.fp;
                    vm.uploadForm.orientation = data.orientation;
                    vm.uploadForm.unit = data.address;
                    vm.uploadForm.bed = data.beds;
                    vm.uploadForm.bath = data.baths;
                    vm.uploadForm.unitSize = data.unit_size;
                    vm.uploadForm.listPrice = data.list_price;
                    vm.uploadForm.pr = data.parking_ref;
                    vm.uploadForm.parking = data.parking;
                    vm.uploadForm.locker = data.locker;
                    vm.uploadForm.gst = data.gst;
                    vm.uploadForm.buyerIncentives = data.buyer;
                    vm.uploadForm.realtorIncentives = data.realtor;
                    vm.uploadForm.status = data.status;
                    vm.uploadForm.soldDate = data.sold_date;
                    vm.uploadForm.listDate = data.list_date;
                    vm.uploadForm.listPriceDate = data.list_price_date;
                    vm.uploadForm.assignment = data.assignment;
                    vm.uploadForm.fl = data.fl;
                    vm.uploadForm.owner = data.owner;
                    vm.uploadForm.pid = data.pid;
                    vm.uploadForm.purchaser = data.purchaser;
                    vm.uploadForm.balcony = data.balcony;
                    vm.uploadForm.balconyArea = data.balconyArea;
                    vm.uploadForm.priceBackup = data.priceBackup;
                    vm.uploadForm.soldPrice = data.sold_price

                    if (data.floor_plan_file) {
                        vm.prefillFile.link = data.floor_plan_file.link;
                        vm.prefillFile.title = data.floor_plan_file.title;
                        vm.prefillFile.fileExist = true;
                    }
                }
            });
        },
        methods:{
            mlsEntryUpload() {
                let formData = new FormData();
                formData.append('planName', this.uploadForm.planName);
                let fpArray = [];
                this.uploadForm.fp.forEach(
                    element => fpArray.push(element.value)
                );
                formData.append('fp', fpArray);
                formData.append('pr', this.uploadForm.pr);
                formData.append('fl', this.uploadForm.fl);
                formData.append('orientation', this.uploadForm.orientation);
                formData.append('unit', this.uploadForm.unit);
                formData.append('bed', this.uploadForm.bed);
                formData.append('bath', this.uploadForm.bath);
                formData.append('unitSize', this.uploadForm.unitSize);
                formData.append('listPrice', this.uploadForm.listPrice);
                formData.append('parking', this.uploadForm.parking);
                formData.append('locker', this.uploadForm.locker);
                formData.append('gst', this.uploadForm.gst);
                formData.append('buyerIncentives', this.uploadForm.buyerIncentives);
                formData.append('realtorIncentives', this.uploadForm.realtorIncentives);
                formData.append('status', this.uploadForm.status);
                formData.append('soldPrice', this.uploadForm.soldPrice);
                formData.append('files', this.uploadForm.floorPlan);
                formData.append('soldDate', this.uploadForm.soldDate);
                formData.append('listDate', this.uploadForm.listDate);
                formData.append('listPriceDate', this.uploadForm.listPriceDate);
                formData.append('fid', this.$route.query.fid);
                formData.append('cid', this.$route.query.cid);
                formData.append('entry', this.$route.query.entry);
                formData.append('owner', this.uploadForm.owner);
                formData.append('purchaser', this.$route.query.purchaser);
                formData.append('pid', this.uploadForm.pid);
                formData.append('backupfile', this.uploadForm.backupfile);
                formData.append('balcony', this.uploadForm.balcony);
                formData.append('balconyArea', this.uploadForm.balconyArea);
                formData.append('priceBackup', this.uploadForm.priceBackup);
                var vm = this;
                $.ajax({
					type: 'POST',
					url: 'https://www.realpropertydata.ca/backend/api/presale_mls_entry_edit',
                    data: formData,
                    processData: false,
                    contentType: false,
				}).done(function(response) {
                    // vm.uploadForm = {
                    //     planName: '',
                    //     fp: [],
                    //     orientation: 11105,
                    //     unit: '',
                    //     bed: '',
                    //     bath: '',
                    //     unitSize: '',
                    //     listPrice: '',
                    //     pr: 11107,
                    //     locker: '',
                    //     gst: '',
                    //     buyerIncentives: '',
                    //     realtorIncentives: '',
                    //     status: 8197,
                    //     soldDate: null,
                    //     listDate: null,
                    //     fl: 11106,
                    // };
                    // vm.$router.go(-1);
                    window.location.href = "/type?project="+vm.$route.query.project+"&fid="+vm.$route.query.fid+"&sid="+vm.$route.query.sid+"&uid="+vm.$route.query.uid + '&tab=unit';
                });
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>