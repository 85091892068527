<template>
  <header class="fs-header main-header"
    :class="{ 'front fw-header': $route.path == '/', 'border-bottom': !['/presale-database','/','/custom-login'].includes($route.path), 'bg-vb-primary': ['/presale-database','/custom-login'].includes($route.path) }">
    <div class="header-inner row justify-content-md-between">
      <div class="hidden-lg hidden-md d-none">
        <div class="fa fa-bars mobile-nav text-vb-primary" v-click-outside="$emit('menu-off')"
          @click="$emit('toggle-menu', !menuOn)"></div>
      </div>
      <div class="logo ml-4">
        <a v-if="$route.path != '/' && !['/presale-database','/','/custom-login'].includes($route.path)" class="logo-link" href="/listing-new"><img
            class="logo-img" src="/src/assets/images/viber_image_2023-11-07_11-30-39-588.webp"></a>
        <a v-else class="logo-link" href="/"><img class="logo-img"
            src="/src/assets/images/logo/RPD_logotype_skyblue.png"></a>
      </div>
      <div>

        <div class="header-col d-none d-md-block" v-if="!userStore.current_user.role && !isMobile"><a class="text-light"
            href="/presale-database">LOGIN</a></div>
        <template v-else-if="userStore.current_user.role && !isMobile">
          <div class="header-col"><a :class="!['/','/presale-database'].includes($route.path)? 'text-vb-primary' : 'text-light'" href="/"
              @click="$emit('logout')">LOGOUT</a></div>
        </template>
      </div>
    </div>
    <!-- <div class="mobile-nav-wrapper" :class="{ 'on': menuOn }">
      <ul class="text-center">
        <li v-if="!userLoggedIn"><a href="/presale-database">LOGIN</a></li>
        <template v-if="userLoggedIn">
          <li>
            <div class=""><a href="/" @click="$emit('logout')">LOGOUT tst</a></div>
          </li>
        </template>
        <li><a href="#">BOOK A DEMO</a></li>
        <li><a href="#">PACKAGES</a></li>
        <li><a href="/contact">CONTACT</a></li>
      </ul>
      <div class="social-icons-mobile text-center">
        <img class="footer-social-media" src="/images/assets/instagram_icon.png">
        <img class="footer-social-media" src="/images/assets/linkedin_icon.png">
      </div>
    </div> -->
  </header>
</template>

<script setup lang="ts">
import {
  BNavbar,
  BNavbarNav,
  BNavbarBrand,
  BNav,
  BNavItem,
  BCollapse,
  BNavbarToggle,
} from "bootstrap-vue";
import { onMounted, computed } from "vue";
import Vue from 'vue';
import ClickOutside from 'vue-click-outside';
import { useUserStore } from "../../store/UserStore";

Vue.directive('click-outside', ClickOutside);
const props = defineProps({
  userLoggedIn: Boolean,
  role: String,
  menuOn: Boolean
})

const userStore = useUserStore()
 
const isMobile = computed(() => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 768) {
    return true
  } else {
    return false
  }
}) 
</script>

<style lang="scss" scoped>
.fw-header {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 1.4rem;
  color: #416785;
}

div#nav-collapse.show,
div#nav-collapse.collapsing {
  padding: 1rem 2rem;
  width: 20rem;
  position: absolute;
  top: 90px;
  z-index: 99999999;
  right: 10px;
  background: #416785;
  border-radius: 10px;
}

div#nav-collapse.show>ul>li>a {
  color: white;
}

.footer-inner {
  text-align: left;
}

.front.loggedin {
  display: none;
}

.header-col {
  color: #13293E;
  font-family: Poppins;
  font-size: 1.4rem;
  font-weight: 500;
  margin-right: 40px;
}

.header-col a {
  color: #13293E;
}

.split {
  margin-left: auto;
}

.header-inner {
  color: #ffffff;
  background: transparent;
  align-items: center;
  cursor: pointer;
  height: 120px;
}

.logo-img {
  max-width: 310px;
}

h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 2.6rem;
}

h2 {
  font-size: 1.6rem;
}

.main-header {
  position: relative;
  background: #fff;
  box-shadow: none;
  transition: background 0.3s;
  z-index: 10;
  color: #13293E;
}

.main-header.front {
  position: sticky;
  top: 0;
  width: 100%;
  background: transparent;
}

.main-header.active {
  background: #13293E;
}

.footer-social-media {
  width: 60px;
}

.logo-link {
  margin-left: 30px;

}

section {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mobile-nav-wrapper {
  position: fixed;
  background: #13293E;
  font-size: 1.4rem;
  text-align: left;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  top: 0;
  left: -300px;
  width: 300px;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  /* Firefox 4 */
  -webkit-transition: all 0.3s linear;
  /* Safari and Chrome */
  -o-transition: all 0.3s linear;
  /* Opera */
  -ms-transition: all 0.3s linear;
  /* Explorer 10 */
}

.mobile-nav-wrapper.on {
  left: 0;
}

.mobile-nav-wrapper a {
  color: #fff;
  font-weight: 500;
  font-family: Poppins;
  line-height: 70px;
  display: inline-block;
  width: 100%;
}

.social-icons-mobile {}

@media (max-width: 991px) {
  .logo-img {
    width: 100%;
    margin-right: 1rem;
  }

  .logo {}

  .logo-link {
    margin-left: 0;
  }

  .mobile-nav {
    font-size: 2rem;
    margin-left: 20px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1100px) {
  .header-col {
    font-size: 1rem;
  }
}

a.text-vb-primary.font-weight-bold {
  font-size: 1.2rem;
}
</style>
