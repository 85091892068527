import { defineStore } from "pinia";
import UserData from "../utilities/UserData"
import { BACKEND_URL } from '../variables.js'
import axios from 'axios'
import 'regenerator-runtime'
axios.defaults.withCredentials = false;
export const useUserDataStore = defineStore("UserDataStore", {
    state: () => ({user_data: new UserData()}),
    actions: {
        setUserData(first_name, last_name, brokerage_name, team_name, user_email, phone_number, pic, link, user_id) {
            this.user_data = new UserData(first_name, last_name, brokerage_name, team_name, user_email, phone_number, pic, link, user_id)
        },
        async getData(){
            try {
                let res = await axios.get(BACKEND_URL + 'api/get_user_details')
                if(res){
                    this.user_data = new UserData(res.data.first_name, res.data.last_name, res.data.brokerage_name, res.data.team_name, res.data.user_email, res.data.phone_number, res.data.pic, res.data.link, res.data.user_id)
                    return true
                }
            } catch (error) {
                throw error
            }
        },
    },
    getters: {
      
    }
}) 