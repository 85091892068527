<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <div class="panel panel-default">
                <div class="panel-body">
                    <div class="text-center">
                    <h3 class='mb-3'><i class="fa fa-lock fa-4x"></i></h3>
                    <h3 style="font-size: 1rem" class='mb-5'>You can reset your password here.</h3>
                    <div class="panel-body">
        
                        <form id="password-reset-form" role="form" autocomplete="off" class="form" method="post" @submit="checkForm">
                            <div class="alert alert-danger text-left" v-if="errors.length">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                            <div class="alert alert-success text-left" v-if="statusMessage">{{statusMessage}}</div>
                            <div class="form-group">
                                <h3 v-if="!token" style="font-size: 1.3rem;" class="text-left mb-2">Current Password:</h3>
                                <div v-if="!token" class="input-group mb-4">
                                    <input name="password" placeholder="Current" class="form-control" type="password" v-model="currentPass">
                                </div>
                                <h3 style="font-size: 1.3rem;" class="text-left mb-2">New Password:</h3>
                                <div class="input-group mb-2">
                                    <input name="password" placeholder="New Password" class="form-control" type="password" v-model="newPass">
                                </div>
                                <h3 style="font-size: 1.3rem;" class="text-left mb-2">Confirm Password:</h3>
                                <div class="input-group">
                                    <input name="password" placeholder="Confirm Password" class="form-control" type="password" v-model="confirmPass">
                                </div>
                            </div>
                            <div class="form-group">
                                <input name="recover-submit" class="btn btn-lg btn-primary btn-block" value="Reset Password" type="submit">
                            </div>
                            
                            <input type="hidden" class="hide" name="token" id="token" value=""> 
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BACKEND_URL} from '../variables.js';

    export default {
        components: { 
        },
        data() {
            return {
                currentPass: '',
                newPass: '',
                confirmPass: '',
                query: this.$route.query,
                errors: [],
                token: '',
                statusMessage: '',
            }
        },
        mounted: function() {
            if (this.query.token) {
                this.token = this.query.token;
            }
            
        },
        methods: {
            checkForm: function (e) {
                const vm = this;
                this.errors = [];
                if (!this.token) {
                    if (!this.currentPass) {
                        this.errors.push('Current Password Required');
                    }
                }

                if (!this.newPass) {
                    this.errors.push('New Password required.');
                }

                if (!this.confirmPass) {
                    this.errors.push('Please confirm your password.');
                }

                if (this.newPass != this.confirmPass) {
                    this.errors.push('New Password doesn\'t match.');
                }
                if (!this.errors.length) {
                    $.post(BACKEND_URL + 'api/reset_password', {
                        currentPass: this.currentPass,
                        token: this.token,
                        newPass: this.newPass,
                    }, (data, status) => {
                        if (data.status) {
                            this.statusMessage = data.message;
                            setTimeout(function() {
                                vm.$router.push({ path: '/'});
                                vm.$router.go();
                            }, 500);
                        }
                        else {
                            this.errors.push(data.message);
                        }
                    });
                }

                e.preventDefault();
            }
        },
    }
</script>