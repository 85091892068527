<template>
    <div>
        <section class="scroll-con-sec hero-section" data-scrollax-parent="true" id="sec1">
            <div class="bg"  data-bg="images/bg/1.jpg" data-scrollax="properties: { translateY: '200px' }"></div>
            <div class="overlay"></div>
            <div class="hero-section-wrap fl-wrap">
                <div class="container">
                    <div class="intro-item fl-wrap">
                        <h2>We will help you to find all</h2>
                        <h3>Find great places , hotels , restourants , shops.</h3>
                    </div>
                    <div class="main-search-input-wrap">
                        <div class="main-search-input fl-wrap">
                            <div class="main-search-input-item" id="step1">
                                <div class="nice-select chosen-select" tabindex="0" multiple>
                                    <span class="current">Select a Type</span>
                                    <div class="nice-select-search-box">
                                        <input type="text" class="nice-select-search" placeholder="Search...">
                                    </div>
                                    <ul class="list">
                                    </ul>
                                </div>
                            </div>
                            <div class="main-search-input-item" id="step2">
                                <div class="nice-select chosen-select" tabindex="0" multiple>
                                    <span class="current">Select a City</span>
                                    <div class="nice-select-search-box">
                                        <input type="text" class="nice-select-search" placeholder="Search...">
                                    </div>
                                    <ul class="list">
                                    </ul>
                                </div>
                            </div>
                            <div class="main-search-input-item" id="step3">
                                <div class="nice-select chosen-select" tabindex="0">
                                    <span class="current">Select a Status</span>
                                    <div class="nice-select-search-box">
                                        <input type="text" class="nice-select-search" placeholder="Search...">
                                    </div>
                                    <ul class="list">
                                    </ul>
                                </div>
                            </div>
                            <button class="main-search-button" @click="onGo">Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bubble-bg"> </div>
            <div class="header-sec-link">
                <div class="container"><a href="#sec2" class="custom-scroll-link">Let's Start</a></div>
            </div>
        </section>
    </div>
</template>
<script>
    import arrive from 'arrive'
    import {BACKEND_URL} from '../variables.js';

    export default {
        props: [],
        data() {
            return {
                types: [
                    {name: 'Apartments', value: '2886'},
                    {name: 'Townhomes', value: '2870'},
                    {name: 'Mixed-Use Apartment', value: '2884'}
                ],
                type: [],
                city: [],
                status: [],
            }
        },
        mounted: function () {
            $('.chosen-select').niceSelect();
            $.get({
                url: BACKEND_URL + 'api/get_region',
            }, (data, status) => {
                if (status === 'success') {
                    let THIS = this;
                    THIS.types.forEach((a) => {
                        $('#step1 .nice-select.chosen-select.has-multiple ul').append('<li data-value="'+ a.value + '" class="option">' + a.name + '</li>');
                    });
                    data.forEach((a) => {
                        $('#step2 .nice-select.chosen-select.has-multiple ul').append('<li data-value="'+ a.tid.replace(' ', '') + '" class="option">' + a.name + '</li>');
                    });
                    // $('#step1 .current').on('DOMSubtreeModified', () => {
                    //     this.onSelectType($('#step1 .selected').data('value'))
                    // })
                    // $('#step2 .current').on('DOMSubtreeModified', () => {
                    //     this.onSelectCity($('#step2 .selected').data('value'))
                    // })
                }
            });
            $.get({
                url: BACKEND_URL + 'api/get_status',
            }, (data, status) => {
                if (status === 'success') {
                    data.forEach((a) => {
                        $('#step3 .nice-select.chosen-select ul').append('<li data-value="'+ a.tid.replace(' ', '') + '" class="option">' + a.name + '</li>');
                    })
                    // $('#step3 .current').on('DOMSubtreeModified', () => {
                    //     this.onSelectStatus($('#step3 .selected').data('value'))
                    // })
                }
            });
        },
        methods: {
            // onSelectType(val) {
            //     this.type = val;
            // },
            // onSelectCity(val) {
            //     this.city = val;
            // },
            // onSelectStatus(val) {
            //     this.status = val;
            // },
            onGo() {
                let q = {};
                q['type[]'] = new Array();
                q['city[]'] = new Array();
                q['status[]'] = new Array();
                $('#step1 .nice-select.chosen-select.has-multiple ul.list').find('.selected').each(function() {
                    q['type[]'].push($(this).attr('data-value'));
                });
                $('#step2 .nice-select.chosen-select.has-multiple ul.list').find('.selected').each(function() {
                    q['city[]'].push($(this).attr('data-value'));
                });
                $('#step3 .nice-select.chosen-select ul.list').find('.selected').each(function() {
                    q['status[]'].push($(this).attr('data-value'));
                });
                this.$router.push({ path: '/listing', query: q})
            }
        }
    }

</script>