<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <div class="panel panel-default">
                    <div class="panel-body">
                        <div class="alert alert-danger text-left" v-if="error">{{error}}</div>
                        <div class="alert alert-success text-left" v-if="statusMessage">{{statusMessage}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>      
</template>
<script>
    import {BACKEND_URL} from '../variables.js';

    export default {
        components: { 
        },
        data() {
            return {
                statusMessage: '',
                error: '',
                query: this.$route.query,
                uid: '',
                timestamp: '',
                hashed_pass: '',
            }
        },
        mounted: function() {
            if (typeof(this.query.uid) == 'undefined' || typeof(this.query.timestamp) == 'undefined' || typeof(this.query.hashed_pass) == 'undefined') {
                this.error = 'Missing Required Parameter in URL';
            }
            else {
                $.post(BACKEND_URL + 'api/api_onetime_login', {
                    uid: this.query.uid,
                    timestamp: this.query.timestamp,
                    action: 'login',
                    hashed_pass: this.query.hashed_pass,
                }, (data, status) => {
                    if (data.status) {
                        this.statusMessage = data.message;
                        let q = {};
                        q['token'] = data.token;
                        if (data.token) {
                            this.$router.push({ path: '/reset-password', query: q});
                            this.$router.go();
                        }
                    }
                    else {
                        this.error = data.message;
                    }
                });
            }
        },
        methods: {
        },
    }
</script>