<template>
  <div>
    <template v-if="$route.path == '/mls-list'">
        <div class="row">
        <div class="col-3">
            Unit#: {{ data.field_mls_address_value }}
        </div>
        <div class="col-3">
            List Date:
            <b-badge class="bg-vb-secondary text-light" style="font-size: medium">{{
            data.field_mls_list_date_value != '0' ? data.field_mls_list_date_value : '-'
            }}</b-badge>
        </div>
        </div>
        <div class="row">
        <div class="col-3">Beds: {{ data.field_mls_beds_value }}</div>
        <div class="col-3">
            Baths: {{ data.field_mls_baths_value }}
        </div>
        <div class="col-3">
            Unit Size: {{ Math.round(data.field_mls_unit_size_value).toLocaleString() }}
        </div>
        <div class="col-3">FP Specs: {{ data.fp_specs }}</div>
        </div>
        <div class="row mb-3">
        <div class="col-3">View: {{ data.view_orientation }}</div>
        <div class="col-3">Levels: {{ data.floor_levels }}</div>
        <div class="col-3">
            Parking: {{ data.parking ? data.parking : "-" }}
        </div>
        <div class="col-3">
            Stalls: {{ data.stalls ? data.stalls : "-" }}
        </div>
        </div>
    </template>  
    <template v-else-if="$route.path == '/unit-report'">
        <div class="row">
        <div class="col-3">
            Unit#: <span v-if="data.floor_plan.fid" v-b-modal="'mls-fp-'+data.floor_plan.fid" class="mls-floorplan">{{ data.field_mls_address_value }}</span>
        </div>
        <div class="col-3">
            List Date:
            <b-badge class="bg-vb-secondary text-light" style="font-size: medium">{{
            data.field_mls_list_date_value != '0' ? data.field_mls_list_date_value : '-'
            }}</b-badge>
        </div>
        </div>
        <div class="row">
        <div class="col-3">Beds: {{ data.field_mls_beds_value }}</div>
        <div class="col-3">
            Baths: {{ data.field_mls_baths_value }}
        </div>
        <div class="col-3">
            Unit Size: {{ Math.round(data.field_mls_unit_size_value).toLocaleString() }}
        </div>
        <div class="col-3">FP Specs: {{ data.fp_specs }}</div>
        </div>
        <div class="row mb-3">
        <div class="col-3">View: {{ data.view_orientation }}</div>
        <div class="col-3">Levels: {{ data.floor_levels }}</div>
        <div class="col-3">
            Parking: {{ data.parking ? data.parking : "-" }}
        </div>
        <div class="col-3">
            Stalls: {{ data.stalls ? data.stalls : "-" }}
        </div>
        </div>
    </template>
    <template v-else>
        <div class="row">
        <div class="col-3">
            Unit#: <span v-if="data[data.length - 1].mls_floor_plan_feature.fid" v-b-modal="'mls-fp-'+data[data.length - 1].mls_floor_plan_feature.fid" class="mls-floorplan">{{data[data.length - 1].mls_address}}</span>
        </div>
        <div class="col-3">
            List Date:
            <b-badge class="bg-vb-secondary text-light" style="font-size: medium">{{
            data[data.length - 1].mls_list_date != '0' ? data[data.length - 1].mls_list_date : '-'
            }}</b-badge>
        </div>
        <div class="col-3" v-if="current_user.role == 'admin'">
            <b-button variant="outline-vb-primary" class="p-1" v-b-modal="'mls-entry-new'" v-on:click="$emit('set-project-id', data[data.length - 1].cid)">Add Entry</b-button>
        </div>
        </div>
        <div class="row">
        <div class="col-3">Beds: {{ data[data.length - 1].mls_beds }}</div>
        <div class="col-3">
            Baths: {{ data[data.length - 1].mls_baths }}
        </div>
        <div class="col-3">
            Unit Size: {{ Math.round(data[data.length - 1].mls_unit_size).toLocaleString() }}
        </div>
        <div class="col-3">FP Specs: {{ data[data.length - 1].fp_specs }}</div>
        </div>
        <div class="row mb-3">
        <div class="col-3">View: {{ data[data.length - 1].orientation }}</div>
        <div class="col-3">Levels: {{ data[data.length - 1].levels }}</div>
        <div class="col-3">
            Parking: {{ data[data.length - 1].stalls ? data[data.length - 1].stalls : "-" }}
        </div>
        <div class="col-3">
            Stalls: {{ data[data.length - 1].mls_parking ? data[data.length - 1].mls_parking : "-" }}
        </div>
        </div>
    </template>
    
    <b-table :items="computed_mlsHistory" :fields="custom_header_fields" thead-class="bg-vb-secondary text-light position-sticky" responsive="sm" hover small striped>
        
        <template #cell(mls_psf)="tableData">
            <!-- <span :style="{'color' : setColor(tableData.item.price_back_up)}" v-if="tableData.item.mls_status == 'Sold' && ">
                {{ '$' + Math.round(Math.round(Number(tableData.item.mls_list_price)) / Math.round(Number(data[data.length - 1].mls_unit_size))).toLocaleString() }}
                {{ calculatePsf(data) }}
            </span> 
            <span :style="{'color' : setColor(tableData.item.price_back_up)}" v-else>
                {{ tableData.value > 0 ? '$'+ Math.round(tableData.value).toLocaleString() : '-' }}
                {{ calculatePsf(data) }}
            </span> -->
            <span :style="{'color' : setColor(tableData.item.price_back_up)}">
                {{ calculateResult(tableData.item) > 0 ? '$' +  calculateResult(tableData.item) : '-' }}
            </span>
        </template>
        <template #cell(modified_listing_type)="data">
          <span :class="{'text-vb-red' : ['resale','re-lease'].includes(data.value.toLowerCase()), 'text-vb-secondary': ['assignment'].includes(data.value.toLowerCase())}">{{ data.value }}</span> 
        </template>
        <template #cell(net_psf)="tableData">
            <span>
                {{ calculateNetPsf(tableData.item) }}
            </span>
        </template> 
        <template #cell(mls_list_price)="data">  
            <span :style="{'color' : setColor(data.item.price_back_up)}">{{ data.value > 0 ? '$'+ Math.round(data.value).toLocaleString() : '-' }}</span>
        </template>

        <template #cell(mls_sold_price)="data">  
            <span :style="{'color' : setColor(data.item.price_back_up)}">{{ data.value > 0 ? '$'+ Math.round(data.value).toLocaleString() : '-' }}</span>
        </template>
        <template #cell(buyer_incentives)="data">  
            <span>{{ data.value > 0 ? '$'+ Math.round(data.value).toLocaleString() : '-' }}</span>
        </template>
        <template #cell(net_price)="data">  
            <span>{{ getNetPrice(data.item).toLocaleString() }}</span>
        </template>
        <template #cell(action)="data">  
            <span @click="$emit('editMLSEntryNew', { projectId :data.item.cid, projectEntry: data.item.entry })"><font-awesome-icon :icon="['fas', 'edit']" class="_cursor-pointer text-vb-tertiary"/></span> | 
            <span  @click="$emit('deleteMLSEntry', { projectId :data.item.cid, projectEntry: data.item.entry })"><font-awesome-icon :icon="['fas', 'trash']" class="_cursor-pointer text-vb-red"/></span>
        </template>
        <template #cell(backup)="data">  
            <a :href="data.item.backup_file ? data.item.backup_file : '#'" target="_blank"><font-awesome-icon :icon="['fas', 'file-alt']" v-if="data.item.backup_file" class="_cursor-pointer text-vb-tertiary"/></a> 
        </template>
        <!-- Unit Mix --> 
        <template #cell(mls_need_approval)="data">
            <button class="btn btn-outline-vb-primary btn-sm">{{ Number(data.value) > 0 ? 'Approve' : 'UnApprove'}}</button>
        </template>
        <template #cell(backup_file)="data">
            <p class="m-0" v-if="data.value"><a :href="data.value" target="_blank">File</a></p>
            <p class="m-0" v-else>No preview available</p>
        </template>
        <!-- End of Unit Mix  -->
        <template #cell()="data">
            {{data.value ? data.value : '-'}}
        </template>
    </b-table>
  </div>
</template>

<script setup lang="ts">
import { BBadge } from 'bootstrap-vue'
import {ref, onMounted, computed} from 'vue';

import { useUserStore } from '../../store/UserStore' 
import { useRouter } from 'vue2-helpers/vue-router'; 

const route = useRouter()
const current_user = ref(useUserStore().current_user)

const props = defineProps({
    data: null,
    mlsHistory: null,
    listing_type: null,
    project_status: null,
    header_fields:null,
    occupancy_date: null,
    sold_out_date: null
});

const mlsHistory_model = computed(() => props.mlsHistory)

const computed_mlsHistory = computed(() => { 
    return props.mlsHistory
    // if(props.mlsHistory != null)
    //     return current_user.value.role != 'admin' ? props.mlsHistory.filter(x => x.mls_list_price != '-') : props.mlsHistory
})

let custom_header_fields = ref()

const setHeaderFields = () => {
    switch(route.currentRoute.path) {
        case '/mls-list':
            if(current_user.value.role == 'admin'){
                custom_header_fields.value = [
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "Beds" , key: 'mls_beds' },
                    { label: "Baths" , key: 'mls_baths' },
                    { label: "$PSF" , key: 'mls_psf' }, 
                    { label: "Sold Price" , key: 'mls_sold_price' }, 
                    { label: "SP SQFT", key: 'mls_sp_sqft' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: 'Collection Type', key: 'collection_type' },
                ]
            }
            else {
                custom_header_fields.value = [
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "$PSF" , key: 'mls_psf' },
                    { label: "Price Date", key: 'price_date' },
                    { label: "Incentives", key: 'buyer_incentives' },
                    { label: "Net Price", key: 'net_price' },
                    { label: "Net $PSF", key: 'net_psf' },
                    { label: "DOM", key: 'mls_dom' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                ]
            }
        break;

        case '/type':
            switch(props.project_status.toLowerCase()){
                case 'now leasing':
                    if(current_user.value.role == 'admin'){
                        custom_header_fields.value = [
                            { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                            { label: "Price Date", key: 'mls_price_date' },
                            { label: "DOM", key: 'mls_dom'},
                            { label: "List Price", key: 'mls_list_price'},
                            { label: "$PSF" , key: 'mls_psf' },
                            { label: "Incentives", key: 'buyer_incentives' },
                            { label: "Sold Date", key: 'mls_sold_date' },
                            { label: "Action", key: 'action' },
                            { label: "Last Modified", key: 'last_modified' },
                            { label: "Listing Type", key: 'modified_listing_type', formatter: setListingType},
                            { label: "Backup File", key: 'backup' },
                        ]
                    }
                    else {
                        custom_header_fields.value = [
                            { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                            { label: "Price Date", key: 'mls_price_date' },
                            { label: "DOM", key: 'mls_dom'},
                            { label: "List Price", key: 'mls_list_price'},
                            { label: "$PSF" , key: 'mls_psf' },
                            { label: "Incentives", key: 'buyer_incentives' },
                            { label: "Sold Date", key: 'mls_sold_date' },
                            { label: "Listing Type", key: 'modified_listing_type', formatter: setListingType},
                        ]
                    }
                break;

                default:
                if(current_user.value.role == 'admin'){
                    custom_header_fields.value = [
                        { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                        { label: "Price Date", key: 'mls_price_date' },
                        { label: "DOM", key: 'mls_dom'},
                        { label: "List Price", key: 'mls_list_price'},
                        { label: "$PSF" , key: 'mls_psf' },
                        { label: "Incentives", key: 'buyer_incentives' },
                        { label: "Net Price", key: 'net_price' },
                        { label: "Net $PSF", key: 'net_psf' },
                        { label: "Sold Date", key: 'mls_sold_date' },
                        { label: "Sold Price", key: 'mls_sold_price' },
                        { label: "Action", key: 'action' },
                        { label: "Last Modified", key: 'last_modified' },
                        { label: "Listing Type", key: 'modified_listing_type', formatter: setListingType},
                        { label: "Backup File", key: 'backup' },
                    ]
                }
                else {
                    custom_header_fields.value = [
                        { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                        { label: "Price Date", key: 'mls_price_date' },
                        { label: "DOM", key: 'mls_dom'},
                        { label: "List Price", key: 'mls_list_price'},
                        { label: "$PSF" , key: 'mls_psf' },
                        { label: "Incentives", key: 'buyer_incentives' },
                        { label: "Net Price", key: 'net_price' },
                        { label: "Net $PSF", key: 'net_psf' },
                        { label: "Sold Date", key: 'mls_sold_date' },
                        { label: "Sold Price", key: 'mls_sold_price' },
                        { label: "Listing Type", key: 'modified_listing_type', formatter: setListingType},
                    ]
                }
                break;
            }     
        break; 
        
        case '/unit-report':
            if(current_user.value.role == 'admin'){
                custom_header_fields.value = [
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "Beds" , key: 'beds' },
                    { label: "Baths" , key: 'baths' },
                    { label: "$PSF" , key: 'mls_psf' }, 
                    { label: "Sold Price" , key: 'mls_sold_price' }, 
                    { label: "SP SQFT", key: 'mls_sp_sqft' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: 'Collection Type', key: 'collection_type' },
                ]
            }
            else {
                custom_header_fields.value = [
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "$PSF" , key: 'mls_psf' },
                    { label: "Price Date", key: 'price_date' },
                    { label: "Incentives", key: 'buyer_incentives' },
                    { label: "Net Price", key: 'net_price' },
                    { label: "Net $PSF", key: 'net_psf' },
                    { label: "DOM", key: 'mls_dom' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                ]
            }
        break;

        default:
            if(current_user.value.role == 'admin'){
                    custom_header_fields.value = [
                        { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                        { label: "Price Date", key: 'mls_price_date' },
                        { label: "DOM", key: 'mls_dom'},
                        { label: "List Price", key: 'mls_list_price'},
                        { label: "$PSF" , key: 'mls_psf' },
                        { label: "Incentives", key: 'buyer_incentives' },
                        { label: "Net Price", key: 'net_price' },
                        { label: "Net $PSF", key: 'net_psf' },
                        { label: "Sold Date", key: 'mls_sold_date' },
                        { label: "Action", key: 'action' },
                        { label: "Backup File", key: 'backup' },
                    ]
                }
            else {
                custom_header_fields.value = [
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: "Price Date", key: 'mls_price_date' },
                    { label: "DOM", key: 'mls_dom'},
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "$PSF" , key: 'mls_psf' },
                    { label: "Incentives", key: 'buyer_incentives' },
                    { label: "Net Price", key: 'net_price' },
                    { label: "Net $PSF", key: 'net_psf' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                ]
            } 
        break;
    }
}

const setColor = (pb) => {
        let color = ""
        let priceBackUp = ""

        if(route.currentRoute.path == '/mls-list')
            priceBackUp = props.data.field_price_back_up_value 
        else if( ['/type','/unit-report'].includes(route.currentRoute.path))
            priceBackUp = pb
        else
            priceBackUp = props.mlsHistory[0].price_back_up
        switch (priceBackUp) {
            case 'Verified':
            color = "#0b36dc"
            break;
            case 'Educated Estimated':
            color = "#dc3545"
            break;
            case 'Semi-Verified':
            color = "#FF6F16"
            break;
        }  
        return color
}

const calculateResult = (data) => {
    let res
    if(route.currentRoute.path == '/mls-list'){
        if(route.currentRoute.query['unittype[]']=='2954')
            res = Number(Number(data.mls_list_price) / Number(data.mls_unit_size)).toFixed(2)
        else 
            res = Math.round(Number(Number(data.mls_list_price) / Number(data.mls_unit_size)))

    } else if (['/type','/unit-mix'].includes(route.currentRoute.path)) { 
        if(['now leasing','leased out'].includes(props.project_status.toLowerCase()))
            res = Number(data.mls_list_price / data.mls_unit_size).toFixed(2)
        else 
            res = Math.round(Number(data.mls_list_price / data.mls_unit_size))

    } else {
        res = Math.round(data.mls_psf)
    }

    return res  
}

const getNetPrice = (data) => {
    const list_price = Math.round(data.mls_list_price)
    const incentive = Math.round(data.buyer_incentives)
    let res = list_price - incentive
    return res ? `$${res.toLocaleString()}` : '-';
}

const calculateNetPsf = (data) => {
    const net_price = getNetPrice(data)  
    let result = Number(Number(net_price) / Number(data.mls_unit_size))

    if(props.project_status && props.project_status.toLowerCase() == 'now leasing')
       result = Number(result.toFixed(2))
    else if (route.currentRoute.query['unittype[]']=='2954')
       result = Number(result.toFixed(2))
    else
        result = Math.round(Number(result))
     
    return Number(result) > 0 ? `$${Number(result).toLocaleString()}` : '-'
}


const setListingType = (value, key, item) => {
        let res = '';
        let priceDate = new Date(item.mls_price_date)
        let soldDate = new Date(item.mls_sold_date)
        let constructionCompletionDate = new Date(props.occupancy_date)
        let soldOutDate = new Date(props.sold_out_date) 
        
        let hasSoldStatusBeforeEntry = item.has_sold

        res = 'Presale'

        if(hasSoldStatusBeforeEntry){ 
            if(([priceDate, soldDate].some(date => date < constructionCompletionDate)))
                res = 'Assignment'

            if([priceDate, soldDate].some(date => date > constructionCompletionDate)) 
                res = 'Resale'
                
        } else {
            if([priceDate, soldDate].some(date => date > soldOutDate && date < constructionCompletionDate))
                res = 'Assignment'
            
            if([priceDate, soldDate].some(date => date > soldOutDate  && date > constructionCompletionDate))
                res = 'Resale'
        } 

        if(['now leasing' ,'leased out'].includes(props.project_status.toLowerCase())){
            switch(res) {
                case 'Presale' :
                    res = 'New Lease'
                break;

                case 'Resale':
                    res = 'Re-Lease'
                break;
            }
        }

        return res
                 
    }
onMounted(() => {
    if(['/mls-list','/type','/unit-mix','/unit-report'].includes(route.currentRoute.path.toLocaleLowerCase()))
        setHeaderFields()
}) 
</script>

<style lang="scss" scoped></style>
