import { defineStore } from "pinia";
import User from "../utilities/User"
import { BACKEND_URL } from '../variables.js'
import axios from 'axios'
import 'regenerator-runtime'
import { useLocalStorage } from "@vueuse/core"

export const useUserStore = defineStore("UserStore", {
    state: () => ({current_user: new User(), userGutSelected: useLocalStorage('userGutSelected', new Array(), { mergeDefaults: true })}),
    actions: {
        setUser(bc_access, first_name, last_name, mail, role,status, uid) {
            this.current_user = new User(bc_access, first_name, last_name, mail, role,status, uid)
        },
        async checkLogin(){
            try {
                let res = await Promise.all([axios.get(BACKEND_URL + 'api/current_user'), axios.get(BACKEND_URL + 'api/get_role')]);
                let loggedIn = false
                console.log(res,'HERE user store')
                res.forEach(x => { 
                    if(x.data.role != "" && x.data.role != undefined)
                    { 
                        if(x.data.status == 1){ 
                            this.setUser(x.data.bcaccess, x.data.first_name, x.data.last_name, x.data.mail, x.data.role, x.data.status, x.data.uid)
                        }else{
                            this.current_user.role = x.data.role 
                        }
                        loggedIn = true  
                    }
                })
 
                return loggedIn
            } catch (error) {
                throw error
            }
        },
        
        getUser(){
            return this.current_user
        },
    },
    getters: {
        isLogin(state){
            if(state.current_user.status || state.current_user.status != undefined)
                return true

            return false
        }

    },
    persist: true,

}) 