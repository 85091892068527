export default class User {
    constructor (bc_access, first_name, last_name, mail, role, status, uid){
        this.bc_access = bc_access
        this.first_name = first_name;
        this.last_name = last_name;
        this.mail = mail;
        this.role = role;
        this.status = status;
        this.uid = uid;
    }
}