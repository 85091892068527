<template>
  <div class="page-loader" v-if="isLoading">
    <div class="loading-width">  
        <h2 class="text-center">Page Loading</h2>
        <b-progress
          :value="store.reqCounter.completedRequest"
          variant="dark" striped :animated="true"
          :max="store.reqCounter.requestLength"
          height="2rem"
          class="_progress-bar mt-4"
        ></b-progress>
        <h3 class="mt-10 text-center">
          {{
            Math.trunc(
              (this.store.reqCounter.completedRequest /
                this.store.reqCounter.requestLength) *
                100
            )
          }}%
        </h3> 
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { BButton, BProgress, BSpinner } from "bootstrap-vue";
import { useRequestCounter } from "../../store/RequestCounter"; 

export default {
  name: "PageLoader",
  data: () => ({
    vhtml: "",
    isLoading: true,
    store: useRequestCounter(),
    percent: 1,
  }),
  computed: {
    ...mapState(useRequestCounter, ["reqCounter", "getCounter"]),
  },
  components: {
    "b-button": BButton,
    "b-progress": BProgress,
    "b-spinner": BSpinner,
  },
  created() { 
    this.vhtml = document.querySelector("html");
    this.vhtml.classList.add("loading");
  },
  watch: {
    getCounter(newVal) {
      setTimeout(() => {
        document.querySelector(".page-loader").classList.add("loaded");

        this.vhtml.classList.remove("loading");

        //Completely hide page loader
        if (newVal >= this.store.reqCounter.requestLength) {
          setTimeout(() => {
            this.isLoading = false;
          }, 200);
        } 
      }, 500);
    },
  }, 
};
</script>

<style scoped>
.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: 0.2s opacity ease-out;
  background: #fff;
  padding:1rem;
}

._progress-bar {
  border: 5px solid #13293e;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.progress-bar {
  background-color: #13293e;
}

h1 {
  font-size: 4.5rem;
  font-weight: 300;
}

h2 {
  font-size: 3.5rem;
  font-weight: 300;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

@media only screen and (min-width: 976px) { 
  .loading-width{
  width:50vw;
  }
}

</style>
