<template>
    <div class="container">
        <div class="row pt-4" v-if="landInfo && landInfo.length > 0">
            <div class="col-md-12">
                <table class="table">
                    <thead class="fp-header">
                        <th>PID</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Registration Type</th>
                        <th>Sales Price</th>
                        <th>Registered Owner</th>
                        <th>Date</th>
                    </thead>
                    <tbody class="fp-body">
                        <tr v-for="(value, key) in landInfo" :key="key">
                            <td>{{value.pid}}</td>
                            <td>{{value.address}}</td>
                            <td>{{value.status}}</td>
                            <td>
                                <template v-if="selectedDateMap[key].selectedDate">
                                    {{value.reg[selectedDateMap[key].selectedDate].registration_type}}
                                </template>
                            </td>
                            <td>
                                <template v-if="selectedDateMap[key].selectedDate">
                                    {{value.reg[selectedDateMap[key].selectedDate].sale_price}}
                                </template>
                            </td>
                            <td>
                                <template v-if="selectedDateMap[key].selectedDate">
                                    {{value.reg[selectedDateMap[key].selectedDate].purchaser}}
                                </template>
                            </td>
                            <td v-if="selectedDateMap[key]">
                                <b-form-select v-model="selectedDateMap[key].selectedDate" @change="selectedDate($event, key)">
                                    <b-form-select-option v-for="(reg, regKey) in value.reg" :key="regKey+key" :value="regKey">
                                        {{regKey}}
                                    </b-form-select-option>
                                </b-form-select>
                            </td>
                            <td v-else>
                            </td>
                        </tr>
                    </tbody>
                </table> 
            </div>
        </div>
        <div class="row pt-4" v-if="groupInfo.ptc.display_total && ['admin'].includes(current_user.role)">
            <div class="col-md-12">
                <table class="table">
                    <thead class="fp-header">
                        <th></th>
                        <th>Units</th>
                        <th>Floor Area</th>
                        <th>Floor Area (gross)</th>
                        <th>FSR/FAR</th>
                        <th>Parking</th>
                        <th>Studio</th>
                        <th>1-bed</th>
                        <th>2-bed</th>
                        <th>3-bed</th>
                        <th>4-bed</th>
                    </thead>
                    <tbody class="fp-body">
                        <tr v-for="(value, key) in totalInfo" :key="key">
                            <td>{{key}}</td>
                            <td>{{value.total_units?value.total_units:'-'}}</td>
                            <td>{{value.total_floor_area | numFormat}}</td>
                            <td>{{value.gross | numFormat}}</td>
                            <td>{{value.total_fsr?value.total_fsr: '-'}}</td>
                            <td>{{value.total_parking?value.total_parking:'-'}}</td>
                            <td>{{value.total_studio?value.total_studio:'-'}}</td>
                            <td>{{value.total_1_bed?value.total_1_bed:'-'}}</td>
                            <td>{{value.total_2_bed?value.total_2_bed:'-'}}</td>
                            <td>{{value.total_3_bed?value.total_3_bed:'-'}}</td>
                            <td>{{value.total_4_bed?value.total_4_bed:'-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-20">
            <div v-for="(item, key_i) in utcInfo" :key="key_i" class="col-4 p-2">
                <div class="border border-vb-blue-accent text-center">
                                <div class="border-bottom py-2 bg-vb-primary text-light">{{ item.project_name }}</div>
                                <div class="border-bottom py-2">{{ item.project_type }}</div>
                                <div class="border-bottom py-2">{{ item.project_storey ? item.project_storey + '-storey' : '-' }}</div>
                                <div class="d-flex">
                                    <template v-for="(detail, index_key) in item.project_split">
                                        <div :key="index_key" class="col border px-0">
                                            <div class="py-1 font-weight-bold">{{ detail.gut }}</div>
                                            <div class="py-1">{{ detail.units ? detail.units + ' Units' : '-'}}</div>
                                            <div class="py-1">{{ detail.fsr ? detail.fsr + ' FSR' : '-' }}</div>
                                            <div class="py-1">{{ detail.gross ? detail.gross + ' Gross' : '-' }}</div>
                                            <div class="py-1">{{ detail.floor_net ? Number(detail.floor_net).toLocaleString() + ' Sq.Ft NFA' : '-' }}</div>
                                            <div class="py-1 mb-2">{{ detail.parking ? detail.parking + ' Parking Stalls' : '-' }}</div>
                                            <div class="py-1 font-weight-bold text-light" 
                                                    :style="{ 'background-color': setColor(detail.status) }" style="position: absolute;
                                            right: 0;
                                            left: 0;
                                            bottom: 0;">
                                            <a v-if="['admin'].includes(current_user.role) || (['market strata','market rental'].includes(detail.gut.toLocaleLowerCase()) && 
                                            ['in planning' ,'selling soon' ,'leasing soon' ,'now selling' ,'now leasing' ,'sold out' ,'leased out'].includes(detail.status.toLocaleLowerCase())
                                            ) " :href="detail.link"
                                                class="text-light">{{ detail.status }}</a>
                                                <span v-else>{{ detail.status }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div> 
            </div>
        </div>
    </div>                   
</template>
<script>
import {BModal, VBModal} from 'bootstrap-vue'
import RpdTable from '../../components/common/RpdTable.vue'
import { useUserStore } from '../../store/UserStore'
import {mapStores, mapState} from 'pinia'
export default {
    props: ['groupInfo','location','utcInfo'],
    components: {
        'b-modal': BModal,
        RpdTable
    },
    directives: {
        'b-modal': VBModal,
    },
    computed:{ 
        ...mapState(useUserStore, ['current_user']),
    },
    data() {
        return {
            landInfo: {},
            selectedDateMap: {},
            totalInfo: {},
            gInfo: this.groupInfo,
            uInfo: this.utcInfo
        }
    },
    created() { 
        // initGoogleMap(this.location.lat, this.location.lng, this.$refs.googleMap, this.location.icon)  
        this.landInfo = this.groupInfo ? this.groupInfo.ptc.land_info : {}
        this.totalInfo = this.groupInfo ? this.groupInfo.ptc.total_data : []
        Object.keys(this.landInfo).forEach((key) => {
            this.selectedDateMap[key] = { selectedDate: Object.keys(this.landInfo[key].reg)[0] }
        })
    }, 
    methods: { 
        setColor: function(data){
            let color = "";
                if (data) {
                    switch (data.toLocaleLowerCase()) {
                    case "in planning":
                        color = "#244260";
                        break;

                    case "selling soon":
                        color = "#3c6289";
                        break;

                    case "leasing soon":
                        color = "#969191";
                        break;

                    case "now selling":
                        color = "#6c90b6";
                        break;

                    case "now leasing":
                        color = "#b8b0b0";
                        break;

                    case "sold out":
                        color = "#a1bcd8";
                        break;

                    case "leased out":
                        color = "#d4d2d2";
                        break;

                    default:
                        color = "#000";
                        break;
                    }
                }
            return color;
        },
        keyIsInt: function(key) {
            return !isNaN(parseInt(key))
        },
        selectedDate: function(value, propertyName) {
            this.selectedDateMap = Object.assign({}, this.selectedDateMap, {[propertyName]: {selectedDate: value}})
        },
        statusSwitch (status) {
            switch (status) {
                case 'Denied':
                    return 'status-red';
                    break;
                case 'Not Submitted':
                    return 'status-grey';
                    break;
                case 'In Process':
                    return 'status-yellow';
                    break;
                case 'Approved':
                    return 'status-purple';
                    break;
                case 'Issued':
                    return 'status-blue';
                    break;
                case 'Completed':
                    return 'status-green';
                    break;
                default:
                    return 'status-transparent';
                    break;
            }
        },
        getColor: function(color) {
            switch (color) {
                case "Selling Soon":
                    return 'status-color-selling-soon'
                case "In Planning":
                    return 'status-color-in-planning'
                case "Leasing Soon":
                    return 'status-color-leasing-soon'
                case "Now Selling":
                    return 'status-color-now-selling'
                case "Now Leasing":
                    return 'status-color-now-leasing'
                case "Leased Out":
                    return 'status-color-leased-out'
                case "Sold Out":
                    return 'status-color-sold-out'
                default:
                    break;
            }
        },
    },
}
</script>
<style scoped>
tr:last-child {
    border-bottom: 1px solid #dee2e6;
}
.utc-sub {
    display: flex;
}
.utc-item {
    flex-grow: 1;
    text-align: center;
}
.utc-box {
    border: 5px solid #000;
    margin: 5px 15px;
    font-size: 1.3rem;
    min-width:35rem;
}
.utc-project-name.short {
    /* max-width: 350px; */
}
.utc-project-name {
    min-width: 350px;
    padding: 0 10px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.utc-project-name, .utc-project-type, .utc-project-storey {
    border-bottom: 5px solid #000;
}
.utc-project-gut {
    font-weight: bold;
}
.utc-project-gut, .utc-project-units, .utc-project-fsr, .utc-project-gross, .utc-project-floor, .utc-project-parking {
    margin: 10px 0;
}
.utc-project-status {
    padding: 10px 0;
    border-top: 5px solid #000;
}
.city-plan {
    height: 25px;
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 0px;
    background: #79B8F2;
    display: block;
    margin-left: -10px;
    margin-right: -10px;
}
.status-color-selling-soon {
    background: #5F6DFF;
}
.status-color-in-planning {
    background: #E7CE1C;
}
.status-color-leasing-soon {
    background: #1227FA;
}
.status-color-now-selling {
    background: #008000;
}
.status-color-now-leasing {
    background: #5DB05D;
}
.status-color-leased-out {
    background: #E3676F;
}
.status-color-sold-out {
    background: #C2131E;
}
</style>