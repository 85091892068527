import Options from './components/Options.vue';
import Listing from './components/Listing.vue';
import MLSList from './components/MLSList.vue';
import Report from './components/Report.vue';
import planningReport from './components/planningReport.vue';
import Projects from './components/NewProjects.vue';
import UnitMix from './components/UnitMix.vue';
import UTC from './components/UTC.vue';
import PasswordReset from './components/PasswordReset.vue';
import OnetimeLogin from './components/OnetimeLogin.vue';
import Contact from './components/Contact.vue';
import MLSEntry from './components/MLSEntry.vue';
import MLSEntryEdit from './components/MLSEntryEdit.vue';
import LandList from './components/LandList.vue';
import LandListNew from './components/LandListNew.vue'; 
import Plans from './components/Plans.vue'; 
import Permits from './components/Permits.vue';   
import VueRouter from 'vue-router';
import { useUserStore } from './store/UserStore'  
import { useUserDataStore } from './store/UserDataStore';
import { async } from 'regenerator-runtime';  

function loadComponent(component){
    return System.import(`./components/${component}.vue`)
} 
const authenticateUser = async (to, from, next) => {
    let res = await useUserStore().checkLogin()
    if(res)
    {
        next() 
        return
    }  

    else
        router.push({name:'restricted'}).catch(() => {})
}
const checkUser = async (to, from, next) => {
    await useUserStore().checkLogin() 

    next()
}  

const routes = [ 
    { name:'index',path: '/', component: () => loadComponent('Index'), beforeEnter: checkUser },
    { name:'login',path: '/presale-database', component: () => loadComponent('Login') },
    { name:'login',path: '/custom-login', component: () => loadComponent('CustomLogin') },
    { name:'options',path: '/options', component: () => loadComponent('Options')},
    { name:'report',path: '/report', component: () => loadComponent('Report')},
    { name:'contact',path: '/contact', component: () => loadComponent('Contact')},
    { name: 'restricted',path: '*', component: () => loadComponent('Restricted')},
    { path: '/saved-properties', component: () => loadComponent('SavedProperties')},
    { path: '/subscription-agreement', component: () => loadComponent('SubscriptionAgreement'), beforeEnter: authenticateUser},
    { path: '/listing-new', component: () => loadComponent('ListingNew'), beforeEnter: authenticateUser},
    { path: '/utc-report-planning', component: planningReport},
    { path: '/listing', component: Listing},
    { path: '/unit-mix', component: () => loadComponent('UnitMix'), beforeEnter: authenticateUser},
    { name:'unit-report', path: '/unit-report', component: () => loadComponent('UnitReport'), beforeEnter: authenticateUser},
    { name:'unit-report', path: '/unit-mix-report', component: () => loadComponent('UnitMixReport'), beforeEnter: authenticateUser},
    { path: '/mls-list', component: () => loadComponent('MLSList'), beforeEnter: authenticateUser },
    { path: '/land-list', component: LandList},
    { path: '/land-list-new', component: LandListNew},
    { path: '/project', component: Projects, beforeEnter: authenticateUser},
    { path: '/type', component: () => loadComponent('UTC'), beforeEnter: authenticateUser },
    { path: '/100millionclub', component: () => loadComponent('100MillionClub')},
    { name:'developer-results', path: '/developer-results', component: () => loadComponent('DeveloperResults'), beforeEnter: authenticateUser},
    { path: '/reset-password', component: PasswordReset },
    { path: '/onetime-login', component: OnetimeLogin },
    { path: '/mls_entry_update', component: MLSEntry },
    { path: '/mls_entry_edit', component: MLSEntryEdit },
    { path: '/plans', component: Plans },
    { path: '/planning-updates', component: Permits },
] 

const router = new VueRouter({
    routes,
    mode: 'history'
})
export default router