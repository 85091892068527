<template>
    <section class="justify-content-center" style="padding:0 10rem;height: max-content; min-height: 100%;">
        <b-modal ref="summary-table-modal" title="Unit Preview" ok-only> 
            <div class="row justify-content-center mt-2 mb-3"> 
                <template v-for="(item, index, key) in totalListingSummary"> 
                    <h4 :key="index" class="bg-vb-primary mx-2 p-2 rounded text-light" v-if="item[Object.keys(item)] > 0">
                        {{ Object.keys(item)[0] }} <b-badge variant="light">{{ item[Object.keys(item)] }}</b-badge>
                    </h4>
                </template>
            </div>
            <SummaryTable :report-detail-summary="unitPreviewSummary" :header-fields="unitPreviewSummaryHeader"/>
        </b-modal>
        <b-modal ref="unit-history-modal">
            <div>
                test
            </div>
        </b-modal>  
        <div class="row py-3">
            <div class="col-md-12">
                <template>
                    <div class="row mt-4 ml-1 justify-content-end align-items-center mb-3"> 
                        <div class="d-flex">
                            <span class="text-white p-2 mx-2 _price-backup" style="background-color:#0b36dc">Verified</span>
                            <span class="text-white p-2 mx-2 _price-backup" style="background-color:#FF6F16">Semi-Verified</span>
                            <span class="text-white bg-danger p-2 mx-2 _price-backup">Estimated</span>
                        </div>
                    </div>
                    
                    <div class="fields-toggle-wrapper" v-if="custom_header_field">
                        <div class="fields-toggle-block selected-fields">
                            <div class="row justify-content-between">
                                <div class="fields-toggle-title">Shown Data Point</div>
                                <font-awesome-icon
                                    style="font-size: 20px;cursor: pointer;position: absolute;top: 12px;right: 12px;"
                                    class="text-danger" @click="custom_header_field = false" :icon="['fas', 'times-circle']" />
                            </div>
                            <draggable class="row" :list="rpdTableList1" group="field"
                                @change="fieldsOn" :sort="true"> 
                            <template v-for="(element, index) in rpdTableList1" >
                                <div :class="{'d-none' : ['checkbox'].includes(element.key.toLocaleLowerCase())}" class="list-group-item _cursor-pointer" :key="'element-a123' + element.label"
                                >
                                {{ index }}. {{ element.label }}</div>
                            </template> 
                            </draggable>
                        </div>
                        <div class="fields-toggle-bar"></div>
                        <div class="fields-toggle-block">
                            <div class="fields-toggle-title">Drag and Drop Data Points</div>
                            <draggable class="row" :list="rpdTableList2" group="field" :sort="true">
                            
                                <template v-for="(element, index) in rpdTableList2" >
                                <div :class="{'d-none' : ['checkbox'].includes(element.key.toLocaleLowerCase())}" class="list-group-item _cursor-pointer" :key="'element-a123' + element.label"
                                >
                                {{ element.label }}</div>
                            </template> 
                            </draggable>
                        </div>
                    </div>
                    <div> 
                        <div class="row justify-content-between mb-4">
                            <h1 class="text-left">Unit Mix</h1>
                            <div class="row">
                                <b-button variant="vb-primary" @click="showUnitPreview" pill>Unit Preview</b-button> 
                                <img class="_field_icon mx-2 mt-10" src="/images/assets/fields.png" @click="custom_header_field = !custom_header_field"/>
                            <div class="pr-4">
                                <b-input-group>
                                    <b-input-group-append>
                                        <b-button variant="outline-vb-secondary rounded-left" @click="exportData()">
                                        <font-awesome-icon :icon="['fas', 'file-export']" />
                                        </b-button>
                                        <b-button variant="outline-vb-secondary" @click="generateImage()">
                                        <font-awesome-icon v-if="!loading" :icon="['fas', 'download']" />
                                        <b-spinner v-if="loading" variant="vb-primary" label="Spinning"></b-spinner> 
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            </div> 
                        </div>
                        <UnitMixTable
                            ref="unit_mix_table"
                            id="new-active-listing-table" 
                            v-model="totalRows" 
                            :totalRows="totalRows" 
                            :current-page="currentPage"
                            :per-page="perPage"
                            :listing_type="'active'"
                            :table_reference="'activeTable'"
                            :row_data="mergedListingData" 
                            :header_fields="rpdTableList1"
                            v-on:selectAllRows="selectAllRows"
                            v-on:refreshResults="refreshResults"
                            v-on:toggleFavorite="toggleFavorite"
                            > 
                        </UnitMixTable>  
                        <div class="row justify-content-between">
                                        <div style="margin-bottom: 8rem !important;">
                                            <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                                prev-text="Prev"
                                                first-text="First"  
                                                last-text="Last"
                                                size="md"
                                                >
                                            <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                                            <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                                            </b-pagination> 
                                        </div>
                                                
                                        <div class="px-3"> 
                                            <b-form-select v-model="perPage" :options="rowOptions" class="mt-15"></b-form-select>
                                        </div>
                                    </div>
                    </div>
                  
                    <b-modal v-for="(d, k) in mergedListingData" :id="'mls-fp-'+d.mls_floor_plan_feature.fid" hide-footer :key="k">
                        <div v-if="d.mls_floor_plan_feature.fid" class="col-md-12 d-block text-center">
                            <a target="_blank" :href="d.mls_floor_plan_feature.link"><img class='floorplan-pdf' :src="d.mls_floor_plan_feature.preview"></a>
                        </div>
                    </b-modal>
                    <b-modal v-for="(v_o, k_o) in project.mls_data" :id="'mls-active-'+k_o" hide-footer :key="k_o" title="Active Listing Summary">
                        <template>
                            <SummaryPopUp 
                            :data="v_o" 
                            :mlsHistory="v_o" 
                            :listing_type="'active'"
                            :project_status="'none'" 
                            v-on:set-project-id="cidSelected = $event" v-on:editMLSEntryNew="editMLSEntry" v-on:deleteMLSEntry="deleteMLSEntry" v-on:showBackupTextModal="showBackupTextModal"/>
                        </template>
                    </b-modal>
                </template>
            </div>
        </div>
    </section>
</template>
<script>
    import {BACKEND_URL} from '../variables.js';
    import {BModal, BModalDirective, VBModal, BForm, BFormGroup, 
    BFormInput, BFormSelect, BFormCheckbox, BFormCheckboxGroup,  BButton, BFormFile, BFormDatepicker, BBadge} from 'bootstrap-vue';
    import { useUserStore } from '../store/UserStore'; 
    import RpdTable from './common/RpdTable.vue'; 
    import {mapStores, mapState} from 'pinia' 
    import draggable from 'vuedraggable'; 
    import SummaryPopUp from './common/SummaryPopUp.vue';
    import SummaryTable from './common/SummaryTable.vue';
    import { BTabs, BTab } from 'bootstrap-vue'
    import UnitMixTable from './RpdTables/UnitMixTable.vue'
    import { BToast } from 'bootstrap-vue' 
    import { jsPDF } from "jspdf";
    import autoTable from 'jspdf-autotable'

    export default {
        components: { 
            'b-modal': BModal,
            'b-form': BForm,
            'b-form-group': BFormGroup,
            'b-form-input': BFormInput,
            'b-form-select': BFormSelect,
            'b-form-checkbox': BFormCheckbox,
            'b-form-checkbox-group': BFormCheckboxGroup,
            'b-button': BButton,
            'b-form-file': BFormFile,
            'b-form-datepicker': BFormDatepicker,
            'b-tabs': BTabs,
            'b-tab': BTab,
            'b-badge': BBadge,
            RpdTable,
            UnitMixTable,
            SummaryPopUp,
            SummaryTable,
            draggable
        },
        directives: {
            'b-modal': VBModal,
        },
        data() {
            return {
                arrayOfImages: [],
                loading: false,
                mergedListingData: null,
                unitMixData:null,
                listingTab:'active',
                unitPreviewSummaryHeader : [ 
                    { key: 'mls_plan_name', label: 'Plan Name' ,tdClass:'border-right-0', sortable: true },
                    { key: 'type', label: 'Type', sortable: true },
                    { key: 'baths', label: 'Baths', sortable: true },
                    { key: 'units', label: 'Total Units', sortable: true },
                    { key: 'confirmed_sold', label: 'Confirmed Sold', formatter: (value, row, key)  => { return value ? value : '-'},sortable: true },
                    { key: 'sold_percentage', label: 'Sold Percentage', formatter: (value, row, key)  => {                       
                        return key.confirmed_sold > 0 ? `${Math.round(Number((key.confirmed_sold / key.units) * 100))}%` : '-'
                    },sortable: true },
                ],
                rowOptions: [
                { value: 15, text: '15 rows' }, 
                { value: 25, text: '25 rows' }, 
                { value: 50, text: '50 rows' }, 
                { value: 100, text: '100 rows' }, 
                ],
                custom_header_field:false,
                backupModalText:'',
                rpdTableList1:[],
                rpdTableList2:[],
                totalRows:1, 
                currentPage: 1,
                perPage: 15,
                soldPerPage: 10,
                project: [],
                orderAsc: true,
                activeSortBy: 'mls_address',
                cidSelected: 0,
                MLSuploadForm: {
                    listPrice: '',
                    listPriceDate: null,
                    buyerIncentives: '',
                    realtorIncentives: '',
                    backupText: '',
                    files: null,
                    priceBackup: '',
                    soldDate: null
                },
                mlsStatus: [{text: '', value: 11482}, {text: 'Active', value: 8197}, {text: 'Sold', value: 8198}],
                priceBackupOption: [
                    {text: '', value: ''},
                    {text: 'Verified', value: 'Verified'},
                    {text: 'Semi-Verified', value: 'Semi-Verified'},
                    {text: 'Educated Estimated', value: 'Educated Estimated'}
                ],

            }
        },
        methods:{   
            roundToWholeNumber (value) {
                return Number(value) ? '$' + Math.round(value).toLocaleString() : '-'
            }, 
            setListingData(row, header){
                const vm = this
                const res = row.map((item, index) => {
                        let clonedRow = {...item}
                        return header.map(header => {
                                if (['custom_field_mls_beds_value'].includes(header.key) && !vm.isAdmin) { 
                                    let bed = '';
                                    let fpSpecs = '';
                                    bed = `${clonedRow[header.key].type}`;

                                    if (!['None', ''].includes(clonedRow['fp_specs']))
                                        fpSpecs = `${clonedRow['fp_specs']}`;

                                    if (clonedRow[header.key] == 's')
                                        bed = 'Studio'; 

                                    return clonedRow[header.key] = fpSpecs ? bed +' / '+ fpSpecs : bed;
                                }

                                if (['buyer_incentives','mls_list_price','net_psf','custom_net_psf','list_price_net','net_price','custom_mls_net_rent'].includes(header.key)) {
                                    let formatPrice = vm.roundToWholeNumber(clonedRow[header.key]); 
                                    
                                    return clonedRow[header.key] = formatPrice;
                                }

                                if(['mls_psf','custom_mls_net_psf'].includes(header.key)){
                                    if(this.project.status && 
                                    ['now leasing','now leasing 2','leased out','leased out 2'].includes(this.project.status.toLowerCase())) {
                                        let formatPrice = Number(clonedRow[header.key]).toFixed(2); 
                                    
                                        return clonedRow[header.key] && formatPrice != 'NaN' ? `$${formatPrice}` : '-';
                                    } else {
                                        let formatPrice = Math.round(Number(clonedRow[header.key])).toLocaleString(); 
                                    
                                        return clonedRow[header.key] && formatPrice != 'NaN' ? `$${formatPrice}` : '-';
                                    }
                                }

                                return clonedRow[header.key] ? clonedRow[header.key] : '-';
                            });
                    })

                return res
            },
            async getImageRatio(imgUrl) {    
                const max = {height:190,width:133} 

                return new Promise(async (resolve) => {
                    let image = new Image(); 
                    image.src=imgUrl
                    
                    image.onload = async function () { 
                    const ratio=image.height/image.width;
                
                    if(image.height>max.height||image.width>max.width){
                        if(image.height>image.width){
                            image.height=max.height;
                            image.width=image.height*(1/ratio);
                        // Making reciprocal of ratio because ration of height as width is no valid here needs width as height
                        }else if(image.width > image.height){
                            image.width=max.width;
                            image.height=image.width*ratio;
                        // Ratio is valid here 
                        }
                        }    
                        resolve({
                            status: 'success',
                            image: image
                        })
                    }
                })           
            },
            async generateImage(){
                const vm = this
                let promises = []

                vm.$bvToast.toast(`Download is in progress. Please wait for a moment while we prepare everything for you.`, {
                    title: 'Downloading...',
                    variant: 'info',
                    autoHideDelay: 5000, 
                })
                
                if(confirm("Include Floor Plans in the report?")){ 
                    vm.arrayOfImages = new Array() 
                    let filtered_floorplan_feature = [...vm.mergedListingData.filter(item =>  item.mls_floor_plan_feature.length != 0)]
                    
                    filtered_floorplan_feature.forEach(x => {
                            promises.push(vm.getImageRatio(x.mls_floor_plan_feature.preview))
                            vm.getImageRatio(x.mls_floor_plan_feature.preview).then(resolve => {
                                if(resolve.status == "success"){ 
                                this.arrayOfImages.push(
                                    {   project: {
                                        name: x.mls_plan_name,
                                        unit: x.mls_address,
                                        price: vm.roundToWholeNumber(x.mls_list_price),
                                        psf: x.mls_psf > 0 ? vm.roundToWholeNumber(x.mls_psf) : '-',
                                        priceDate: x.mls_list_date
                                        },
                                        image: resolve.image
                                    }
                                )
                            }
                        })
                    }) 
                }
                else{

                } 

                try {
                        // Wait for all promises to complete
                        await Promise.all(promises);

                        // After all images are processed, generate the report
                        await this.generateReport();

                        // Show success toast once report is generated
                        vm.$bvToast.toast(`You can now view the downloaded file`, {
                            title: 'Success!',
                            variant: 'success',
                            autoHideDelay: 5000,
                        });

                    } catch (error) {
                        // Handle any errors that occurred during the process
                        console.error('Error during image generation or report generation:', error);
                        vm.$bvToast.toast(`${'Something went wrong. \n Please inform us to fix this for you.'}`, {
                            title: 'Oops!',
                            variant: 'danger',
                            autoHideDelay: 5000,
                        });
                    } 
            },   
            
            async generateReport(){
                const vm = this 
                try { 
                let date = new Date();
                let formattedDate = vm.formatDate(date)
                let logo = document.createElement('img'); 
                logo.src = '/src/assets/images/viber_image_2023-11-07_11-30-39-588.webp'
                let rpdBackground = new Image()
                rpdBackground.src = '/images/assets/logo/RPD_logomark_black-1.png'
                rpdBackground.width = 200
                rpdBackground.height = 200

                const doc = new jsPDF(); 
                const pageWidth = doc.internal.pageSize.width;
                const pageHeight = doc.internal.pageSize.height;  

                let activeListingHeader = vm.rpdTableList1.filter(x => !['checkbox','favorite'].includes(x.key)).map((item) => {return {...item}})
                      
                const imgWidth = 20; 
                const imgHeight = 20;  
                doc.setFontSize(15);
                doc.setFont("helvetica", "bold"); 
                doc.setFontSize(10);
                const boxes = [
                    { color: [220, 53, 69], text: "Estimated" },   // #dc3545
                    { color: [255, 111, 22], text: "Semi-Verified" }, // #FF6F16
                    { color: [11, 54, 220], text: "Verified" },  // #0b36dc
                ];
                
                boxes.forEach((box, i) => {
                    const x =  (pageWidth - 41) - (i * 26);  // Adjust X position for horizontal alignment
                    doc.setFillColor(...box.color);
                    doc.rect(x - (i * 8), 20, 30, 8, 'F');  // Adjust box width to 50 for each
                    doc.setTextColor(255, 255, 255);
                    
                    if(['verified'].includes(box.text.toLowerCase()))
                        doc.text(box.text, x - (i * 4), 25); 
                    else if(box.text == 'Estimated')
                        doc.text(box.text, x + 7, 25);
                    else
                        doc.text(box.text, x - (i * 5), 25)
                }); 
                let mergedData = vm.mergedListingData
                const activeListingData = vm.setListingData(mergedData, activeListingHeader);    
                const lastActiveColumnData = mergedData.map(row => row.price_back_up)
                autoTable(doc,{ 
                    startY:30,
                    margin: {top: 15, right: 10, bottom: 30, left: 10}, 
                    styles:{fontSize: 8}, 
                    head: [activeListingHeader.map(header => header.label)],
                    body: activeListingData, 
                    headStyles: { fillColor: '#476E93', cellPadding: {top:3,bottom: 5, left: 1 } , valign:'center' }, 
                    didParseCell: function(data){
                        // console.log(data,'data')
                        const rowIndex = data.row.index;
                        if(data.cell.raw.toString().match(/^\$/) && data.row.section == 'body'){
                            data.cell.styles.textColor = vm.setColor(lastActiveColumnData[rowIndex]) 
                        } 
                    },
                    didDrawPage: function (data) {   

                    },
                })   
                    
                    const promise2 = new Promise((resolve) => { 
                        vm.arrayOfImages.forEach((x, index) => {
                            doc.addPage('l','mm')
                            const centerX = (pageWidth - x.image.width) / 2;
                            const centerY = (pageHeight+20 - x.image.height) / 2; 

                            doc.addImage(x.image.src, centerX, centerY, x.image.width, x.image.height)  
                                        
                            let previewTextObject = [
                                {label: 'Project Name', key: x.project.name},
                                {label: 'Unit #', key: x.project.unit},
                                {label: 'Price', key: x.project.price},
                                {label: 'PSF', key: x.project.psf},
                                {label: 'Price Date', key: x.project.priceDate},
                            ]

                            doc.setFont("helvetica", "normal");
                            let previewY = 25;

                            previewTextObject.forEach(x => { 
                                if(x.label.toLowerCase() == 'project name') { 
                                    doc.setTextColor("#000000");
                                    doc.setFontSize(12)
                                    doc.setFont("Helvetica", "bold")
                                    doc.text(x.key, 15, previewY)
                                } else {
                                doc.setFontSize(10)
                                doc.setFont("Helvetica", "normal")
                                doc.text(x.label, 15, previewY)
                                doc.text(x.key ? x.key : '-', 35, previewY) 
                                }
                                previewY += 4.5
                            })
                           
                        });
                            resolve("Promise 2 fulfilled")
                    })  
                        
                Promise.all([promise2]).then((values) => {
                    doc.setTextColor(19,41,62)
                    const pageCount = doc.internal.getNumberOfPages();   
                    for(var i = 1; i <= pageCount ; i++){ 
                        doc.setPage(i)
                        
                        const pageWidth = doc.internal.pageSize.width;
                        const pageHeight = doc.internal.pageSize.height;
                        const centerX = pageWidth / 2 - rpdBackground.width / 2;
                        const centerY = pageHeight / 2 - rpdBackground.height / 2;  
                        
                        doc.setFontSize(8) 
                        doc.text(formattedDate, 10, 10); 
                        doc.text("realpropertydata.ca", pageWidth - 36, 10)
                        doc.addImage(rpdBackground, 'PNG', centerX, centerY, rpdBackground.width, rpdBackground.height);
                        doc.addImage(logo, 'PNG', 10, doc.internal.pageSize.height - 17, 50, 10);
                    
                        doc.text('Page ' + i + ' of ' + String(pageCount), pageWidth - 30, pageHeight - 10);
                    }
                    vm.arrayOfImages.length = 0
                    window.open(doc.output('bloburl'), '_blank',"toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=500,width=1000,height=800");
                }); 
                } catch (error) { 
                        console.error( 'Error during image generation or report generation:',error);

                        throw new Error('Error during image generation or report generation:',error); 
                }
                
            },
        exportData () {
            const vm = this  
            try { 
                const csvContent = this.convertToCsv(vm.mergedListingData)
                const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'exported-data.csv')
                link.click()
                URL.revokeObjectURL(url); // Clean up the URL after download     
                vm.$bvToast.toast(`You can now view the downloaded file`, {
                        title: 'Success!',
                        variant: 'success',
                        autoHideDelay: 5000,
                });   
            } catch (error) { 
                console.error('Failed to fetch user data:', error);
                vm.$bvToast.toast(`${'Something went wrong. \n Please inform us to fix this for you.'}`, {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000,
                });
            }
        },
        convertToCsv(data){ 
            const vm = this  
            let headers = []
            headers.push(...vm.rpdTableList1.filter(header => !['checkbox','customData','customLocationData'].includes(header.key)))
            
            const rows = data.map(row => {
                return headers.map(header => {
                    const cell = row[header.key]; 

                    if(['0',0,undefined,null].includes(cell))
                        return '-'

                    if(typeof cell == 'object')
                        return cell.fp_specs ? cell.type + '/' + cell.fp_specs : cell.type

                    return typeof cell === 'string' && cell.includes(',')
                    ? `"${cell.replace(/"/g, '""')}"` // Escape commas and quotes
                    : cell;
                    })
            })

            let forRowheaders = headers.map(item => item.label) 
            const headerRow = forRowheaders.join(",")
            const csvRows = [headerRow, ...rows.map(row => row.join(','))]
            return csvRows.join('\n')
        },
        isLeased(row){ 
            let res = ""
            
            if(['leased out','now leasing'].includes(row.utc_status.toLowerCase()) && row.mls_status.toLowerCase() == 'sold')
                res = 'Leased';
            else
                res = row.mls_status;
            return res
        },
        toggleFavorite(item) { 
            const vm = this
            this.$bvToast.toast(`Updating...`, {
                title: 'Please wait',
                autoHideDelay: 3000, 
            })

            if(item.favor == '1'){
                $.ajax({
                    type: 'POST',
                    url: 'https://www.realpropertydata.ca/backend/api/mls_unfavor',
                    data: {mls: item.cid},
                })
                .fail(function(response) { 
                    vm.$bvToast.toast(`Something went wrong`, {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 3000, 
                    })
                }).done(function(response) {
                    item.favor = '0' 
                    vm.$bvToast.toast(`Removed to favorites`, {
                        title: 'Removed!',
                        variant: 'warning',
                        autoHideDelay: 3000, 
                    })
                }); 
            } else { 
                $.ajax({
                    type: 'POST',
                    url: 'https://www.realpropertydata.ca/backend/api/mls_favor',
                    data: {mls: item.cid},
                })
                .fail(function(response) { 
                    vm.$bvToast.toast(`Something went wrong`, {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 3000, 
                    })
                })
                .done(function(response) { 
                    item.favor = '1' 
                    vm.$bvToast.toast(`Added to favorites`, {
                        title: 'Success!',
                        variant: 'success',
                        autoHideDelay: 3000, 
                    })
                });
            }
        },
        showUnitHistory(data){
            this.$refs['unit-history-modal'].show()
        },
        showUnitPreview(){
            this.$refs['summary-table-modal'].show()
        },
        refreshResults() {
            this.mergedListingData = this.mergedListingData.filter(row => [row.rowSelected, row.favorite].includes(true))  
            this.totalRows = this.mergedListingData.length 
        },
        fieldsOn: function () {
            this.reportFields = [];  
        },
        formatToWholeNumber(value, key, row){ 
            return (typeof value == 'string' && value != '0') || (typeof value == 'number' && value > 0) ? `$${Math.round(value).toLocaleString()}` : '-'
        }, 
        padValue(value) {
            return (value < 10) ? "0" + value : value;
        },
        formatDate(dateVal) {
                let vm = this
                var newDate = new Date(dateVal);

                var sMonth = vm.padValue(newDate.getMonth() + 1);
                var sDay = vm.padValue(newDate.getDate());
                var sYear = newDate.getFullYear();
                var sHour = newDate.getHours();
                var sMinute = vm.padValue(newDate.getMinutes());
                var sAMPM = "AM";

                var iHourCheck = parseInt(sHour);

                if (iHourCheck > 12) {
                    sAMPM = "PM";
                    sHour = iHourCheck - 12;
                }
                else if (iHourCheck === 0) {
                    sHour = "12";
                }

                sHour = vm.padValue(sHour);

                return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
        },
        setUnitMixTableHeader() {
            const vm = this
            switch(vm.current_user.role) {
                case 'developer': 
                    vm.rpdTableList1 = [
                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                        { label: "Type", key: 'custom_field_mls_beds_value', sortable: true},
                        { label: "Baths", key: 'mls_baths' },
                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                            return Number(value) ? `${Number(value)}` : '-'
                        }, sortable: true },
                        { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                            let list_price = Number(row.mls_list_price);
                            let unit_size = Number(row.mls_unit_size);

                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                        }, sortable: true },
                        { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                        },sortable: true , thClass: '_w-15-rem'},
                        { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                            return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                        }, sortable: true},
                        { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                            let list_price = Number(row.net_price);
                            let unit_size = Number(row.mls_unit_size);

                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                        }, sortable: true},
                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                        { label: "Status", key:'mls_status', sortable: true}, 
                    ]
                
                    vm.rpdTableList2 = [
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "FP Specs", key: 'fp_specs' },
                    ]
                break;

                case 'developerbc': 
                        vm.rpdTableList1 = [
                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                            { label: "Type", key: 'custom_field_mls_beds_value', sortable: true},
                            { label: "Baths", key: 'mls_baths' },
                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                return Number(value) ? `${Number(value)}` : '-'
                            }, sortable: true },
                            { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                                let list_price = Number(row.mls_list_price);
                                let unit_size = Number(row.mls_unit_size);

                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                            }, sortable: true },
                            { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                            },sortable: true , thClass: '_w-15-rem'},
                            { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                                return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                            }, sortable: true},
                            { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                                let list_price = Number(row.net_price);
                                let unit_size = Number(row.mls_unit_size);

                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                            }, sortable: true},
                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                            { label: "Status", key:'mls_status', sortable: true}, 
                        ]
                
                        vm.rpdTableList2 = [
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View", key: 'orientation', sortable: true  },
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "FP Specs", key: 'fp_specs' },
                        ]
                break;

                default:
                    vm.rpdTableList1 = [
                                { label: 'Selected', key: 'checkbox'},
                                { label: "Favorite" , key: "favor",thClass:'align-content-center'},
                                { label: "Unit #", key: 'mls_address', sortable: true },
                                { label: "PID", key: 'mls_pid', sortable: true },
                                { label: "Plan Name", key: 'mls_plan_name', sortable: true },
                                { label: "Beds", key: 'custom_field_mls_beds_value' },
                                { label: "FP Specs", key: 'fp_specs' },
                                { label: "Baths", key: 'mls_baths', sortable: true  },
                                { label: "Size", key: 'mls_unit_size' , sortable: true},
                                { label: "View", key: 'orientation', sortable: true  },
                                { label: "Balcony", key: 'balcony',sortable: true},
                                { label: "Balcony Area", key: 'balcony_area',sortable: true},
                                { label: "Parking", key: 'stalls' },
                                { label: "Stalls", key: 'mls_parking' },
                                { label: "List Date", key: 'mls_list_date', sortable: true, tdClass:'_no-wrap'  },
                                { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                    return Number(value) ? `${Number(value)}` : '-'
                                }, sortable: true },
                                { label: "$PSF", key: 'mls_psf', formatter: this.formatToWholeNumber,sortable: true },
                                { label: "Buyer Incentives", key:'buyer_incentives',sortable: true},
                                { label: "Net Price", key:'list_price_net',formatter: (value, row, key) => {
                                    let res = Number(key.mls_list_price - key.buyer_incentives)
                                    return res > 0 ? `$${res.toLocaleString()}` : '-'
                                },sortable: true},
                                { label: "Net $PSF", key:'custom_net_psf',formatter: (value, row, key) => {
                                    let netPrice = Number(key.mls_list_price - key.buyer_incentives)
                                    let res = Math.round(Number(netPrice / key.mls_unit_size))
                                    return res > 0 ? `$${res.toLocaleString()}` : '-'
                                },sortable: true},
                                { label: "Price Date", key: 'mls_price_date',sortable: true, tdClass:'_no-wrap'  }, 
                                { label: "Sold Date", key:'mls_sold_date',sortable: true},
                                { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                    return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                }, sortable: true},
                                { label: "Status", key:'mls_status', sortable: true},
                    ]

                    vm.rpdTableList2 = [
                        { label: "Realtor Incentives", key:'realtor_incentives'},
                        { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                        { label: "Net Price", key:'net_price'},
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "Price Status", key: 'price_back_up' },
                    ]
                break;
            }

        },
        setRpdTableHeader(){ 
            const vm = this
            switch(vm.current_user.role) {
                    case 'developer':
                    if(vm.listingTab == 'active') { 
                        vm.rpdTableList1 = [
                                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                        { label: "Type", key: 'mls_beds', sortable: true},
                                        { label: "Baths", key: 'mls_baths' },
                                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                            return Number(value) ? `${Number(value)}` : '-'
                                        }, sortable: true },
                                        { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                                            let list_price = Number(row.mls_list_price);
                                            let unit_size = Number(row.mls_unit_size);

                                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                        }, sortable: true },
                                        { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        },sortable: true , thClass: '_w-15-rem'},
                                        { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                                            return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                                        }, sortable: true},
                                        { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                                            let list_price = Number(row.net_price);
                                            let unit_size = Number(row.mls_unit_size);

                                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                        }, sortable: true},
                                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                        ]
                        vm.rpdTableList2 = [
                        { label: "Status", key:'mls_status'}, 
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "FP Specs", key: 'fp_specs' },
                        ]
                    }
                    else {
                        vm.rpdTableList1 = [
                                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                        { label: "Type", key: 'mls_beds', sortable: true},
                                        { label: "Baths", key: 'mls_baths' },
                                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                            return Number(value) ? `${Number(value)}` : '-'
                                        }, sortable: true },
                                        { label: "$PSF", key: 'mls_psf', formatter: this.formatToWholeNumber, sortable: true },
                                        { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                                        { label: "Net Price", key:'net_price', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        }, sortable: true},
                                        { label: "Net $PSF", key:'net_psf'},
                                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                                        { label: "Sold Date", key:'mls_sold_date'},
                                        { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        }, sortable: true},
                        ]
                        vm.rpdTableList2 = [
                        { label: "Status", key:'mls_status'}, 
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "FP Specs", key: 'fp_specs' },
                        ]
                    }
                    break;

                    case 'developerbc':
                    if(vm.listingTab == 'active') { 
                        vm.rpdTableList1 = [
                                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                        { label: "Type", key: 'mls_beds', sortable: true},
                                        { label: "Baths", key: 'mls_baths' },
                                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                            return Number(value) ? `${Number(value)}` : '-'
                                        }, sortable: true },
                                        { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                                            let list_price = Number(row.mls_list_price);
                                            let unit_size = Number(row.mls_unit_size);

                                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                        }, sortable: true },
                                        { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        },sortable: true , thClass: '_w-15-rem'},
                                        { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                                            return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                                        }, sortable: true},
                                        { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                                            let list_price = Number(row.net_price);
                                            let unit_size = Number(row.mls_unit_size);

                                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                        }, sortable: true},
                                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                        ]
                        vm.rpdTableList2 = [
                        { label: "Status", key:'mls_status'}, 
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "FP Specs", key: 'fp_specs' },
                        ]
                    }
                    else {
                        vm.rpdTableList1 = [
                                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                        { label: "Type", key: 'mls_beds', sortable: true},
                                        { label: "Baths", key: 'mls_baths' },
                                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                            return Number(value) ? `${Number(value)}` : '-'
                                        }, sortable: true },
                                        { label: "$PSF", key: 'mls_psf', formatter: this.formatToWholeNumber, sortable: true },
                                        { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                                        { label: "Net Price", key:'net_price', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        }, sortable: true},
                                        { label: "Net $PSF", key:'net_psf'},
                                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                                        { label: "Sold Date", key:'mls_sold_date'},
                                        { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        }, sortable: true},
                        ]
                        vm.rpdTableList2 = [
                        { label: "Status", key:'mls_status'}, 
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "FP Specs", key: 'fp_specs' },
                        ]
                    }
                    break;

                    case 'realtor':
                        vm.rpdTableList1 = [
                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                        { label: "Type", key: 'mls_beds', sortable: true},
                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                            return Number(value) ? `${Number(value)}` : '-'
                        }, sortable: true },
                        { label: "$PSF", key: 'mls_psf', sortable: true },
                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                    ]
                    vm.rpdTableList2 = [
                    { label: "Realtor Incentives", key:'realtor_incentives'},
                    { label: "Status", key:'mls_status'},
                    { label: "Sold Date", key:'mls_sold_date'},
                    { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                        return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                    }, sortable: true},
                    { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                    { label: "Net Price", key:'net_price'},
                    { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                    { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                    { label: "Baths", key: 'mls_baths', sortable: true  },
                    { label: "Parking", key: 'stalls' },
                    { label: "Stalls", key: 'mls_parking' },
                    { label: "List Date", key: 'mls_list_date', sortable: true  },
                    { label: "View", key: 'orientation', sortable: true  },
                    { label: "Levels", key: 'levels', sortable: true  }
                    ]
                    break;

                    case 'admin': //admin role 
                        vm.rpdTableList1 = [
                                { label: 'Selected', key: 'checkbox'},
                                { label: "Unit #", key: 'mls_address', sortable: true },
                                { label: "PID", key: 'mls_pid', sortable: true },
                                { label: "Plan Name", key: 'mls_plan_name', sortable: true },
                                { label: "Beds", key: 'custom_mls_beds', sortable: true, formatter:(value, row, key) => {
                                    return key.mls_beds
                                } },
                                { label: "FP Specs", key: 'fp_specs' },
                                { label: "Baths", key: 'mls_baths', sortable: true  },
                                { label: "Size", key: 'mls_unit_size' , sortable: true},
                                { label: "View", key: 'orientation', sortable: true  },
                                { label: "Balcony", key: 'balcony',sortable: true},
                                { label: "Balcony Area", key: 'balcony_area',sortable: true},
                                { label: "Parking", key: 'stalls' },
                                { label: "Stalls", key: 'mls_parking' },
                                { label: "List Date", key: 'mls_list_date', sortable: true, tdClass:'_no-wrap'  },
                                { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                    return Number(value) ? `${Number(value)}` : '-'
                                }, sortable: true },
                                { label: "$PSF", key: 'mls_psf', formatter: this.formatToWholeNumber,sortable: true },
                                { label: "Buyer Incentives", key:'buyer_incentives',sortable: true},
                                { label: "Net Price", key:'list_price_net',formatter: (value, row, key) => {
                                    let res = Number(key.mls_list_price - key.buyer_incentives)
                                    return res > 0 ? `$${res.toLocaleString()}` : '-'
                                },sortable: true},
                                { label: "Net $PSF", key:'custom_net_psf',formatter: (value, row, key) => {
                                    let netPrice = Number(key.mls_list_price - key.buyer_incentives)
                                    let res = Math.round(Number(netPrice / key.mls_unit_size))
                                    return res > 0 ? `$${res.toLocaleString()}` : '-'
                                },sortable: true},
                                { label: "Price Date", key: 'mls_price_date',sortable: true, tdClass:'_no-wrap'  }, 
                                { label: "Sold Date", key:'mls_sold_date',sortable: true},
                                { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                    return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                }, sortable: true},
                        ]
                        vm.rpdTableList2 = [
                        { label: "Realtor Incentives", key:'realtor_incentives'},
                        { label: "Status", key:'mls_status'},
                        { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                        { label: "Net Price", key:'net_price'},
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "Price Status", key: 'price_back_up' },
                        ]
                    break;

                    default:
                    if(vm.listingTab == 'active') { 
                        vm.rpdTableList1 = [
                                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                        { label: "Type", key: 'mls_beds', sortable: true},
                                        { label: "Baths", key: 'mls_baths' },
                                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                            return Number(value) ? `${Number(value)}` : '-'
                                        }, sortable: true },
                                        { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                                            let list_price = Number(row.mls_list_price);
                                            let unit_size = Number(row.mls_unit_size);

                                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                        }, sortable: true },
                                        { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        },sortable: true , thClass: '_w-15-rem'},
                                        { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                                            return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                                        }, sortable: true},
                                        { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                                            let list_price = Number(row.net_price);
                                            let unit_size = Number(row.mls_unit_size);

                                            return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                        }, sortable: true},
                                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                        ]
                        vm.rpdTableList2 = [
                        { label: "Status", key:'mls_status'}, 
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "FP Specs", key: 'fp_specs' },
                        ]
                    }
                    else {
                        vm.rpdTableList1 = [
                                        { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                        { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                        { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                        { label: "Type", key: 'mls_beds', sortable: true},
                                        { label: "Baths", key: 'mls_baths' },
                                        { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                                        { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                            return Number(value) ? `${Number(value)}` : '-'
                                        }, sortable: true },
                                        { label: "$PSF", key: 'mls_psf', sortable: true },
                                        { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                                        { label: "Net Price", key:'net_price', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        }, sortable: true},
                                        { label: "Net $PSF", key:'net_psf'},
                                        { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                                        { label: "Sold Date", key:'mls_sold_date'},
                                        { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                        }, sortable: true},
                        ]
                        vm.rpdTableList2 = [
                        { label: "Status", key:'mls_status'}, 
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  },
                        { label: "FP Specs", key: 'fp_specs' },
                        ]
                    }
                    break;
            }   
        },
        formatTypeText(value){
            switch (value) {
                case 's':
                    return 'Studio'
                    break;
            
                default:
                    return value
                    break;
            }
        },
        showBackupTextModal(data) {
            this.backupModalText = data
            this.$root.$emit('bv::show::modal', 'backupTextModal')
        },
        selectAllRows({selectAll, tableRef}){ 
            this.mergedListingData.forEach(item => item.rowSelected = selectAll) 
        },
            mlsEntryUpload() {
                let formData = new FormData();
                formData.append('status', this.MLSuploadForm.status);
                formData.append('listPrice', this.MLSuploadForm.listPrice);
                formData.append('listPriceDate', this.MLSuploadForm.listPriceDate);
                formData.append('files', this.MLSuploadForm.floorPlan);
                formData.append('backupText', this.MLSuploadForm.backupText);
                formData.append('buyerIncentives', this.MLSuploadForm.buyerIncentives);
                formData.append('realtorIncentives', this.MLSuploadForm.realtorIncentives);
                formData.append('priceBackup', this.MLSuploadForm.priceBackup);
                formData.append('cid', this.cidSelected);
                formData.append('soldDate', this.MLSuploadForm.soldDate);
                formData.append('uid', this.$route.query.uid);
                var vm = this;
                $.ajax({
					type: 'POST',
					url: 'https://www.realpropertydata.ca/backend/api/presale_mls_entry_add_entry',
                    data: formData,
                    processData: false,
                    contentType: false,
				}).done(function(response) {
                    location.reload();
                });
            },
            setColor(data){
                let color = ""
                if(data){  
                    if(data == 'Verified')
                        color = "#0b36dc"

                    if(data == "Educated Estimated")
                        color = "#dc3545"

                    if(data == "Semi-Verified")
                        color = "#FF6F16"
                }   
                return color
            },
            deleteMLSEntry(cid, entry, k1, k2) { 
                if (this.current_user.role.toLocaleLowerCase() =='admin') {
                    if(confirm("Do you really want to delete this MLS Entry?")){
                        var vm = this;
                        $.ajax({
                            type: 'POST',
                            url: 'https://www.realpropertydata.ca/backend/api/presale_mls_entry_delete',
                            data: {cid: cid.projectId, entry: cid.projectEntry},
                        }).done(function(response) {
                            if (response.success) {
                                vm.project.mls_data[k1].splice(k2, 1);
                            }
                        });
                    }
                }
            },
            editMLSEntry(cid) {
                if (true) {
                    let link = 'mls_entry_edit?cid='+cid.projectId + '&entry=' + cid.projectEntry +'&project='+this.$route.query.project+'&fid='+this.$route.query.fid+'&uid='+this.$route.query.uid+'&sid='+this.$route.query.sid;
                    window.open(link, '_blank');
                }
            },
            activeSort(column) {
                if (this.activeSortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.activeSortBy = column;
            },
            soldSort(column) {
                if (this.soldSortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.soldSortBy = column;
            },
            setColor(data){
                let color = ""
                if(data){  
                    if(data == 'Verified')
                        color = "#0b36dc"

                    if(data == "Educated Estimated")
                        color = "#dc3545"

                    if(data == "Semi-Verified")
                        color = "#FF6F16"
                } 
                return color
            },
        },
        mounted: function() {
            const vm = this;
            this.setUnitMixTableHeader(); 

            $.get({
                url: BACKEND_URL + 'api/get_unit_mix_group?uid=' + this.$route.query.uid + '&unit_key=' + this.$route.query.unit_key + '&size_range=' + this.$route.query.size_range,
            }, (data, status) => {
                if (status === 'success') {
                    if(data){
                        let mapped = new Object(data) 
                        for(const key in mapped){
                            if(mapped.hasOwnProperty(key) && ['mls_last_date_active','mls_last_date_sold'].includes(key)) {
                                if(data[key] != null){ 
                                    data[key].forEach(mlsRow => { 
                                        mlsRow.rowSelected = false
                                        mlsRow.mls_status = vm.isLeased(mlsRow) 
                                        mlsRow.custom_field_mls_beds_value = {
                                            type : mlsRow.mls_beds == 's' ? 'Studio' : mlsRow.mls_beds == 'jr' ? 'Jr 1' + '-bed' : mlsRow.mls_beds + '-bed', 
                                            fp_specs: mlsRow.fp_specs.toLocaleLowerCase() != 'none' ? mlsRow.fp_specs : ''
                                        }
                                    }) 
                                }
                            }
                        }

                    if(mapped.mls_last_date_active)
                        vm.mergedListingData = [...mapped.mls_last_date_active]

                    if(mapped.mls_last_date_sold && mapped.mls_last_date_active)
                        vm.mergedListingData = [...vm.mergedListingData, ...mapped.mls_last_date_sold]
                    else 
                        vm.mergedListingData = [...mapped.mls_last_date_sold]

                    vm.totalRows = vm.mergedListingData.length
                    vm.project = mapped
                    }
                }
            });
        },
        watch: {   
            mergedListingData: function(newVal) {
                this.totalRows = newVal.length
            },
        },
        computed: {
            ...mapState(useUserStore,['current_user']), 
            totalListingSummary() {
                const vm= this
                let res = new Array() 

                if(vm.mlsActiveSort) 
                res.splice(0,0,{ 'Total Units' : [...vm.mlsActiveSort]})

                if(vm.mlsSoldSort) 
                res.splice(0,0,{ 'Total Units' : [...vm.mlsSoldSort]})

                if(vm.mlsActiveSort) 
                res.splice(1,0,{ 'Active Units' : vm.mlsActiveSort ? vm.mlsActiveSort.length : '-' })
            
                if(vm.mlsSoldSort) 
                res.splice(2,0,{ 'Confirmed Sold Units' : vm.mlsSoldSort ? vm.mlsSoldSort.length : '-' })

                return res
            },
            unitPreviewSummary() {
                const vm = this
                let activeData = vm.mlsActiveSort ? vm.mlsActiveSort : []
                let soldData = vm.mlsSoldSort ? vm.mlsSoldSort : []
                // let unreleasedData = vm.mlsOtherSort
                
                let mergedData = [...activeData, ...soldData]

                let grouped = mergedData.reduce((acc, {mls_plan_name, mls_status, fp_specs, mls_beds, mls_baths}) => {
                    if (!acc[mls_plan_name]) {
                        acc[mls_plan_name] = { mls_plan_name, units: 0, confirmed_sold: 0, type: {}, baths: 0 };
                    }

                    if(mls_status !== '' && mls_status.toLowerCase() == 'sold')
                        acc[mls_plan_name].confirmed_sold += 1;
                    
                        acc[mls_plan_name].units += 1;

                        acc[mls_plan_name].baths +=  Number(mls_baths);

                        acc[mls_plan_name].type = {
                            mls_beds: mls_beds,
                            fp_specs: fp_specs
                        } 
                    return acc
                },{})
                
                const groupedArray = Object.values(grouped); 
                return groupedArray 
            },
            isAdmin: {
                get() {
                    return this.current_user.role == 'admin'
                }
            },
            mlsSoldSort: function() {
                var ob = this.project.mls_last_date_sold;
                var vm = this;
                if (this.project.mls_last_date_sold && this.project.mls_last_date_sold.length < 2) {
                    return ob;
                }
                if (this.soldSortBy) {
                    if (this.soldSortBy == 'mls_plan_name') { 
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.soldSortBy] > b[vm.soldSortBy]) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.soldSortBy] > b[vm.soldSortBy]) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else if (this.soldSortBy == 'mls_address') {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.soldSortBy].replace('TH', '')) > parseFloat(b[vm.soldSortBy].replace('TH', ''))) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                                else if (a[vm.soldSortBy].includes('TH') && !b[vm.soldSortBy].includes('TH')) {
                                    return -1;
                                }
                                else if (!a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.soldSortBy].replace('TH', '')) > parseFloat(b[vm.soldSortBy].replace('TH', ''))) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                                else if (a[vm.soldSortBy].includes('TH') && !b[vm.soldSortBy].includes('TH')) {
                                    return 1;
                                }
                                else if (!a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                }
                return ob;
            },
            mlsActiveSort: function() {
                var ob = this.project.mls_last_date_active;
                var vm = this;
                if (this.project.mls_last_date_active && this.project.mls_last_date_active ) {
                    return ob;
                }
                if (this.activeSortBy) {
                    if (this.activeSortBy == 'mls_plan_name') {
                        if(vm.project.mls_last_date_active != null) {
                            if (this.orderAsc) {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.activeSortBy] > b[vm.activeSortBy]) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                            }
                            else {
                                ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                    if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                        return 1;
                                    }
                                    if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                        return -1;
                                    }
                                    if (a[vm.activeSortBy] > b[vm.activeSortBy]) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                });
                            }
                        }
                        
                    }
                    else if (this.activeSortBy == 'mls_address') {
                        if (this.orderAsc) {
                            if(vm.project.mls_last_date_active != null) {
                                ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.activeSortBy].replace('TH', '')) > parseFloat(b[vm.activeSortBy].replace('TH', ''))) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                                else if (a[vm.activeSortBy].includes('TH') && !b[vm.activeSortBy].includes('TH')) {
                                    return -1;
                                }
                                else if (!a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                            }
                            
                        }
                        else {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.activeSortBy].replace('TH', '')) > parseFloat(b[vm.activeSortBy].replace('TH', ''))) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                                else if (a[vm.activeSortBy].includes('TH') && !b[vm.activeSortBy].includes('TH')) {
                                    return 1;
                                }
                                else if (!a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                }
                return ob;
            }
        }
    }
</script>
<style scoped>
    .add-entry-btn {
        border: 1px solid;
        padding: 10px;
        position: absolute;
        cursor: pointer;
    }
</style>