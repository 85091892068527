<template>
    <div v-cloak>
        <PageLoader v-if="isLoading"></PageLoader>

        <div class="report-container column-report">
            <div class="mt-10">
            </div>

            <div class="row justify-content-between mr-3">
                <div></div>
                <div class="fields-toggle-wrapper" v-if="custom_header_field">
                    <div class="fields-toggle-block selected-fields">
                        <div class="row justify-content-between">
                            <div class="fields-toggle-title">Shown Data Point</div>
                            <font-awesome-icon
                                style="font-size: 20px;cursor: pointer;position: absolute;top: 12px;right: 12px;"
                                class="text-danger" @click="custom_header_field = false"
                                :icon="['fas', 'times-circle']" />
                        </div>
                        <draggable class="list-group row justify-content-center" :list="fields" group="field"
                            @change="fieldsOn" :sort="true">
                            <template v-for="(element, index) in fields">
                                <div :class="{ 'd-none': ['project', 'selected'].includes(element.label.toLocaleLowerCase()) }"
                                    class="list-group-item _cursor-pointer" :key="'element-a123' + element.label">
                                    {{ index }}. {{ element.label }}</div>
                            </template>

                        </draggable>
                    </div>
                    <div class="fields-toggle-bar"></div>
                    <div class="fields-toggle-block">
                        <div class="fields-toggle-title">Drag and Drop Data Points</div>
                        <draggable class="list-group row" :list="list2" group="field" :sort="true">
                            <div class="list-group-item _cursor-pointer" v-for="(element) in list2"
                                :key="'element-a234' + element.label">{{
                    element.label }}</div>
                        </draggable>
                    </div>
                </div>
            </div>
            <div class="row mb-3 justify-content-between">
                <div>
                    <h1 class="ml-3 text-vb-secondary">UNIT REPORT</h1>                
                </div> 
                <div class="d-flex justify-content-end">
                    <span class="text-white p-2 mx-2 align-self-md-center _price-backup"
                        style="background-color:#0b36dc">Verified</span>
                    <span class="text-white p-2 mx-2 align-self-md-center _price-backup"
                        style="background-color:#FF6F16">Semi-Verified</span>
                </div>
            </div>
            
            <div class="row">
                    <div class="col-md-4">
                        <treeselect class="border border-vb-secondary mb-10 pb-1 rounded" v-model="filter_rows.filterArray" :multiple="true" :options="filter_options" value-consists-of="LEAF_PRIORITY" 
                        placeholder="Choose filter"/>
                        </div>
                    <div class="col-md-8">
                        <div class="row align-items-center justify-content-end">
                            <div class="col-md-6">
                                <b-input-group>
                                    <b-form-input v-model="filter_rows.filterText" placeholder="Filter Project" style="height:50px;"></b-form-input>  
                                    <b-input-group-append>
                                    <b-button variant="outline-vb-secondary" @click="exportData();">
                                        <font-awesome-icon :icon="['fas', 'file-export']" />
                                    </b-button>
                                    <b-button variant="outline-vb-secondary" @click="generateReport();">
                                        <font-awesome-icon :icon="['fas', 'download']" />
                                    </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                    </div> 
            </div>
            
            <div class="row justify-content-end mb-3"> 
                <img class="print-none mx-2 mt-10 pr-3" src="/images/assets/fields.png"
                    @click="custom_header_field = !custom_header_field">
            </div>
            <RpdTable id="my-table" v-model="totalRows" :totalRows="totalRows"
                :current-page="currentPage" :per-page="perPage" v-on:popHistory="popHistory" v-on:getFilteredData="getFilteredData"
                v-on:selectAllRows="selectAllRows" v-on:popModal="showModal" v-on:updateTotalRows="updateTotalRows" v-on:refreshResults="refreshResults"
                :row_data="mlsData" :header_fields="fields" :marketRentalPsfToDouble="marketRentalPsfToDouble" 
                :filter_rows="filter_rows" :filtered_data="filtered_data">
            </RpdTable>
            <div style="margin-bottom: 8rem !important;" class="row jutify-content-space-between">
                <div class="col">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" prev-text="Prev"
                    first-text="First" last-text="Last" size="md">
                        <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                        <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                    </b-pagination>
                </div>
                <div class="px-3"> 
                    <b-form-select v-model="perPage" :options="rowOptions" class="mt-15"></b-form-select>
                </div>
            </div>
            <b-modal ref="floorplan_modal" header-bg-variant="vb-secondary" title="Floor Plan"
                v-for="(row, key) in mappedRow" :id="'mls-fp-' + row.field_mls_floor_plan_fid" hide-footer :key="key">
                <div v-if="row.floor_plan.fid" class="d-block text-center">
                    <p>
                        <a target="_blank" :href="row.floor_plan.link"><img class='floorplan-pdf'
                                :src="row.floor_plan.preview"></a>
                    </p>
                </div>
            </b-modal>
        </div>
        <b-modal id="mlspop" hide-footer @hidden="mlsHistory = null" title="Unit History">
            <SummaryPopUp :data="unitHistoryData" :mlsHistory="mlsHistory" />
        </b-modal>
        <!-- style="position: absolute; top:-20rem;" -->
        <ReportFooter id="erl-div" :userData="user_data" style="position: absolute; top:-20rem;"/>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { BACKEND_URL } from '../variables.js';
import { BTabs, BTab, BPopover, BPopoverDirective, BModal, BModalDirective, VBModal, BIconFileEarmarkXFill } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { cloneDeep } from 'lodash';
import { useRequestCounter } from '../store/RequestCounter'
import draggable from 'vuedraggable';
import RpdTable from './common/RpdTable.vue';
import { useUserStore } from '../store/UserStore';
import { useUserDataStore } from '../store/UserDataStore';
import { mapState } from 'pinia';
import { BBadge } from 'bootstrap-vue'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { BSpinner } from 'bootstrap-vue'
import ReportFooter from './common/ReportFooter.vue';
import { text } from '@fortawesome/fontawesome-svg-core';
import html2canvas from 'html2canvas'; 
import PageLoader from '../components/common/PageLoader.vue';
import SummaryPopUp from './common/SummaryPopUp.vue';
import axios from 'axios';  

export default {
    components: {
        DatePicker,
        'b-tabs': BTabs,
        'b-tab': BTab,
        'b-popover': BPopover,
        'b-modal': BModal,
        'b-badge': BBadge,
        'b-spinner': BSpinner,
        Treeselect,
        draggable,
        RpdTable,
        ReportFooter,
        PageLoader,
        SummaryPopUp
    },
    directives: {
        'b-popover': BPopoverDirective,
        'b-modal': VBModal,
    },
    data() {
        return {
            filtered_data:[],
            isLoading: false,
            rowOptions: [
                { value: 15, text: '15 rows' }, 
                { value: 25, text: '25 rows' }, 
                { value: 50, text: '50 rows' }, 
                { value: 100, text: '100 rows' }, 
            ],
            projectSelected: 0,
            arrayOfImages:[],
            loading:false,
            query: this.$route.query,
            unitHistoryData: {},
            mlsData: [],
            sortBy: 'sort-uname',
            orderAsc: true,
            mlsHistory: [], 
            filterSearch: null,
            filter_rows : {
                    filterText: '',
                    filterArray : []
                },
            filter_options : [
                {
                    id: 'type',
                    label: 'Type',
                    children: [
                        {id:'bed_Studio', label:'Studio'}, 
                        {id:'bed_Jr 1-bed', label:'Jr 1-bed'},
                        {id:'bed_1-bed', label:'1-bed'},
                        {id:'bed_2-bed', label:'2-bed'},
                        {id:'bed_3-bed', label:'3-bed'},
                        {id:'bed_4-bed', label:'4-bed'},
                    ]
                },
                {
                    id:'baths',
                    label:'Baths',
                    children:[    
                        {id:'bath_1', label:'Bath - 1'},
                        {id:'bath_1.5', label:'Bath - 1.5'},
                        {id:'bath_2', label:'Bath - 2'},
                        {id:'bath_2.5', label:'Bath - 2.5'},
                        {id:'bath_3', label:'Bath - 3'},
                        {id:'bath_3.5', label:'Bath - 3.5'},
                        {id:'bath_4', label:'Bath - 4'},
                        {id:'bath_4.5', label:'Bath - 4.5'},
                        {id:'bath_5', label:'Bath - 5'},
                    ]
                },
                {
                    id:'fpspecs',
                    label: 'FP Specs',
                    children:[
                        {id:'fp_Jr Bed', label:'Jr Bed'},
                        {id:'fp_Lock-Off', label:'Lock-Off'},
                        {id:'fp_Loft', label:'Loft'},
                        {id:'fp_PH', label:'PH'},
                        {id:'fp_Roof Deck', label:'Roof Deck'},
                        {id:'fp_Sub-PH', label:'Sub-PH'},
                    ]
                },
                {
                    id:'status',
                    label: 'Unit Status',
                    children:[
                        {id:'st_Active', label:'Active'},
                        {id:'st_Sold', label:'Sold'},
                    ]
                },
                {
                    id: 'cs',
                    label: 'Construction Status',
                    children: [ {
                        id: 'cs_Pre-Construction',
                        label: 'Pre-Construction',
                    }, {
                        id: 'cs_Excavation & Foundation',
                        label: 'Excavation & Foundation',
                    }, {
                        id: 'cs_Framing',
                        label: 'Framing',
                    }, {
                        id: 'cs_Interior Finishing',
                        label: 'Interior Finishing',
                    }, {
                        id: 'cs_Some Homes Complete',
                        label: 'Some Homes Complete',
                    }, {
                        id: 'cs_Complete',
                        label: 'Complete',
                    } ],
                },
                {
                id: 'pt',
                label: 'Project Type',
                children: [ {
                    id: 'pt_Apartment',
                    label: 'Apartment',
                }, {
                    id: 'pt_Mixed-Use Apartment',
                    label: 'Mixed-Use Apartment',
                }, {
                    id: 'pt_Townhome',
                    label: 'Townhome',
                }, {
                    id: 'pt_Stacked Townhome',
                    label: 'Stacked Townhome',
                } ],
                },
                {
                    id: 'pdt',
                    label: 'Product Type',
                    children: [ {
                        id: 'pdt_Concrete',
                        label: 'Concrete',
                    }, {
                        id: 'pdt_Wood Frame',
                        label: 'Wood Frame',
                    }, {
                        id: 'pdt_Townhome',
                        label: 'Townhome',
                    }, {
                        id: 'pdt_Stacked Townhome',
                        label: 'Stacked Townhome',
                    } ],
                },
            ],
            fields: [],
            list2: [
                { label: "Balcony", key: 'balcony',sortable: true },
                { label: "Balcony Area", key: 'balcony_area',sortable: true },
                { label: "View Orientation", key: 'view_orientation',sortable: true },
                { label: "Parking", key: 'parking' },
                { label: "Stalls", key: 'stalls' },
                { label: "Sold Date", key: 'sold_date',sortable: true },
                { label: "Sold Price", key: 'sold_price', 
                    formatter: (value) => {
                        return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                }, sortable: true },
                { label: "Construction Status", key: 'construction_status',sortable: true },
                { label: "Product Type", key: 'product_type',sortable: true }, 
                { label: "Neighbourhood", key: 'neighbour',sortable: true },
                { label: "Price Status", key: 'price_backup',sortable: true },
                { label: "Occupancy Date", key: 'occupancy_date',sortable: true }
            ],
            custom_header_field: false,
            allSelected: false,
            filteredReportDetail: [],
            selectedSummary: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            useUserDataStore: useUserDataStore()
        }
    },
    methods: {
        addToFilterOptions(){
            let status_index = this.filter_options.findIndex(elem => elem.id == 'status') 
            switch(this.$route.query["unittype[]"]){
                case '2952':
                    this.filter_options.splice(++status_index, 0,
                    {
                        id: 'listing_type',
                        label: 'Listing Type',
                        children: [
                            {id:'lt_Presale', label:'Presale'}, 
                            {id:'lt_Assignment', label:'Assignment'}, 
                            {id:'lt_Resale', label:'Resale'}
                        ]
                    },
                    {
                        id: 'project_status',
                        label: 'Project Status',
                        children: [
                            {id:'ps_Now Selling', label:'Now Selling'}, 
                            {id:'ps_Selling Soon', label:'Sold Out'}
                        ]
                    })
                break;
                case '2954':
                    this.filter_options.splice(++status_index, 0,
                    {
                        id: 'listing_type',
                        label: 'Listing Type',
                        children: [
                            {id:'lt_New Lease', label:'New Lease'}, 
                            {id:'lt_Re-Lease', label:'Re-Lease'}
                        ]
                    },
                    {
                        id: 'project_status',
                        label: 'Project Status',
                        children: [
                            {id:'ps_Now Leasing', label:'Now Leasing'}, 
                            {id:'ps_Leased Out', label:'Leased Out'}
                        ]
                    })
                break;
            }
 
        },
        getFilteredData(data) {
            this.filtered_data = data
        },
        exportData () {
            const vm = this  
            try { 
                const csvContent = this.convertToCsv(vm.allListingData)
                const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'exported-data.csv')
                link.click()
                URL.revokeObjectURL(url); // Clean up the URL after download    
                // Show success toast once report is generated
                vm.$bvToast.toast(`You can now view the downloaded file`, {
                    title: 'Success!',
                    variant: 'success',
                    autoHideDelay: 5000,
                });
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                
                vm.$bvToast.toast(`${'Something went wrong. \n Please inform us to fix this for you.'}`, {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000,
                });
            }    
        },
        convertToCsv(data){ 
            const vm = this  
            let headers = [{
                    key: 'name',
                    label:'Project',
                },  {
                    key: 'developer',
                    label:'Developer'
                },  {
                    key: 'address',
                    label:'Address'
                },  {
                    key: 'city',
                    label:'City'
                },  {
                    key: 'neighbour',
                    label:'Neighbour'
                }, {
                    key: 'project_type',
                    label:'Project Type'
                },]
            headers.push(...vm.fields.filter(header => !['checkbox','customData','customLocationData'].includes(header.key)))
         
            const rows = data.map(row => {
                return headers.map(header => {
                    const cell = row[header.key]; 

                    if(typeof cell == 'object')
                        return cell.fp_specs ? cell.type + '/' + cell.fp_specs : cell.type

                    return typeof cell === 'string' && cell.includes(',')
                    ? `"${cell.replace(/"/g, '""')}"` // Escape commas and quotes
                    : cell;
                    })
            })

            let forRowheaders = headers.map(item => item.label) 
            const headerRow = forRowheaders.join(",")
            const csvRows = [headerRow, ...rows.map(row => row.join(','))]
            return csvRows.join('\n')
        },
        async fetchUserData() {
            try {
                await this.useUserDataStore.getData();
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        },
        async generateReport () {
            const vm = this  
            const doc = new jsPDF('l','mm');

            vm.$bvToast.toast(`Download is in progress. Please wait for a moment while we prepare everything for you.`, {
                title: 'Downloading...',
                variant: 'info',
                autoHideDelay: 5000, 
            })
            try {
                let summaryReport = vm.mlsData 

            vm.loading = true
            let date = new Date();
            let formattedDate = vm.reportFormatDate(date)
            let logo = document.createElement('img');
            logo.src = 'src/assets/images/viber_image_2023-11-07_11-30-39-588.jpg';
            
            let rpdBackground = new Image()
                rpdBackground.src = '/images/assets/logo/RPD_logomark_black-1.png'
                rpdBackground.width = 200
                rpdBackground.height = 200
            
            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;

             
            doc.setFontSize(8)

            doc.text(formattedDate, 15, 10);
            doc.text("realpropertydata.ca", doc.internal.pageSize.width - 38, 10)
            doc.setLineWidth(0.5);
            doc.setDrawColor("#d9d9d9");
            doc.line(15, 20, doc.internal.pageSize.width - 15, 20);
            doc.setFontSize(14)
            doc.setFont("Helvetica", "bold")
            doc.text("Unit Summary Report", 15, 35) 
            doc.setLineWidth(0);
            doc.setDrawColor("#000");
            doc.line(15, 36, 67, 36);
            doc.setFontSize(12)
            let unitBreakDownHeaders = vm.fields.filter(x => x.key !== 'checkbox').map((item) => {return {...item}})
           
            const formattedSummary = summaryReport.map( (item) => {
                return {...item}
            } );
            
            const tableData = formattedSummary.map((item, index) => {
                return unitBreakDownHeaders.map(header => {
                    if (header.key == 'customData')
                        return `${item[header.key].projectName}` + '\n' + item[header.key].project 
                    
                    if (['psf','field_mls_list_price_value','net_price','buyer_incentives'].includes(header.key)) {
                        let formatPrice = Math.round(Number(item[header.key])).toLocaleString();
                        return item[header.key] ? `$${formatPrice}` : '-';
                    }

                    if (header.key == 'customLocationData')
                        return `${item[header.key].address}` + '\n' + item[header.key].municipality
 
                    if (header.key == 'net_psf'){
                        if((item.net_price && item.field_mls_unit_size_value)){
                            let netPrice = Math.round(Number(item.net_price) / Number(item.field_mls_unit_size_value)).toLocaleString()
                             return  netPrice ? `$${netPrice}` : '-'
                        }
                    }

                    if (header.key == 'custom_field_mls_beds_value')
                    return `${item[header.key].type}` + '\n' + item[header.key].fp_specs
 
                    
                    return item[header.key] ? item[header.key] : '-';
                })
            })
                          
            const boxes = [
                  { color: [11, 54, 220], text: "Verified" },  // #0b36dc
                  { color: [255, 111, 22], text: "Semi-Verified" }, // #FF6F16
                  // { color: [220, 53, 69], text: "Estimated" }   // #dc3545
              ];
            
            doc.setFontSize(10)  
            boxes.forEach((box, i) => {
                const x =  (pageWidth - 43) - (i * 26);  // Adjust X position for horizontal alignment
                
                doc.setFillColor(...box.color);
                doc.rect(x - (i * 8) , 30, 30, 8, 'F');  // Adjust box width to 50 for each
                doc.setTextColor(255, 255, 255);
                if(['verified','estimated'].includes(box.text.toLowerCase()))
                    doc.text(box.text, x + 8, 35);  // Adjust text position inside each box
                else
                    doc.text(box.text, x - (i * 5), 35)
            }); 
 
            doc.setFontSize(12)

                autoTable(doc, { 
                    rowPageBreak : 'avoid',
                    margin: {top: 25, right: 10, bottom: 30, left: 10},
                    startY: 50,  
                    headStyles: { fillColor: '#476E93' , valign:'center', cellPadding: {top: 3, bottom:5, left: 1, right:1}  }, 
                    columnStyles: { 
                            0: {halign: 'left', valign:'middle',cellPadding: {left: 3 } }
                    }, 
                    styles: { fontSize: 8, halign:'center', valign:'middle', cellPadding: 2}, 
                    head: [unitBreakDownHeaders.map(header => header.label)], body: tableData,
                    didParseCell: async(data) => {
                        const { doc, cell, column } = data 
                    const rowIndex = data.row.index;
                        if(data.cell.raw == 'Project'){
                            data.cell.styles.halign = 'left' 
                        }
                    this.enhanceWordBreak(doc, cell, column)
                    }, 
                    didDrawCell: async(data) => {

                    }
                
                }) 
                
                const promise2 = new Promise((resolve) => {
                    this.arrayOfImages.forEach((x, index) => {
                    doc.addPage("l", 'mm')
                    const centerX = pageWidth / 2 - x.image.width / 2;
                    const centerY = pageHeight / 2 - x.image.height / 2; 

                    doc.setTextColor("#000000");
                    doc.setFontSize(12)
                    doc.setFont("Helvetica", "bold")
                    doc.text(x.project.name, 15, 30);
                    
                    doc.setFontSize(10)
                    doc.setFont("Helvetica", "normal")
                    doc.text('Unit #: ', 15, 35);
                    doc.text(x.project.field_mls_address_value, 35, 35);
                    doc.text('Price: ', 15, 40);
                    doc.text(x.project.price, 35, 40);
                    doc.text('PSF: ', 15, 45);
                    doc.text(x.project.psf, 35, 45);
                    doc.text('Price Date: ', 15, 50);
                    doc.text(x.project.priceDate, 35, 50); 
                    doc.addImage(x.image.src, centerX, centerY, x.image.width, x.image.height) 
                
                    });

                    return resolve("Images loaded")
                })

                const promise3 = new Promise((resolve) => {
                    
                    const pageCount = doc.internal.getNumberOfPages();   

                    for(var i = 1; i <= pageCount ; i++){ 
                        doc.setPage(i)
                        
                        doc.setFontSize(8)
                        doc.text('Page ' + i + ' of ' + String(pageCount), pageWidth - 30, pageHeight - 10); 
                        
                        let centerX = pageWidth / 2 - rpdBackground.width / 2;
                        let centerY = pageHeight / 2 - rpdBackground.height / 2;  
                        
                        doc.addImage(rpdBackground, 'PNG', centerX, centerY, rpdBackground.width, rpdBackground.height);
                        doc.addImage(logo, 'PNG', 10, doc.internal.pageSize.height - 18,50,10);     
                    }
                    
                    return resolve("Page numbers added")
                })
                Promise.all([promise2, promise3]).then((values) => {
                    this.loading = false
                    
                    vm.$bvToast.toast(`You can now view the downloaded file`, {
                            title: 'Success!',
                            variant: 'success',
                            autoHideDelay: 5000,
                        });
                    window.open(doc.output('bloburl'), '_blank',"toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=500,width=1000,height=800");

                });
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                vm.$bvToast.toast(`${'Something went wrong. \n Please inform us to fix this for you.'}`, {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000,
                });
            }
            
        },
        enhanceWordBreak(doc, cell, column) {
            if (cell === undefined) {
            return;
            }

            const hasCustomWidth = (typeof cell.styles.cellWidth === 'number');

            if (hasCustomWidth || cell.raw == null || cell.colSpan > 1) {
            return
            }

            let text;

            if (cell.raw instanceof Node) {
            text = cell.raw.innerText;
            } else {
            if (typeof cell.raw == 'object') {
                // not implemented yet
                // when a cell contains other cells (colSpan)
                return;
            } else {
                text = '' + cell.raw;
            }
            }

            // split cell string by spaces
            const words = text.split(/\s+/);

            // calculate longest word width
            const maxWordUnitWidth = words.map(s => Math.floor(doc.getStringUnitWidth(s) * 100) / 100).reduce((a, b) => Math.max(a, b), 0);
            const maxWordWidth = maxWordUnitWidth * (cell.styles.fontSize / doc.internal.scaleFactor)

            const minWidth = cell.padding('horizontal') + maxWordWidth;

            // update minWidth for cell & column

            if (minWidth > cell.minWidth) {
            cell.minWidth = minWidth;
            }

            if (cell.minWidth > cell.wrappedWidth) {
            cell.wrappedWidth = cell.minWidth;
            }

            if (cell.minWidth > column.minWidth) {
            column.minWidth = cell.minWidth;
            }

            if (column.minWidth > column.wrappedWidth) {
            column.wrappedWidth = column.minWidth;
            }
        },
        async getImageRatio(imgUrl) { 
                    
                const max = {height:190,width:133} 

                return new Promise(async (resolve) => {
                    let image = new Image(); 
                    image.src=imgUrl
                    
                    image.onload = async function () { 
                    const ratio=image.height/image.width;
                
                    if(image.height>max.height||image.width>max.width){
                        if(image.height>image.width){
                            image.height=max.height;
                            image.width=image.height*(1/ratio);
                        // Making reciprocal of ratio because ration of height as width is no valid here needs width as height
                        }else if(image.width > image.height){
                            image.width=max.width;
                            image.height=image.width*ratio;
                        // Ratio is valid here 
                        } else {
                            image.width=max.width;
                            image.height=image.width*ratio
                        }
                        }    
                        resolve({
                            status: 'success',
                            image: image
                        })
                    }
                })
                
                    
        },
        reportFormatDate(dateVal) {
                let vm = this
                var newDate = new Date(dateVal);

                var sMonth = vm.padValue(newDate.getMonth() + 1);
                var sDay = vm.padValue(newDate.getDate());
                var sYear = newDate.getFullYear();
                var sHour = newDate.getHours();
                var sMinute = vm.padValue(newDate.getMinutes());
                var sAMPM = "AM";

                var iHourCheck = parseInt(sHour);

                if (iHourCheck > 12) {
                    sAMPM = "PM";
                    sHour = iHourCheck - 12;
                }
                else if (iHourCheck === 0) {
                    sHour = "12";
                }

                sHour = vm.padValue(sHour);

                return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
        },
            
        padValue(value) {
                return (value < 10) ? "0" + value : value;
        },
        formatDate(date) {
            if (!date || date.length <= 0)
                return '-';

            let mlsDate = new Date(date * 1000);

            return mlsDate.toISOString().slice(0, 10).replace(/-/g, '-');
        },
        setRpdHeaderFields() {
            const vm = this
            
            switch(vm.current_user.role){ 
                default: 
                    vm.fields = [
                        { label: 'Selected', key: 'checkbox', thClass: 'w-small' },
                        { label: "Project & Developer", key: 'customData', thClass: 'text-left pl-3 w-25',tdClass: 'border-right', sortable: true },
                        { label: "Location" , key: 'customLocationData',sortable: true },
                        { label: "Unit #", key: 'field_mls_address_value', sortable: true },
                        { label: "Plan Name", key: 'field_mls_plan_name_value', sortable: true },
                        { label: "Type", key: 'custom_field_mls_beds_value',sortable: true },
                        { label: "Baths", key: 'field_mls_baths_value',sortable: true },
                        {
                        label: "Unit Size", key: 'field_mls_unit_size_value', formatter: (value) => {
                            return Number(value) ? Math.round(value).toLocaleString() : '-'
                        },sortable: true
                        },
                        { label: "List Price", key: 'field_mls_list_price_value', tdClass:'_w-350', thClass:'_w-350',sortable: true },
                        { label: "$PSF", key: 'psf', sortable: true },
                        { label: "Incentives", key: 'buyer_incentives', sortable: true },
                        { label: "Net Price" , key: 'net_price',sortable: true },
                        { label: "Net $PSF" , key: 'custom_net_psf', formatter: (value, key, item) => {
                                if(this.$route.query["unittype[]"] == '2954')
                                    return  Number(Number(item.net_price / item.field_mls_unit_size_value)).toFixed(2)
                                
                                return Math.round(Number(item.net_price / item.field_mls_unit_size_value))

                            },sortable: true, sortByFormatted: true },
                        { label: "Price Date", key: 'price_date', sortable: true },
                        { label: "Unit Status", key: 'status_name_name',sortable: true },
                        { label: "Project Status", key: 'status',sortable: true },
                        { label: "Age", key: 'custom_age', sortable: true },
                        { label: "Listing Type", key: 'custom_listing_type',sortable: true },
                        { label: "Unit Status", key: 'status_name_name',sortable: true },
                        { label: "Project Status", key: 'status',sortable: true },
                        { label: "Age", key: 'custom_age', sortable: true },
                        { label: "Listing Type", key: 'custom_listing_type',sortable: true },
                    ]
                break;

            }
            
        },
        selectAllRows({ selectAll, tableRef }) {
            this.filtered_data.forEach((filteredItem, index) => {  
                    this.mlsData.forEach(
                        (item) => {
                            if(item.entity_id === filteredItem.entity_id) {
                                item.rowSelected = selectAll
                            }
                        }
                    ); 
                })
        },
        updateTotalRows(value) {
            this.totalRows = value
        },
        roundToWholeNumber(value) {
            return Number(value) ? '$' + Math.round(value).toLocaleString() : '-'
        },
        showModal(data) {
            this.$root.$emit('bv::show::modal', 'mls-fp-' + data)
        },
        setColor(data) {
            let color = ""
            if (data) {
                if (data == 'Verified')
                    color = "#0b36dc"

                if (data == "Educated Estimated")
                    color = "#dc3545"

                if (data == "Semi-Verified")
                    color = "#FF6F16"

            }
            return color
        },
        refreshResults() { 
            const vm = this
            if (this.mlsData.filter(row => row.rowSelected === true).length > 0)
                this.mlsData = this.mlsData.filter(row => row.rowSelected === true)

            vm.generateArrayOfImages()    
        },
        generateArrayOfImages(){
            const vm = this
            vm.arrayOfImages = new Array() 
            let promises = []
            vm.mlsData.forEach(x => { 
                promises.push(vm.getImageRatio(x.floor_plan.preview))
                
                vm.getImageRatio(x.floor_plan.preview).then(resolve => {
                    if(resolve.status == "success"){ 
                        
                        vm.arrayOfImages.push(
                            {   project: {
                                name: x.name,
                                field_mls_address_value: x.field_mls_address_value,
                                price: this.roundToWholeNumber(x.field_mls_list_price_value).toString() ,
                                psf: x.psf > 0 ? this.roundToWholeNumber(x.psf).toString() : '-',
                                priceDate: x.price_date
                                },
                                image: resolve.image
                            }
                        )
                    }
                })
            }) 

        },
        selectAll: function () {
            if (!this.allSelected) {
                for (let report in this.mlsSort) {
                    this.selectedSummary.push(this.mlsSort[report].entity_id);
                }
            }
            else {
                this.selectedSummary = [];
            }
        },
        fieldsOn: function () {
            // this.reportFields = [];
            // if (this.fields.length >= 10) {
            //     let poped = this.fields.pop();
            //     this.list2.push(poped);
            // } else {
            //     this.fields.forEach((val, key) => {
            //         this.reportFields.push(val.field);
            //     });
            // }
        },
        project_sort(column) {
            if (this.sortBy == column) {
                this.orderAsc = !this.orderAsc;
            }
            this.sortBy = column;
        },
        utcLink(utc) {
            let link = '/type?project=' + utc.city_entity_id + '&fid=' + utc.field_project_type_collection_value + '&sid=' + utc.field_utc_status_tid + '&uid=' + utc.field_unit_type_value;
            return link;
        },
        getAge(row){
            let d2 = new Date()
            let d1 = new Date(row.occupancy_date)
            // Calculate the total difference in months
            let totalMonths = (d2.getFullYear() - d1.getFullYear()) * 12 + (d2.getMonth() - d1.getMonth());

            // If the day of the second date is before the first, adjust the month count
            if (d2.getDate() < d1.getDate()) {
                totalMonths -= 1;
            }

            // If the difference is 0-12 months, round up to 1 year
            if (totalMonths <= 12) {
                return 1;
            }

            // Otherwise, round to the nearest whole number in years
            return Math.round(totalMonths / 12); 
        },
        async setListingType(row){    
                let res = '';
                let priceDate = new Date(row.price_date)
                let soldDate = new Date(row.sold_date)
                let constructionCompletionDate = new Date(row.occupancy_date)
                let soldOutDate = new Date(row.sold_out_date) 
                try { 
                let resHistory = await axios.get(BACKEND_URL + `api/get_mls_history?eid=${row.entity_id}`)

                    if(resHistory.data) { 
                        let hasSoldStatusBeforeEntry = resHistory.data.some((val, index) => val['mls_status'] == 'Sold' && index < resHistory.data - 1 )
                        res = 'Presale'

                        if(hasSoldStatusBeforeEntry){ 
                            if(([priceDate, soldDate].some(date => date < constructionCompletionDate)))
                                res = 'Assignment'

                            if([priceDate, soldDate].some(date => date > constructionCompletionDate)) 
                                res = 'Resale'
                            
                        } else {
                            if([priceDate, soldDate].some(date => date > soldOutDate && date < constructionCompletionDate))
                                res = 'Assignment'
                            
                            if([priceDate, soldDate].some(date => date > soldOutDate  && date > constructionCompletionDate))
                                res = 'Resale'
                        } 

                        if(['now leasing' ,'leased out'].includes(row.status.toLowerCase())){
                            switch(res) {
                                case 'Presale' :
                                    res = 'New Lease'
                                break;

                                case 'Resale':
                                    res = 'Re-Lease'
                                break;
                            }
                        }
                    }

                } catch (error) {
                    console.log(error)
                }    
                return res 
            },
        getMLSDetail(params) {
            const endPoint = `api/get_mls_list?${params}`;
            this.isLoading = true
            useRequestCounter().incrementRequestCounter();
            $.get({
                url: BACKEND_URL + endPoint,
            }, (data, status) => {
                if (status !== 'success') return;
 
                let mappedData = data.filter(mlsRow => mlsRow.price_backup != 'Educated Estimated' && mlsRow.price_date.length > 0).map(mlsRow => {
                    return {
                        ...mlsRow,
                        psfToString: this.roundToWholeNumber(mlsRow.psf).toString(),
                        customData: {
                            project: mlsRow.developer,
                            projectName: mlsRow.name,
                            href_link: this.utcLink(mlsRow)
                        }, 
                        customLocationData:{
                            address: mlsRow.address,
                            municipality: mlsRow.city,
                            neighbour: mlsRow.neighbour
                        },
                        custom_field_mls_beds_value: {
                            type : mlsRow.field_mls_beds_value == 's' ? 'Studio' : mlsRow.field_mls_beds_value == 'jr' ? 'Jr 1' + '-bed' : mlsRow.field_mls_beds_value + '-bed', 
                            fp_specs: mlsRow.fp_specs.toLocaleLowerCase() != 'none' ? mlsRow.fp_specs : ''
                        }, 
                        custom_age: this.getAge(mlsRow),
                        rowSelected: false
                    };
                })

                // Fetch custom_listing_type asynchronously for each row
                let listingTypePromises = mappedData.map(row => {
                    return this.setListingType(row).then(customListingType => {
                        row.custom_listing_type = customListingType;
                        return row;
                    });
                });

                Promise.all(listingTypePromises).then(updatedData => {
                        this.mlsData = updatedData; // Update Vue data property
                        this.isLoading = false
                        useRequestCounter().incrementCompletedRequest();
                }).catch(error => console.error("Error fetching listing types:", error)); 

                this.generateArrayOfImages()
            });
        },
        popHistory(data) {
            const vm = this
            vm.unitHistoryData = data;
            let entry = 'eid=' + data.entity_id;
            const endPoint = `api/get_mls_history?${entry}`;
            $.get({
                url: BACKEND_URL + endPoint,
            }, (data, status) => {
                if (status !== 'success') return;
                this.mlsHistory = data;
            });
        }
    },
    computed: {
        ...mapState(useUserStore, ['current_user']),
        ...mapState(useUserDataStore, ['user_data']),
        allListingData: function () {
            const vm = this
            let allData = new Array(...vm.mlsData)
            return allData.map((row, index) => {
                let clonedRow = { ...row , 
                customData: '[' +row.customData.projectName + '] ' + row.customData.project,
                customLocationData: '[' +row.customLocationData.address + '] ' + row.customLocationData.municipality,};

                Object.keys(clonedRow).forEach((key) => {
                    clonedRow[key] = !['-', 0, null, NaN, '0'].includes(clonedRow[key]) ? clonedRow[key] : '-';
                }); 
                if (clonedRow['field_mls_beds_value'] && !vm.isAdmin) {
                    let bed = '';
                    let fpSpecs = '';
                    bed = `${clonedRow['field_mls_beds_value']}-bed`;

                    if (!['None', ''].includes(clonedRow['fp_specs']))
                        fpSpecs = `${clonedRow['fp_specs']}`;

                    if (clonedRow['field_mls_beds_value'] == 's')
                        bed = 'Studio'; 

                    clonedRow['test_sample'] = fpSpecs ? bed +' / '+ fpSpecs : bed;
                } 

                return clonedRow;
            });
        },
        marketRentalPsfToDouble: function() { 
            const vm = this;
                if(vm.$router.currentRoute.fullPath.includes('mls-list') && vm.$route.query['unittype[]'] == "2954")
                    return true

                    return false
        },
        isAdmin: function () {
            return this.current_user.role == 'admin'
        },
        filteredFloorPlan: function(){
            return this.mlsData.filter(x => x.floor_plan.length != 0)
        },
        mappedRow: {
            get: function () {
                return this.mlsData.map(mlsRow => {
                    return {
                        ...mlsRow,
                        customData: {
                            project: mlsRow.developer,
                            projectName: mlsRow.name,
                            href_link: this.utcLink(mlsRow)
                        },
                        rowSelected: false
                    };
                })
            },
            set: function (newVal) {
                return newVal
            }
        },
        mlsSort: {
            get: function () {
                let alphSorting = {
                    'sort-uname': 'name',
                    'location': 'address',
                    'sort-status': 'status_name_name',
                    'sort-unit': 'field_mls_address_value',
                    'sort-beds': 'field_mls_beds_value',
                    'sort-baths': 'field_mls_baths_value',
                    'sort-usize': 'field_mls_unit_size_value',
                    'sort-price': 'price',
                    'sort-psf': 'psf',
                    'sort-date': 'price_date'
                }
                var vm = this;
                var ob = this.mlsData;
                Object.keys(alphSorting).forEach(function (k) {
                    if (k == vm.sortBy) {
                        if (vm.orderAsc) {
                            ob = vm.mlsData.sort((a, b) => (a[alphSorting[k]] > b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] > a[alphSorting[k]]) ? -1 : 0));
                        }
                        else {
                            ob = vm.mlsData.sort((a, b) => (a[alphSorting[k]] < b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] < a[alphSorting[k]]) ? -1 : 0));
                        }
                    }
                });
                return ob;
            },
            set: function (newValue) {
                this.mlsData = newValue
            }

        }
    },
    watch: {
        mlsData: function (newVal) {
            this.totalRows = newVal.length
        },
    },
    mounted: function () {

        let param = ''
        var vm = this;
        vm.fetchUserData();
        let rangList = [
            'mlsbedsmax',
            'mlsbedsmin',
            'mlsbathsmax',
            'mlsbathsmin',
            'mlspricemax',
            'mlspricemin',
            'mlspricemax',
            'mlsunitmax',
            'mlsunitmin'
        ];
        vm.setRpdHeaderFields()
        rangList.forEach((d) => {
            if (vm.query[d]) {
                param += d + '=' + vm.query[d] + '&';
            }
        });
        let dateList = [
            'listmlsdate',
            'soldmlsdate'
        ];
        dateList.forEach((d) => {
            if ($.isArray(vm.query[d + '[]'])) {
                vm.query[d + '[]'].forEach((a) => {
                    param += d + '[]=' + a + '&';
                });
            }
            else if (vm.query[d + '[]']) {
                param += d + '[]=' + vm.query[d + '[]'] + '&';
            }
        });

        if ($.isArray(vm.query['gut[]'])) {
            vm.query['gut[]'].forEach((a) => {
                param += 'gut[]=' + a + '&';
            });
        }
        else {
            if (vm.query['gut[]']) {
                param += 'gut[]=' + vm.query['gut[]'] + '&';
            }
        }

        if ($.isArray(vm.query['fp[]'])) {
            vm.query['fp[]'].forEach((a) => {
                param += 'fp[]=' + a + '&';
            });
        }
        else {
            if (vm.query['fp[]']) {
                param += 'fp[]=' + vm.query['fp[]'] + '&';
            }
        }

        if ($.isArray(vm.query['city[]'])) {
            vm.query['city[]'].forEach((a) => {
                param += 'city[]=' + a + '&';
            });
        }
        else {
            if (vm.query['city[]']) {
                param += 'city[]=' + vm.query['city[]'] + '&';
            }
        }


        if ($.isArray(vm.query['unittype[]'])) {
            vm.query['unittype[]'].forEach((a) => {
                param += 'unittype[]=' + a + '&';
            });
        }
        else {
            if (vm.query['unittype[]']) {
                param += 'unittype[]=' + vm.query['unittype[]'] + '&';
            }
        }

        if ($.isArray(vm.query['type[]'])) {
            vm.query['type[]'].forEach((a) => {
                param += 'type[]=' + a + '&';
            });
        }
        else {
            if (vm.query['type[]']) {
                param += 'type[]=' + vm.query['type[]'] + '&';
            }
        }

        if ($.isArray(vm.query['status[]'])) {
            vm.query['status[]'].forEach((a) => {
                param += 'status[]=' + a + '&';
            });
        }
        else {
            if (vm.query['status[]']) {
                param += 'status[]=' + vm.query['status[]'] + '&';
            }
        }

        if ($.isArray(vm.query['mlsdate[]'])) {
            vm.query['mlsdate[]'].forEach((a) => {
                param += 'mlsdate[]=' + a + '&';
            });
        }
        else {
            if (vm.query['mlsdate[]']) {
                param += 'mlsdate[]=' + vm.query['mlsdate[]'] + '&';
            }
        } 
        
        this.addToFilterOptions()

        this.getMLSDetail(param)

        this.filteredReportDetail = this.mlsData   
    }
}
</script> 
<style scoped>
.vue-treeselect__multi-value-item {
    background: #426586 !important;
    border-radius: 7px;
    color: #fff !important;
    font-size: 0.8rem;
    font-weight: bold;
}
th>div {
    position: relative;
}

th>div>span {
    white-space: nowrap;
}

th>div>div {
    position: absolute;
}

fa {
    font-size: 18px;
}

th ul {
    border: 1px solid #eee;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    overflow: hidden;
}

.visible {
    max-height: 100px;
    transition: max-height .3s;
}

.invisible {
    max-height: 0px;
    transition: max-height .3s;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: transform .3s;
}

.rotate-360 {
    transform: rotate(360deg);
    transition: transform .3s;
}

th ul li {
    background: #fff;
    padding: 8px 16px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    text-transform: none;
    font-size: 12px;
    white-space: nowrap;
    height: 32px;
    text-align: left;
    cursor: pointer;
}

th ul li:hover {
    background: #4db7fe;
    color: #fff;
}

th ul li:last-child {
    border-bottom: none;
}

.selected {
    background: #4db7fe;
    color: #fff;
}

a {
    color: #007bff;
}

.status-btn {
    background: #EEF4FB;
    border-radius: 25px;
    font-size: 20px;
    line-height: 18px;
    color: #415A8F;
    padding: 10px 30px;
    margin-right: 30px;
    display: inline-block;
}

.status-row {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: left;
}

.status-line {
    line-height: 5;
}

.summary-table-header-row {
    cursor: pointer;
}

.list-table thead.summary-table-header tr.summary-table-header-row th {
    top: 0;
    padding: 2.5rem 1.5rem;
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2rem;
    z-index: 98;
    vertical-align: middle;
    position: sticky;
    background: #476e93;
    color: #fff;
    line-height: 24px;
}

.table.list-table tbody tr td {
    font-family: Poppins;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 30px;
    padding: 1.5rem;
    vertical-align: middle;
    border-bottom: 2px solid #545454;
    border-top: none;
}

.print-none {
    cursor: pointer;
    height: fit-content;
    max-width: 50px;
}

.fields-toggle-title {
    color: #13293E;
    font-size: 1.4rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.fields-toggle-wrapper {
    zoom: 1.4;
    border: 3px #13293E solid;
    position: absolute;
    right: 70px;
    width: 450px;
    z-index: 999;
    background: #F8F7F7;
    margin-top: 56px;
    padding: 30px;
}

.selected-fields .list-group-item {
    background: #FC5868;
    color: #fff;
}

.fields-toggle-bar {
    border-bottom: 3px solid #13293E;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.list-group-item {
    background: #D9D9D9;
    color: #13293E;
    font-size: 0.9rem;
    border: none;
}

.list-group {
    flex-direction: inherit;
}

.list-group-item {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

input._red-checkbox {
    accent-color: #FF033E;
    height: 30px !important;
    width: 30px !important;
}

h1 {
    font-size: 3rem;
    font-family: Poppins;
    font-weight: 600;
}

/* MOBILE STYLING */
@media only screen and (max-width: 976px) {

    .print-none {
        width: 100%;
        max-width: 35px;
        height: 40px;
    }
}

.modal-body {
    justify-content: center;
}

.mls-floorplan {
    color: #4d7ce1;
    text-decoration: underline;
}

.close {
    color: white !important;
}

/* END OF MOBILE STYLING */
</style>