<template>
    <div v-cloak>
        <div class="report-container column-report">
            <img v-if="is_loading" style="width: 100%;" src="/images/spinner.gif">
            <div class="status-row" v-if="!is_loading">
            </div>
            <table v-if="!is_loading" class="table list-table">
                <thead class="summary-table-header">
                    <tr class="summary-table-header-row navy-header">
                        <th @click="project_sort('sort-pid')">LTSA PID</th>
                        <th @click="project_sort('sort-address')">LTSA ADDRESS</th>
                        <th>LTSA PLAN (SURVEY)</th>
                        <th @click="project_sort('sort-status')">LTSA PARCEL STATUS</th>
                        <th @click="project_sort('sort-price')">SALE PRICE</th>
                        <th @click="project_sort('sort-owner')">REGISTRATION OWNER</th>
                        <th @click="project_sort('sort-date')">SALE DATE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, key) in landSort" v-bind:key="key" @click="presaleLink(row)" style="cursor: pointer;">
                        <td>
                            {{row.pid}}
                        </td>
                        <td>
                            {{row.address}}
                        </td>
                        <td>
                            {{row.plan_survey}}
                        </td>
                        <td>
                            {{row.status}}
                        </td>
                        <td>
                            ${{row.price | numFormat}}
                        </td>
                        <td>
                            {{row.owner}}
                        </td>
                        <td>
                            {{row.sales_date}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    import 'vue2-datepicker/index.css';
    import {BACKEND_URL} from '../variables.js';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { cloneDeep } from 'lodash';

    export default {
        components: { 
        },
        directives: {
        },
        data() {
            return {
                is_loading: true,
                query: this.$route.query,
                landData: [],
                sortBy: 'sort-uname',
                orderAsc: true,
                mlsHistory: []
            }
        },
        methods: {
            project_sort(column) {
                if (this.sortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.sortBy = column;
            },
            presaleLink(param) {
                let link = '';
                if (param.bundle == 'presale_project') {
                    link = '/project?project=' + param.nid + '&land=presale'
                }
                else if (param.bundle == 'field_project_type_collection') {
                    link = '/project?project=' + param.nid + '&land=' + param.fid
                }
                else {
                    link = '/project?project=' + param.nid + '&land=' + param.fid_sec
                }
                this.$router.push({ path: link});
                this.$router.go();
            },
            getLandDetail (params) {
                const endPoint = `api/get_land_sale_list?${params}`;
                this.is_loading = true;
                $.get({
                    url: BACKEND_URL + endPoint,
                }, (data, status) => {
                    this.is_loading = false;
                    if (status !== 'success') return;
                    this.landData = cloneDeep(data);
                });
            }
        },
        computed: {
            landSort: function () {
                let alphSorting = {
                    'sort-address' : 'address',
                    'sort-pid' : 'pid',
                    'sort-status' : 'status',
                    'sort-owner' : 'owner'
                }
                var vm = this;
                var ob = this.landData;
                Object.keys(alphSorting).forEach(function (k) {
                    if (k == vm.sortBy) {
                        if (vm.orderAsc) {
                            ob = vm.landData.sort((a,b) => 
                                {
                                    if (a[alphSorting[k]] == '' || a[alphSorting[k]] == null) {
                                        if (b[alphSorting[k]]) {
                                            return -1;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }
                                    if (b[alphSorting[k]] == '' || b[alphSorting[k]] == null) {
                                        if (a[alphSorting[k]]) {
                                            return 1;
                                        }
                                        else {
                                            return -1;
                                        }
                                    }
                                    if (a[alphSorting[k]] > b[alphSorting[k]]) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                            );
                            // ob = vm.landData.sort((a,b) => (a[alphSorting[k]] > b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] > a[alphSorting[k]]) ? -1 : 0));
                        }
                        else {
                            ob = vm.landData.sort((a,b) => 
                                {
                                    if (a[alphSorting[k]] == '' || a[alphSorting[k]] == null) {
                                        if (b[alphSorting[k]]) {
                                            return 1;
                                        }
                                        else {
                                            return -1;
                                        }
                                    }
                                    if (b[alphSorting[k]] == '' || b[alphSorting[k]] == null) {
                                        if (a[alphSorting[k]]) {
                                            return -1;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }
                                    if (a[alphSorting[k]] < b[alphSorting[k]]) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                            );
                            // ob = vm.landData.sort((a,b) => (a[alphSorting[k]] < b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] < a[alphSorting[k]]) ? -1 : 0));
                        }
                    }
                });

                let digitSorting = {
                    'sort-price' : 'price'
                }
                Object.keys(digitSorting).forEach(function (k) {
                    if (k == vm.sortBy) {
                        if (vm.orderAsc) {
                            ob = vm.landData.sort((a,b) => 
                                {
                                    if (a[digitSorting[k]] == '' || a[digitSorting[k]] == null) {
                                        if (b[digitSorting[k]] > 0) {
                                            return -1;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }
                                    if (b[digitSorting[k]] == '' || b[digitSorting[k]] == null) {
                                        if (a[digitSorting[k]] > 0) {
                                            return 1;
                                        }
                                        else {
                                            return -1;
                                        }
                                    }
                                    if (parseInt(a[digitSorting[k]]) > parseInt(b[digitSorting[k]])) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                            );
                        }
                        else {
                            ob = vm.landData.sort((a,b) => 
                                {
                                    if (a[digitSorting[k]] == '' || a[digitSorting[k]] == null) {
                                        if (b[digitSorting[k]] > 0) {
                                            return 1;
                                        }
                                        else {
                                            return -1;
                                        }
                                    }
                                    if (b[digitSorting[k]] == '' || b[digitSorting[k]] == null) {
                                        if (a[digitSorting[k]] > 0) {
                                            return -1;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }
                                    if (parseInt(a[digitSorting[k]]) > parseInt(b[digitSorting[k]])) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                            );
                        }
                    }
                });

                let dateSorting = {
                    'sort-date' : 'sales_date',
                }
                
                Object.keys(dateSorting).forEach(function (k) {
                    if (k == vm.sortBy) {
                        if (vm.orderAsc) {
                            ob = vm.landData.sort((a,b) => 
                                {
                                    if (a[dateSorting[k]] == '') {
                                        return 1;
                                    }
                                    if (b[dateSorting[k]] == '') {
                                        return -1;
                                    }
                                    if (Date.parse(a[dateSorting[k]]) > Date.parse(b[dateSorting[k]])) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                            );
                        }
                        else {
                            ob = vm.landData.sort((a,b) => 
                                {
                                    if (a[dateSorting[k]] == '') {
                                        return -1;
                                    }
                                    if (b[dateSorting[k]] == '') {
                                        return 1;
                                    }
                                    if (Date.parse(a[dateSorting[k]]) > Date.parse(b[dateSorting[k]])) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                            );
                        }
                    }
                });
                return ob;
            }
        },
        mounted: function() {
            let param = ''
            var vm = this;
            let rangList = [
                'plansearch',
                'regsearch',
                'pid',
                'salepricemin',
                'salepricemax',
                'sizemin',
                'sizemax',
            ];

            rangList.forEach((d) => {
                if (vm.query[d]) {
                    param += d+'=' + vm.query[d] + '&';
                }
            });
            let dateList = [
                'regdate'
            ];
            dateList.forEach((d) => {
                if ($.isArray(vm.query[d+'[]'])) {
                    vm.query[d+'[]'].forEach((a) => {
                        param += d+'[]=' + a + '&';
                    });
                }
                else if (vm.query[d+'[]']) {
                    param += d+'[]=' + vm.query[d+'[]'] + '&';
                }
            });

            if ($.isArray(vm.query['status[]'])) {
                vm.query['status[]'].forEach((a) => {
                    param += 'status[]=' + a + '&';
                });
            }
            else {
                if (vm.query['status[]']) {
                    param += 'status[]=' + vm.query['status[]'] + '&';
                }
            }

            if ($.isArray(vm.query['type[]'])) {
                vm.query['type[]'].forEach((a) => {
                    param += 'type[]=' + a + '&';
                });
            }
            else {
                if (vm.query['type[]']) {
                    param += 'type[]=' + vm.query['type[]'] + '&';
                }
            }

            if ($.isArray(vm.query['city[]'])) {
                vm.query['city[]'].forEach((a) => {
                    param += 'city[]=' + a + '&';
                });
            }
            else {
                if (vm.query['city[]']) {
                    param += 'city[]=' + vm.query['city[]'] + '&';
                }
            }

            this.getLandDetail(param)
        }
    }
</script>
<style scoped>
th>div {
    position: relative;
}
th>div>span {
    white-space: nowrap;
}
th>div>div {
    position: absolute;
}
fa {
    font-size: 18px;
}
th ul {
    border: 1px solid #eee;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    overflow: hidden;
}
.visible {
    max-height: 100px;
    transition: max-height .3s;
}
.invisible {
    max-height: 0px;
    transition: max-height .3s;
}
.rotate-180 {
    transform: rotate(180deg);
    transition: transform .3s;
}
.rotate-360 {
    transform: rotate(360deg);
    transition: transform .3s;
}
th ul li {
    background: #fff;
    padding: 8px 16px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    text-transform: none;
    font-size: 12px;
    white-space: nowrap;
    height: 32px;
    text-align: left;
    cursor: pointer;
}
th ul li:hover {
    background: #4db7fe;
    color: #fff;
}
th ul li:last-child {
    border-bottom: none;
}
.selected {
    background: #4db7fe;
    color: #fff;
}
a {
    color: #007bff;
}
.report-container {
    padding: 30px 50px;
}
.status-btn {
    background: #EEF4FB;
    border-radius: 25px;
    font-size: 20px;
    line-height: 18px;
    color: #415A8F;
    padding: 10px 30px;
    margin-right: 30px;
    display: inline-block;
}
.status-row {
    margin-top: 20px; 
    margin-bottom: 50px;
    text-align: left;
}
.status-line {
    line-height: 5;
}
.summary-table-header-row {
    cursor: pointer;
}
</style>