<template>
    <section>
        <div class="container">
            <h1>PLANNING UPDATES</h1>
            <div class="filter-wrapper permits text-left">
                <div class="row">
                    <div class="col-lg-3 pt-20 type-group">
                        <div class="type-group-inner" :class="{'closed':closed}">
                            <h3 class="filter-label">Project Type</h3>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Apartment')" @click="toggleBool('Apartment')" id="apartmentCheckbox">
                                <label for="apartmentCheckbox">Apartment</label>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Mixed-Use Apartment')" @click="toggleBool('Mixed-Use Apartment')" id="mixedCheckbox">
                                <label for="mixedCheckbox">Mixed-Use</label>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Townhome')" @click="toggleBool('Townhome')" id="townhomeCheckbox">
                                <label for="townhomeCheckbox">Townhome</label>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Stacked Townhome')" @click="toggleBool('Stacked Townhome')" id="stackedCheckbox">
                                <label for="stackedCheckbox">Stacked</label>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Commercial')" @click="toggleBool('Commercial')" id="commercialCheckbox">
                                <label for="commercialCheckbox">Commercial</label>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Industrial')" @click="toggleBool('Industrial')" id="industrialCheckbox">
                                <label for="industrialCheckbox">Industrial</label>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Podium')" @click="toggleBool('Podium')" id="podiumCheckbox">
                                <label for="podiumCheckbox">Podium</label>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" :selected="typeSelected.includes('Rowhome')" @click="toggleBool('Rowhome')" id="rowhomeCheckbox">
                                <label for="rowhomeCheckbox">Rowhome</label>
                            </div>
                        </div>
                        <div v-if="closed" class="open-btn" @click="closed = false">VIEW ALL</div>
                    </div>
                    <div class="col-lg-3 pt-20">
                        <h3 class="filter-label">City</h3>
                        <div class="filter-checkbox mt-10">
                            <treeselect v-model="citiesSelected" :multiple="true" :flat="true" :options="cityoptions" :limit="3" />
                        </div>
                        <h3 class="filter-label mt-30">General Unit Type</h3>
                        <div class="filter-checkbox mt-10">
                            <treeselect v-model="gutSelected" :multiple="true" :flat="true" :options="gutoptions" :autoSelectDescendants="true" :limit="3" />
                        </div>
                    </div>
                    <div class="col-lg-3 pt-20">
                        <h3 class="filter-label">Permits &amp; Bylaws</h3>
                        <div class="filter-checkbox mt-10">
                            <treeselect v-model="permitSelected" :multiple="true" :flat="true" :options="permitOptions" />
                        </div>
                        <h3 class="filter-label mt-30">Date</h3>
                        <date-picker class="demo-date" v-model="dateSearch" type="date" range placeholder="Select date range"></date-picker>
                    </div>
                    <div class="col-lg-3 pt-20">
                        <h3 class="filter-label">UTC Project Name</h3>
                        <div class="filter-checkbox mt-10">
                            <input type="text" class="demo-input" v-model="projectNameSearch"/>
                        </div>
                        <h3 class="filter-label mt-30">UTC Address</h3>
                        <div class="filter-checkbox mt-10">
                            <input type="text" class="demo-input" v-model="projectAddressSearch"/>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="text-left">VIEW RESULTS</h2>
            <table class="table mt-20">
                <thead class="fp-header">
                    <th @click="Sort('timeUnix')" style="cursor: pointer">Date</th>
                    <th @click="Sort('city')" style="cursor: pointer">City</th>
                    <th @click="Sort('project_name')" style="cursor: pointer">Project Name</th>
                    <th @click="Sort('type')" style="cursor: pointer">Type &amp; Storey</th>
                    <th @click="Sort('units')" style="cursor: pointer">Units</th>
                    <th>Application Type</th>
                    <th>Application Stage</th>
                    <th>Outcome</th>
                </thead>
                <tbody class="fp-body fp-border">
                    <tr class="planning-table-cell" v-for="(val, key) in dataSort" :key="key" @click="goto(val.link)">
                        <td class="text-center">{{val.time}}</td>
                        <td class="text-center">{{val.city}}</td>
                        <td class="text-center">{{val.project_name}}<br>{{val.address}}</td>
                        <td class="text-center">{{val.type}}<br>{{val.storey}}</td>
                        <td class="text-center">{{val.units}}</td>
                        <td class="text-center">{{val.col_1}}</td>
                        <td class="text-center">{{val.col_2}}</td>
                        <td class="text-center" v-html="(val.col_3 ? val.col_3 : '') + (val.col_4 ? '<br>'+val.col_4: '') + (val.col_5 ? '<br>'+val.col_5: '') + (val.col_6 ? '<br>'+val.col_6: '')"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
import {BACKEND_URL} from '../variables.js';
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    components: { 
        Treeselect,
        DatePicker
    },
    data() {
        return {
            plans: [],
            orderAsc: false,
            sortBy: 'timeUnix',
            cityoptions: [],
            permitOptions: [
                {id: 'rz', label: 'Rezoning'},
                {id: 'dp', label: 'DP'},
                {id: 'dvp', label: 'DVP'},
                {id: 'sub', label: 'Subdivision'},
                {id: 'bp', label: 'BP'},
                {id: 'ocp', label: 'OCP Amendment'},
                {id: 'demo', label: 'Demolition'},
                {id: 'srv', label: 'Site Services'}
            ],
            permitSelected: [],
            gutoptions: [],
            typeSelected: [],
            citiesSelected: [],
            statusSelected: [],
            dateSearch: [],
            closed: true,
            projectNameSearch: '',
            projectAddressSearch: '',
            statusOption: [
                {id: 'Now Selling', label: 'Now Selling'},
                {id: 'Selling Soon', label: 'Selling Soon'},
                {id: 'In Planning', label: 'In Planning'},
                {id: 'Sold Out', label: 'Sold Out'},
                {id: 'Now Leasing', label: 'Now Leasing'},
                {id: 'Leased Out', label: 'Leased Out'},
                {id: 'Leasing Soon', label: 'Leasing Soon'}
            ],
            gutSelected: []
        }
    },
    mounted() {
        const vm = this
        $.get({
            url: BACKEND_URL + 'api/get_region',
        }, (data, status) => {
            if (status === 'success') {
                this.cityoptions = data;
            }
        });
        $.get({
            url: BACKEND_URL + 'api/get_gut',
        }, (data, status) => {
            if (status === 'success') {
                this.gutoptions = data;
            }
        });
        $.get({
            url: BACKEND_URL + 'api/get_status',
        }, (data, status) => {
            if (status === 'success') {
                data.forEach((a) => {
                    $('#step3').append('<option value="'+ a.name + '">' + a.name + '</option>');
                })
                setTimeout(function(){
                    $('.better-select').niceSelect();
                });
            }
        });

        let param = ''
        if ($.isArray(vm.$route.query['sub[]'])) {
            vm.$route.query['sub[]'].forEach((a) => {
                param += 'sub[]=' + a + '&';
            });
        }
        else {
            if (vm.$route.query['sub[]']) {
                param += 'sub[]=' + vm.$route.query['sub[]'] + '&';
            }
        }

        if ($.isArray(vm.$route.query['bp[]'])) {
            vm.$route.query['bp[]'].forEach((a) => {
                param += 'bp[]=' + a + '&';
            });
        }
        else {
            if (vm.$route.query['bp[]']) {
                param += 'bp[]=' + vm.$route.query['bp[]'] + '&';
            }
        }

        if ($.isArray(vm.$route.query['dvp[]'])) {
            vm.$route.query['dvp[]'].forEach((a) => {
                param += 'dvp[]=' + a + '&';
            });
        }
        else {
            if (vm.$route.query['dvp[]']) {
                param += 'dvp[]=' + vm.$route.query['dvp[]'] + '&';
            }
        }
        if ($.isArray(vm.$route.query['dp[]'])) {
            vm.$route.query['dp[]'].forEach((a) => {
                param += 'dp[]=' + a + '&';
            });
        }
        else {
            if (vm.$route.query['dp[]']) {
                param += 'dp[]=' + vm.$route.query['dp[]'] + '&';
            }
        }
        if ($.isArray(vm.$route.query['ocp[]'])) {
            vm.$route.query['ocp[]'].forEach((a) => {
                param += 'ocp[]=' + a + '&';
            });
        }
        else {
            if (vm.$route.query['ocp[]']) {
                param += 'ocp[]=' + vm.$route.query['ocp[]'] + '&';
            }
        }
        if ($.isArray(vm.$route.query['rez[]'])) {
            vm.$route.query['rez[]'].forEach((a) => {
                param += 'rez[]=' + a + '&';
            });
        }
        else {
            if (vm.$route.query['rez[]']) {
                param += 'rez[]=' + vm.$route.query['rez[]'] + '&';
            }
        }

        if ($.isArray(vm.$route.query['city[]'])) {
            vm.$route.query['city[]'].forEach((a) => {
                param += 'city[]=' + a + '&';
                vm.citiesSelected.push(a);
            });
        }
        else if (typeof vm.$route.query['city[]'] == 'undefined') {
        }
        else {
            param += 'city[]=' + vm.$route.query['city[]'] + '&';
            vm.citiesSelected.push(vm.$route.query['city[]'])
        }

        if ($.isArray(vm.$route.query['unittype[]'])) {
            vm.$route.query['unittype[]'].forEach((a) => {
                param += 'unittype[]=' + a + '&';
                vm.gutSelected.push(a)
            });
        }
        else if (typeof vm.$route.query['unittype[]'] == 'undefined') {
        }
        else {
            param += 'unittype[]=' + vm.$route.query['unittype[]'] + '&';
            vm.gutSelected.push(vm.$route.query['unittype[]'])
        }

        if (typeof vm.$route.query['dp[]'] != 'undefined') {
            this.permitSelected.push('dp');
        }
        if (typeof vm.$route.query['rez[]'] != 'undefined') {
            this.permitSelected.push('rez');
        }
        if (typeof vm.$route.query['dvp[]'] != 'undefined') {
            this.permitSelected.push('dvp');
        }
        if (typeof vm.$route.query['sub[]'] != 'undefined') {
            this.permitSelected.push('sub');
        }
        if (typeof vm.$route.query['bp[]'] != 'undefined') {
            this.permitSelected.push('bp');
        }
        if (typeof vm.$route.query['ocp[]'] != 'undefined') {
            this.permitSelected.push('ocp');
        }
        if (typeof vm.$route.query['demo[]'] != 'undefined') {
            this.permitSelected.push('demo');
        }
        if (typeof vm.$route.query['srv[]'] != 'undefined') {
            this.permitSelected.push('srv');
        }

        $.get({
            url: BACKEND_URL + `api/get_all_permits?${param}`
        }, (data, status) => {
            if (status === 'success') {
                vm.plans = data
            }
        })
        
    },
    methods: {
        goto(link) {
            window.open(link+'&permit=1', '_blank');
        },
        Sort(column) {
            if (this.sortBy == column) {
                this.orderAsc = !this.orderAsc;
            }
            this.sortBy = column;
        },
        toggleBool(val) {
            if (this.typeSelected.includes(val)) {
                const index = this.typeSelected.indexOf(val);
                if (index > -1) {
                    this.typeSelected.splice(index, 1);
                }
            }
            else {
                this.typeSelected.push(val)
            }
        },
        toTimestamp(strDate){
            var datum = Date.parse(strDate);
            return datum/1000;
        }
    },
    computed: {
        dataSort: function() {
            var ob = [];
            Object.values(this.plans).forEach(el => {
                ob.push(el);
            });
            var vm = this;
            if (this.plans < 2) {
                return ob;
            }
            if (this.dateSearch.length > 0) {
                ob = ob.filter(el => {
                    return (el.timeUnix > this.toTimestamp(this.dateSearch[0]) && el.timeUnix < this.toTimestamp(this.dateSearch[1]))
                })
            }
            if (this.permitSelected.length > 0) {
                ob = ob.filter(el => {
                    var match = false;
                    vm.permitSelected.forEach((a) => {
                        if (el[a]) {
                            match = true
                        }
                    })
                    return match
                })
            }
            if (this.projectNameSearch.length > 0) {
                ob = ob.filter(el => el.project_name.includes(vm.projectNameSearch))
            }
            if (this.projectAddressSearch.length > 0) {
                ob = ob.filter(el => el.address.includes(vm.projectAddressSearch))
            }
            if (this.typeSelected.length > 0) {
                ob = ob.filter(el => this.typeSelected.includes(el.type))
            }
            if (this.citiesSelected.length > 0) {
                ob = ob.filter(el => this.citiesSelected.includes(el.cityTid))
            }
            if (this.statusSelected.length > 0) {
                ob = ob.filter(el => this.statusSelected.includes(el.status))
            }
            if (this.gutSelected.length > 0) {
                ob = ob.filter(el => this.gutSelected.includes(el.gut))
            }
            if (this.sortBy) {
                // if (this.sortBy == 'timeUnix') {
                    if (this.orderAsc) {
                        ob = ob.sort(function (a,b) {
                            if (a[vm.sortBy] == '' || a[vm.sortBy] == null) {
                                return -1;
                            }
                            if (b[vm.sortBy] == '' || b[vm.sortBy] == null) {
                                return 1;
                            }
                            if (a[vm.sortBy] > b[vm.sortBy]) {
                                return 1;
                            }
                            else {
                                return -1;
                            }
                        });
                    }
                    else {
                        ob = ob.sort(function (a,b) {
                            if (a[vm.sortBy] == '' || a[vm.sortBy] == null) {
                                return 1;
                            }
                            if (b[vm.sortBy] == '' || b[vm.sortBy] == null) {
                                return -1;
                            }
                            if (a[vm.sortBy] > b[vm.sortBy]) {
                                return -1;
                            }
                            else {
                                return 1;
                            }
                        });
                    }
                // }
            }
            return ob;
        }
    }
  }
</script>
<style scoped>
h2 {
    font-size: 3rem;
    font-weight: 600;
    font-family: 'Poppins';
    margin-top: 20px;
}
.type-group-inner.closed {
    max-height: 182px;
    overflow: hidden;
}
.type-group .open-btn {
    width: 160px;
    padding: 10px;
    background: #D9D9D9;
    display: block;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    cursor: pointer;
}
.type-group input {
    width: 20px;
    height: 20px;
}
.type-group label {
    font-size: 1.5rem;
}
h1 {
    font-size: 3rem;
    text-align: left;
    font-weight: 600;
    font-family: 'Poppins';
    padding-bottom: 20px;
    border-bottom: 3px solid #545454;
}
.planning-table-cell {
    cursor: pointer;
}
.no-secret {
    max-width: 700px;
}
.btn {
    color: #ffffff;
    background: #13293E;
    padding: 20px;
    border-radius: 45px;
    width: 300px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.8rem;
    cursor: pointer;
    border: 3px solid #fff;
}
.no-secret-section {
    padding: 50px;
}
.premium-section {
    padding: 100px 50px;
    background: #13293E;
    color: #ffffff;
}
.premium-section h2 {
    margin-top: 50px;
    margin-bottom: 50px;
}
.premium-icon {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}
.premium-wrapper {
    max-width: 1000px;
    display: inline-block;
    width: 100%;
}
.premium-block {
    text-align: left;
    margin-bottom: 40px;
}
.premium-block .row {
    margin-bottom: 20px;
}
.premium-content {
    font-size: 1.3rem;
    font-weight: 300;
    color: #ffffff;
    max-width: 250px;
}
.premium-wrapper h3 {
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.growth-wrapper {
    max-width: 700px;
}
p.growth-content {
    font-family: Poppins;
    font-size: 1.9rem;
    margin-top: 10px;
    font-weight: 300;
    color: #000000;
    line-height: 45px;
}
.business-growth {
    margin: 150px 0;
    overflow: visible;
}
.growth-img {
    width: 500px;
    margin-top: 80px;
    border-radius: 30px;
}
.accelerate-block {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.3rem;
    width: 150px;
    background: #1D184C;
    color: #fff;
    padding: 20px 0;
    position: absolute;
}
.accelerate-block.access {
    left: 50%;
    margin-left: 200px;
    top: 200px;
}
.accelerate-block.know {
    left: 50%;
    top: 450px;
    margin-left: -200px;
}
.accelerate-block.view {
    left: 50%;
    margin-left: -350px;
}
.accelerate-block.depth {
    left: 50%;
    margin-left: 200px;
}
.accelerate-block.listing {
    left: 50%;
    top: 350px;
    margin-left: -350px;
}
.accelerate-block.units {
    left: 50%;
    bottom: 0;
    margin-bottom: -50px;
    z-index: 9;
    margin-left: 200px;
}
.color-pink {
    color: #F15C6C !important;
}
.demo-review {
    background: #13293E;
    color: #ffffff;
    padding: 100px 0;
}
.demo-review h2 {
    margin-bottom: 50px;
}
.package {
    padding: 100px 0;
}
.package-wrapper {
    max-width: 1200px;
    margin: auto;
}
.package-block {
    background: #13293E;
    width: 400px;
    margin: auto;
    border-radius: 30px;
    color: #ffffff;
    padding: 30px;
    font-family: 'Poppins';
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.package-block h3 {
    font-weight: 700;
    font-size: 2.3rem;
    width: 100%;
}
.package-block h4 {
    text-decoration: line-through;
    font-size: 1.3rem;
    font-weight: 500;
    width: 100%;
}
.package-content {
    text-align: left;
    width: 329px;
    display: block;
    margin: 50px auto;
    font-size: 1.3rem;
    font-family: Poppins;
    font-weight: 400;
}
.package-content .package-row {
    margin-bottom: 10px;
}
.package-content .package-row span {
    display: inline-block;
    margin-left: 35px;
    width: calc(100% - 30px);
}
.pkg-img {
    width: 30px;
    position: absolute;
}
.demo-wrapper {
    max-width: 1200px;
    margin: auto;
    position: relative;
}
.type-filter-button-sm {
    background: #FFFFFF;
    border: 3px solid #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    position: relative;
    width: 100%;
    margin: 10px 10px;
    color: #ffffff;
    background: #13293E;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 15px 25px;
}
.type-filter-button-sm.selected {
    color: #13293E;
    background-color: #fff;
    border: 3px solid #13293E;
}
.filter-label {
    font-weight: 500;
    font-size: 1.5rem;
    color: #ffffff;
    font-family: Poppins;
    text-align: left;
    margin-left: 10px;
    text-transform: capitalize;
}
.demo-input-normal {
    background: transparent;
    border: 3px solid #fff;
    border-radius: 10px;
    font-size: 1.2rem;
    color: #ffffff;
    padding: 10px 15px 10px 50px;
    width: 100%;
    margin: auto 10px;
    background-repeat: no-repeat;
    background-position: 12px;
}
.demo-input {
    background: transparent;
    border-radius: 10px;
    font-size: 1.2rem;
    color: #000;
    padding: 10px 15px 10px 50px;
    width: 100%;
    margin: auto 10px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-position: 12px;
    border: 3px solid #13293E;
}
.report-submit {
    text-align: center;
    width: 100%;
}
.filter-update {
    margin-top: 30px;
    background: #13293E;
    border: 3px solid #fff;
    border-radius: 30px;
    padding: 20px 50px;
    font-size: 1.2rem;
    display: inline-block;
    float: none;
}
.filter-update.hollow:hover {
    background: #fff;
    color: #13293E;
    border: 3px solid #13293E;
}
.filter-reset {
    margin-top: 30px;
    background: #13293E;
    border: 3px solid #fff;
    border-radius: 30px;
    padding: 20px 50px;
    font-size: 1.2rem;
    display: inline-block;
    float: none;
    color: #fff;
    margin-right: 100px;
}
.discover {
    position: absolute;
    top: 500px;
    width: 100%;
}
.discover h2 {
    color: #fff;
    font-family: Poppins;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
}
.discover .btn {
    background: transparent;
    border: 3px solid #fff;
}
.btn.hollow:hover {
    background: #fff;
    color: #13293E;
    border: 3px solid #13293E;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.listing-map {
    background: #fff;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    border-radius: 40px;
    margin-top: -10px;
}
.map-icon {
    width: 50px;
}
.package-title {
    margin-top: 50px;
    margin-bottom: 50px;
}
.permits {
    display: inline-block;
    width: 100%;
}
@media (max-width: 991px) {
    .row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .accelerate-block.depth {
        right: 10px;
        left: initial;
        margin-left: 0;
    }
    .accelerate-block.units {
        right: 10px;
        left: initial;
        margin-left: 0;
    }
    .accelerate-block.listing {
        left: 40px;
        margin-left: 0;
        top: initial;
        bottom: 0;
        margin-bottom: -50px;
    }
    .growth-img {
        width: calc(100% - 100px);
    }
    .package-block {
        margin-top: 50px;
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
    }
    .business-growth {
        padding-left: 20px;
        padding-right: 20px;
    }
    .business-block-text {
        order: 2;
        margin-top: 100px;
    }
    .business-block-img {
        order: 1;
    }
    .discover {
        position: absolute;
        top: 250px;
        width: 100%;
    }
    .discover h2 {
        font-size: 1rem;
    }
    .discover .btn {
        font-size: 1rem;
        width: 150px;
        padding: 8px;
    }
    .discover-icon {
        width: 40px;
    }
    h2 {
        font-size: 2rem;
    }
    p.no-secret-content {
        font-size: 1rem;
        line-height: 30px;
    }
    p.growth-content {
        font-size: 1.1rem;
        line-height: 30px;
    }
    .btn {
        padding: 15px;
        width: 250px;
        font-size: 1.3rem;
    }
    .premium-icon {
        width: 75px;
        height: 75px;
    }
    .premium-wrapper h3 {
        font-size: 1.3rem;
    }
    .package-title {
        margin-top: 0px;
    }
    .demo-wrapper {
        padding: 0 30px;
    }
    .type-filter-button-sm {
        font-size: 0.8rem;
        padding: 10px;
    }
    .premium-block .row {
        justify-content: center;
    }
}
@media (max-width: 470px) {
    .premium-section {
        padding: 100px 10px;
    }
    .premium-icon {
        width: 55px;
        height: 55px;
    }
    .premium-wrapper h3 {
        font-size: 1.1rem;
        margin-bottom: 5px;
    }
    .premium-content {
        font-size: 1.1rem;
    }
}
@media (max-width: 370px) {
    .package-block h3 {
        font-size: 1.8rem;
    }
    .package-block h4 {
        font-size: 1rem;
    }
}
h3.filter-label {
        color: #13293E;
}
</style>
<style>
.permits .filter-checkbox .vue-treeselect {
    border: 3px solid #13293E;
}
.permits .filter-select .nice-select.better-select {
    border: 3px solid #13293E;
}
.permits .filter-select .nice-select.better-select span {
    color: #13293E;
}
.permits .demo-date input {
    background: transparent;
    border: 3px solid #13293E;
    border-radius: 10px;
    font-size: 1.2rem;
    color: #000;
    padding: 10px 10px;
    height: 50px;
    margin: 10px 0;
    width: 100%;
}
.permits .demo-date.mx-datepicker.mx-datepicker-range {
    width: 100%;
    margin: 0 10px;
}
</style>