<template>
    <section>
        <div class="container">
            <div class="card-deck mb-3 text-center">
                <div class="card mb-4 box-shadow">
                    <div class="card-header">
                        <h4 class="my-0 font-weight-normal">Surrey Presale Subscription</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$49.99 <small class="text-muted">/ mo</small></h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>20 users included</li>
                            <li>10 GB of storage</li>
                            <li>Priority email support</li>
                            <li>Help center access</li>
                        </ul>
                        <stripe-checkout
                            ref="checkoutRef"
                            mode="subscription"
                            :pk="publishableKey"
                            :line-items="lineItems"
                            :success-url="successURL"
                            :cancel-url="cancelURL"
                            @loading="v => loading = v"
                            />
                            <button @click="submit" class="btn btn-lg btn-block btn-primary">Subscribe!</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
    components: {
        StripeCheckout,
    },
    data () {
        this.publishableKey = 'pk_live_51KNl2KE1yVi4Nb4L8KtyhYPYQUHobf7FcZKqPDAzU6DyTOz98Pg39tV7K2SmULGohfJVU6OgpqRjeQRKthSV0UDO00pjjjhwGG';
        return {
            loading: false,
            lineItems: [
                {
                    price: 'price_1KSK75E1yVi4Nb4LL94YK46M', // The id of the recurring price you created in your Stripe dashboard
                    quantity: 1,
                },
            ],
            successURL: 'https://www.realpropertydata.ca/backend/stripe/checkout_session',
            cancelURL: 'https://www.realpropertydata.ca/',
        };
    },
    mounted() {
    },
    methods: {
        submit () {
            this.$refs.checkoutRef.redirectToCheckout();
        },
    }
  }
</script>
<style scoped>

</style>