<template>
  <div> 
    <b-table
      :fields="headerFields"
      :items="reportDetailSummary"
      responsive="sm"
      hover
      small
      thead-class="bg-vb-secondary text-light position-sticky"
    >
      <template #cell(type)="data">
        <strong class="font-weight-bold" v-if="data.value.mls_beds == 's'"
          >Studio</strong
        >
        <strong class="font-weight-bold" v-else-if="data.value.mls_beds == 'jr'"
          >Jr 1-bed</strong
        >
        <strong class="font-weight-bold" v-else
          >{{ data.value.mls_beds }}-bed</strong
        >
        <br />
        {{
          data.value.fp_specs.toLowerCase() != "none" ? data.value.fp_specs : ""
        }}
      </template>
     <template #cell(mls_plan_name)="data"> 
        <a v-if="data.item.mls_floor_plan_feature && Object.entries(data.item.mls_floor_plan_feature).length > 0" href="#" @click="pdf_file=data.item.mls_floor_plan_feature" v-b-modal="'floor-plan'">{{ data.value }}</a>
        <span v-else>{{ data.value }}</span>
      </template>
       <template #cell(units_percentage)="data">
        <span v-if="data.value != '-'">{{ data.value }}%</span>
        <span v-else>{{ data.value }}</span>
      </template>
      <template #custom-foot="data" v-if="router.currentRoute.path == '/type'">
        <b-tr class="bg-vb-accent text-vb-primary">
          <b-td class="border-right-0 font-weight-bold">Total:</b-td>
          <b-td></b-td>
          <b-td></b-td>
          <template 
          v-for="(field, index) in numericFields">
            <b-td :key="index"
              class="font-weight-bold"
              :colspan="field.key =='sold_percentage' ? 2 : 0"
            >
            {{ calculateTotal(field.key) }}
            <span v-if="field.key == 'units_percentage'">%</span>
          </b-td>
          </template> 
          <b-td></b-td>
          <b-td></b-td>
        </b-tr>
      </template>
      <template #cell(view_unit_mix)="data">
        <b-button
          variant="vb-primary" 
          @click="getUnitMix(data.item.mls_plan_name)"
          v-b-modal.show_unit_mix
          >View Unit Mix</b-button
        >
      </template>
    </b-table>
    
    <b-modal id="floor-plan" ref="summary-table-modal" title="Floor Plan">
      <a :href="pdf_file.link" target="_blank"><img class="w-100" :src="pdf_file.preview" alt=""></a>
    </b-modal>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, Ref } from "vue";
import { useRouter } from "vue2-helpers/vue-router";
import axios from "axios";
import { BACKEND_URL } from "../../variables.js";
import { BModal } from 'bootstrap-vue'; 

const router = useRouter();
const props = defineProps({
  reportDetailSummary: null,
  headerFields: null,
});
const pdf_file = ref({})
const numericFields = computed(() =>
  props.headerFields.filter((field) =>
    [
      "units",
      "confirmed_sold",
      "total_size",
      "avg_size",
      "units_percentage",
    ].includes(field.key)
  )
);
// Calculate totals for numeric fields
const calculateTotal = (key) => {
  return Math.round(
    props.reportDetailSummary.reduce(
      (sum, item) => sum + (Number(item[key]) || 0),
      0
    )
  );
};

const getUnitMix = async (data) => {  
                
  let q = { 'uid': router.currentRoute.query.uid,
            'plan': data
          };
    const routeData = router.resolve({ path: '/unit-mix-report', query: q });
    window.open(routeData.href, '_blank'); 
};
 
</script>

<style scoped></style>
