<template>
    <div> 
        <template v-if="slideContent.length > 0">
            <vueper-slides
                style="background-color:#b8b8b8;"
                ref="vueperslides1"
                :touchable="false" 
                :autoplay="false"
                :bullets="false" 
                fixed-height="900px"
                @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                > 
                <vueper-slide
                    v-for="(slide, i) in slideContent"
                    :key="i"
                    style="padding:1rem;"
                    :image="slide.url">
                    <template #content v-if="slide.url.slice(slide.url.length-3,slide.url.length).toLowerCase() == 'pdf'">
                            {{slide.url.slice(slide.url.length-3,slide.url.length)}} 
                        <object :data="slide.url" type="application/pdf" width="100%" height="500px">
                            <p>Unable to display PDF file. <a :href="slide.url">Download</a> instead.</p>
                            </object>
                    </template>
                </vueper-slide> 
            </vueper-slides>
            <vueper-slides
                class="no-shadow thumbnails mt-4" 
                ref="vueperslides2"
                @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                :visible-slides="slideContent.length"
                fixed-height="75px"
                :bullets="false"
                :touchable="false"
                :gap="2.5"
                :arrows="false">
                <vueper-slide
                    v-for="(slide, i) in slideContent"
                    :key="i"
                    :image="slide.thumb"
                    @click.native="$refs.vueperslides2.goToSlide(i)">
                </vueper-slide>
            </vueper-slides>  
        </template>
        <template v-else>
          <h1 class="mt-50 text-center text-vb-tertiary">  No Available Images  </h1>
        </template>
    </div>
</template>

<script>
   import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css' 

    export default {
    components: { VueperSlides, VueperSlide }, 
    props: {
        slideContent : Array
    },
    data: () => ({ 
    })
    }
</script>

<style lang="scss" scoped>

.thumbnails {
  margin: auto;
  width: 500px;
}
 
.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1; 
}

.vueperslide.vueperslide--active.vueperslide--visible{
    background-size: contain;
    background-repeat: no-repeat;
}
</style>