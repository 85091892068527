<template>
    <div> 
        <div class="row bg-vb-accent pt-3 pb-2 px-4">
         <div class="col-2" v-if="userData.first_name != null">
            <img :src="userData.pic" class="rounded-circle _mw-160 mt-1">
         </div>
         <div class="col-1 align-self-center d-flex" style="max-width: max-content;">
            <div>
                <p class="mb-3">Name</p>
                <p class="mb-3">Brokerage</p>
                <p class="mb-3" style="text-wrap:nowrap;">Team Name</p>
            </div>
         </div>
         <div class="col align-self-center d-flex" v-if="userData.first_name != null">
            <div>
                <p class="bg-vb-secondary text-white pl-2 mb-3 mw-15-rem">{{ userData.first_name }} {{ userData.last_name }}</p>
                <p class="bg-vb-secondary text-white pl-2 mb-3 mw-15-rem">{{ userData.brokerage_name }}</p>
                <p class="bg-vb-secondary text-white pl-2 mb-3 mw-15-rem">{{ userData.team_name }}</p>
            </div>
         </div>
         <div class="col align-self-center d-flex" style="max-width: max-content;">
            <div>
                <p v-if="userData.user_email" class="mb-3">Email</p>
                <p v-if="userData.phone_number" class="mb-3">Contact #</p>
                <p v-if="userData.link" class="mb-3">Website</p>
            </div>
         </div>
         <div class="col align-self-center d-flex" v-if="userData.first_name != null">
            <div>
                <p v-if="userData.user_email" class="bg-vb-secondary text-white pl-2 mb-3 mw-15-rem">{{ userData.user_email }}</p>
                <p v-if="userData.phone_number" class="bg-vb-secondary text-white pl-2 mb-3 mw-15-rem">{{ userData.phone_number }}</p>
                <p v-if="userData.link" class="bg-vb-secondary text-white pl-2 mb-3 mw-15-rem">{{ userData.link }}</p>
            </div>
         </div>
        </div>
    </div>
</template>

<script setup lang="ts"> 
    const props = defineProps({
        userData: null
    })
</script>

<style lang="scss" scoped>
._mw-160{
    max-width: 120px; 
}
p {
    font-size: 18px;
    padding: 5px 0px;
    font-family: 'Helvetica' !important;
}

.mw-15-rem{
    max-width: 12rem;
    padding-right: 6px;
}
</style>