import { defineStore } from "pinia";

export const useCitiesSelectedStore = defineStore("CitiesSelectedStore", {
    state: () => ({cities_selected: new Array()}),
    actions: {
        refreshCities() {
            this.cities_selected = new Array()
        },
    },
    getters: {
        getCities(state){
            return state.cities_selected
        }

    }
}) 