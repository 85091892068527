<template>
    <div> 
        <textarea :placeholder="placeHolder" :value="value" :type="inputType"
            class="bg-vb-secondary form-control text-light _rpd-input-form"
            v-on:input="$emit('input', $event.target.value)" rows="5"></textarea>
    </div>
</template>

<script setup lang="ts">

const props = defineProps({
    value: { type: String, required: true },
    inputType: String,
    placeHolder: String
})

</script>

<style lang="scss" scoped>
._rpd-input-form { 
    font-weight: 400;
    font-size: 18px;
    border-radius: 10px;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: rgb(224, 224, 224) !important;
}

::placeholder {
    color: rgb(224, 224, 224) !important;
}
</style>