import { defineStore } from "pinia";

export const useRequestCounter = defineStore("CounterStore", {
  state: () => {
    return {
      reqCounter: {
        requestLength: 0,
        completedRequest: 0,
      },
    };
  },
  actions: {
    incrementRequestCounter(){
      this.reqCounter.requestLength++;
    },

    incrementCompletedRequest() {
      this.reqCounter.completedRequest++;
    },
    setRequestCounter(max) {
      this.reqCounter.requestLength = max;
    },
    resetCounter() {
      this.reqCounter.requestLength = 0;
      this.reqCounter.completedRequest = 0;
    },
  },
  getters: {
    getCounter() {
      return this.reqCounter.completedRequest;
    },
  },
});
